import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
} from "chart.js";
import { fetcher } from "../../utils/axios";
import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(localeData);
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement
);

const UsageSummary = () => {
    const monthNames = dayjs.months();

    const [responseData, setResponseData] = useState("");
    const [data, setData] = useState({ labels: [], datasets: [] });
    const [timescale, setTimescale] = useState("day");
    const [firstDate, setFirstDate] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const styling = useTheme();
    const colorArray = Object.values(styling.palette.graphics);

    const fetchData = async () => {
        try {
            const response = await fetcher("/login_summary/");
            setResponseData(response.data);
            transformData(response.data);
        } catch (error) {
            console.error("Error fetching the data", error);
        }
    };

    const transformData = (responseData) => {
        if (responseData === "") {
            return;
        }

        let labels = [];
        const datasets = {};

        // Process the nested data based on the selected timescale
        if (timescale === "day") {
            const dataSet = responseData["days"];

            for (const year in dataSet) {
                for (const month in dataSet[year]) {
                    for (const day in dataSet[year][month]) {
                        const dateLabel = `${year}-${month}-${day}`;

                        if (!labels.includes(dateLabel)) {
                            labels.push(dateLabel);
                        }

                        for (const org in dataSet[year][month][day]) {
                            if (!datasets[org]) {
                                datasets[org] = {
                                    label: org === "null" ? "Geen/Admin" : org,
                                    data: [],
                                };
                            }

                            const dayData = datasets[org].data.find((d) => d.label === dateLabel);
                            if (dayData) {
                                dayData.value += dataSet[year][month][day][org];
                            } else {
                                datasets[org].data.push({
                                    label: dateLabel,
                                    value: dataSet[year][month][day][org],
                                });
                            }
                        }
                    }
                }
            }
            // Use dayjs to format the labels
            labels = labels.map((label) => dayjs(label).format("DD-MM-YYYY"));
            // if (labels.length > 60 && labels.length < 100) {
            //     labels = labels.map((label, index) =>
            //         ["01", "10", "20"].includes(label.slice(0, 2)) || index === 0 ? label : ""
            //     );
            // }
            // if (labels.length >= 100) {
            //     labels = labels.map((label, index) => (label.startsWith("01") ? label : ""));
            // }
        } else if (timescale === "month") {
            const dataSet = responseData["months"];
            for (const year in dataSet) {
                for (const month in dataSet[year]) {
                    let dateLabel;
                    dateLabel = `${year}-${month}`;
                    if (!labels.includes(dateLabel)) {
                        labels.push(dateLabel);
                    }
                    for (const org in dataSet[year][month]) {
                        if (!datasets[org]) {
                            datasets[org] = {
                                label: org === "null" ? "Geen/Admin" : org,
                                data: [],
                            };
                        }
                        const monthData = datasets[org].data.find((d) => d.label === dateLabel);
                        if (monthData) {
                            monthData.value += dataSet[year][month][org];
                        } else {
                            datasets[org].data.push({
                                label: dateLabel,
                                value: dataSet[year][month][org],
                            });
                        }
                    }
                }
            }
            labels = labels.map((label) => {
                const [year, month] = label.split(/[-]/);
                return `${monthNames[month - 1]} ${year}`;
            });
        } else if (timescale === "year") {
            const dataSet = responseData["years"];
            for (const year in dataSet) {
                let dateLabel;
                dateLabel = `${year}`;
                if (!labels.includes(dateLabel)) {
                    labels.push(dateLabel);
                }
                for (const org in dataSet[year]) {
                    if (!datasets[org]) {
                        datasets[org] = {
                            label: org === "null" ? "Geen/Admin" : org,
                            data: [],
                        };
                    }
                    const yearData = datasets[org].data.find((d) => d.label === dateLabel);
                    if (yearData) {
                        yearData.value += dataSet[year][org];
                    } else {
                        datasets[org].data.push({
                            label: dateLabel,
                            value: dataSet[year][org],
                        });
                    }
                }
            }
        }

        // const firstDateLabel = labels[0];
        // const [year, month, day] = firstDateLabel.split(/[-]/);
        // if (timescale === "day") {
        //     setFirstDate(new Date(year, month - 1, day));
        // } else if (timescale === "month") {
        //     setFirstDate(new Date(year, month - 1, 1));
        // } else if (timescale === "year") {
        //     setFirstDate(new Date(year, 0, 1));
        // }

        setData({
            labels,
            datasets: Object.values(datasets).map((dataset, index) => ({
                ...dataset,
                label: dataset.label || "Geen/Admin",
                data: dataset.data.map((d) => d.value),
                fill: true,
                borderColor: colorArray[index % colorArray.length],
                backgroundColor: colorArray[index % colorArray.length],
            })),
        });
    };

    useEffect(() => {
        // Used to fetch the data when the component is mounted
        fetchData();
    }, []);

    useEffect(() => {
        // Used to transform the data when the timescale changes
        transformData(responseData);
    }, [timescale]);
    return (
        <>
            <Typography variant="h4">Monitoring</Typography>
            <Typography>
                De grafiek toont het aantal unieke gebruikers per tijdseenheid. Gebruikers zonder organisatie
                of met de rol "applicatiebeheerder" worden als "Geen/Admin" weergegeven. Gewone gebruikers die
                lid zijn van meerder organisaties worder geteld bij de organisatie waar ze als eerste lid van
                zijn geworden.
            </Typography>
            <div style={{ height: "400px" }}>
                <div>
                    <label htmlFor="timescale">Tijdsschaal: </label>
                    <select id="timescale" value={timescale} onChange={(e) => setTimescale(e.target.value)}>
                        <option value="day">Dag</option>
                        <option value="month">Maand</option>
                        <option value="year">Jaar</option>
                    </select>
                </div>
                {data?.datasets[0]?.data?.length < 3 ? (
                    <Bar
                        data={data}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: { position: "top" },
                                title: { display: true, text: "Aantal gebruikers " },
                            },
                            scales: { y: { min: 0, stacked: true }, x: { stacked: true } },
                            interaction: { intersect: false, mode: "nearest", axis: "xy" },
                        }}
                    />
                ) : (
                    <Line
                        data={data}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: { position: data.labels.length < 10 ? "top" : "right" },
                                title: { display: true, text: "Aantal gebruikers " },
                            },
                            scales: { y: { min: 0, stacked: true, ticks: { stepSize: 1.0 } } },
                            interaction: { intersect: true, mode: "nearest", axis: "x" },
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default UsageSummary;
