import {
    Button,
    ButtonGroup,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteSubTheme, deleteKPI, updateSubThemeAction } from "./ThemeActions";
import { Delete, Add } from "@mui/icons-material";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import SubThemeDialog from "./NewSubThemeDialog";
import KPIDialog from "./KPIDialog";
import { useParams } from "react-router-dom";
import StyledLinkText from "../CustomComponents/StyledLinkText";
import ToggleableInputField from "../CustomComponents/InputFields/ToggleableInputField";
import { global } from "../../context/global";
import { Capitalize, Pluralize } from "../../functions/Formatters";


/**
 * Displays and manages subthemes and KPIs for a selected theme.
 */
export default function SubthemeOverview() {
    const userIsAdmin = useSelector((state) => state.auth.account.is_staff);
    const dispatch = useDispatch();
    const { themeID } = useParams();
    const theme = useSelector((state) => state.themes.themes.find((theme) => Number(theme.id) === Number(themeID)));

    const [subThemeDeleteDialogOpen, setSubThemeDeleteDialogOpen] = useState(false);
    const [subThemeToDelete, setSubThemeToDelete] = useState(false);
    const handleClickDeleteSubTheme = (subtheme) => {
        setSubThemeToDelete(subtheme);
        setSubThemeDeleteDialogOpen(true);
    };

    const [subThemeDialogOpen, setSubThemeDialogOpen] = useState(false);
    const [subSubThemeToEdit, setSubThemeToEdit] = useState(false);

    const [kpiDialogOpen, setKPIDialogOpen] = useState(false);
    const [kpiToEdit, setKPIToEdit] = useState(false);
    const [subthemeForNewKPI, setSubthemeForNewKPI] = useState(false);
    const handleClickEditKPI = (kpi) => {
        setKPIToEdit(kpi);
        setKPIDialogOpen(true);
    };
    const handleClickAddKPI = (subtheme) => {
        setSubthemeForNewKPI(subtheme);
        setKPIDialogOpen(true);
    };

    const [kpiDeleteDialogOpen, setKPIDeleteDialogOpen] = useState(false);
    const [kpiToDelete, setKPIToDelete] = useState(false);
    const handleClickDeleteKPI = (kpi) => {
        setKPIToDelete(kpi);
        setKPIDeleteDialogOpen(true);
    };

    const handleDialogClose = () => {
        setSubThemeDialogOpen(false);
        setSubThemeDeleteDialogOpen(false);
        setKPIDialogOpen(false);
        setSubThemeToEdit(false);
        setSubThemeToDelete(false);
        setKPIToEdit(false);
        setKPIToDelete(false);
        setKPIDeleteDialogOpen(false);
    };

    const tableHeaders = [
        Capitalize(global.subtheme),
        Capitalize(global.description),
        Pluralize("KPI"),
        userIsAdmin ? Capitalize(global.actions) : null,
    ];
    return (
        <>
            <SubThemeDialog onClose={handleDialogClose} open={subThemeDialogOpen} edit={subSubThemeToEdit} theme={theme} />
            <KPIDialog onClose={handleDialogClose} open={kpiDialogOpen} edit={kpiToEdit} subtheme={subthemeForNewKPI} />
            <ConfirmDeleteDialog
                open={subThemeDeleteDialogOpen}
                onClose={handleDialogClose}
                dialogTitle={"Subthema verwijderen"}
                dialogContentText={`Weet u zeker dat u het subthema ${subThemeToDelete.name} wilt verwijderen?`}
                dialogConfirmButton={"Verwijder subthema"}
                handleConfirm={() => {
                    deleteSubTheme(subThemeToDelete, dispatch, handleDialogClose);
                }}
            />
            <ConfirmDeleteDialog
                open={kpiDeleteDialogOpen}
                onClose={handleDialogClose}
                dialogTitle={"KPI verwijderen"}
                dialogContentText={`Weet u zeker dat u KPI ${kpiToDelete.name} wilt verwijderen?`}
                dialogConfirmButton={"Verwijder KPI"}
                handleConfirm={() => {
                    deleteKPI(kpiToDelete, dispatch, handleDialogClose);
                }}
            />
            <br /> <br />
            <ButtonGroup variant="contained">
                {userIsAdmin && (
                    <Button onClick={() => setSubThemeDialogOpen(true)}>
                        <Add />
                        Nieuw subthema
                    </Button>
                )}
            </ButtonGroup>
            {theme.subthemes.length === 0 ? (
                <Typography variant="body1">Er zijn nog geen subthema's toegevoegd aan dit thema.</Typography>
            ) : (
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header) => (
                                    <TableCell key={header}>
                                        <Typography variant="h5">{header}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {theme.subthemes.map((subtheme) => (
                                <TableRow key={subtheme.id}>
                                    <TableCell>
                                        <ToggleableInputField
                                            value={subtheme.name}
                                            label={"Naam"}
                                            onSave={(data) => {
                                                updateSubThemeAction({ id: subtheme.id, ...data }, dispatch);
                                            }}
                                            allowEdit={userIsAdmin}
                                            field="name"
                                            inline={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ToggleableInputField
                                            value={subtheme.description}
                                            label={"beschrijving"}
                                            onSave={(data) => {
                                                updateSubThemeAction({ id: subtheme.id, ...data }, dispatch);
                                            }}
                                            allowEdit={userIsAdmin}
                                            field="description"
                                            lines={3}
                                            inline={true}
                                        />
                                    </TableCell>
                                    <TableCell padding="none">
                                        <List disablePadding>
                                            {subtheme.kpis.map((kpi) => (
                                                <ListItem
                                                    key={kpi.id}
                                                    sx={{ pb: 0, pt: 0 }}
                                                    secondaryAction={
                                                        userIsAdmin && (
                                                            <Tooltip title="KPI verwijderen">
                                                                <IconButton size="small" onClick={() => handleClickDeleteKPI(kpi)}>
                                                                    <Delete />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={<StyledLinkText text={`• ${kpi.name}`} onClick={() => handleClickEditKPI(kpi)} />}
                                                        secondary={kpi.unit ? `Eenheid: ${kpi.unit}` : null}
                                                    />
                                                </ListItem>
                                            ))}
                                            {userIsAdmin && (
                                                <ListItem>
                                                    <Button onClick={() => handleClickAddKPI(subtheme)}>
                                                        <Add />
                                                        Toevoegen
                                                    </Button>
                                                </ListItem>
                                            )}
                                        </List>
                                    </TableCell>
                                    <TableCell>
                                        {userIsAdmin && (
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                startIcon={<Delete />}
                                                onClick={() => handleClickDeleteSubTheme(subtheme)}
                                            >
                                                Aspect verwijderen
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}
