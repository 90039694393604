import { toast } from "react-toastify";
import { updateAdvice, updateDesign, updateProject } from "../../store/slices/projectsSlice";
import { deleter, fetcher, patcher, poster } from "../../utils/axios";
import { setFiles } from "../../store/slices/authSlice";

/**
 * Downloads a file from a given blob URL with the specified filename.
 * @param {string} blobURL - The URL of the blob to download.
 * @param {string} filename - The desired filename for the downloaded file.
 */
export const DownloadFile = (blobURL, filename) => {
    const link = document.createElement("a");
    link.href = blobURL;
    link.target = "_blank";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

export const OpenFileInTab = (blobURL) => {
    const link = document.createElement("a");
    link.href = blobURL;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

// example:
// parentIDs = {
//     project: 15,
//     phase: 7,
//     loop: 29,
//     design: 17,
//     advice: 21,
//     projectTheme: 23,
//     projectSubtheme: 25,
// };

const addAttachmentToObject = (parentObj, attachment) => {
    return { ...parentObj, attachments: [...parentObj.attachments, attachment] };
};
const removeAttachmentFromObject = (parentObj, attachmentID) => {
    return { ...parentObj, attachments: parentObj.attachments.filter((a) => a.id !== attachmentID) };
};
const updateAttachmentInObject = (parentObj, attachment) => {
    return { ...parentObj, attachments: parentObj.attachments.map((a) => (a.id === attachment.id ? attachment : a)) };
};

const updateObjectInStore = (parentType, obj, dispatch) => {
    switch (parentType) {
        case "project":
            dispatch(updateProject(obj));
            break;
        case "design":
            dispatch(updateDesign(obj));
            break;
        case "advice":
            dispatch(updateAdvice(obj));
            break;
        default:
            break;
    }
};
export const createAttachment = (attachment, parentType, parentIDs, parentObj = {}, dispatch) => {
    attachment.append(parentType, parentObj.id);
    poster("attachments/", attachment, "multipart/form-data").then((response) => {
        if (response.status === 201) {
            let updatedObject = addAttachmentToObject(parentObj, response.data);

            if (parentType !== "project") {
                updatedObject = { ...updatedObject, ...parentIDs };
            }
            updateObjectInStore(parentType, updatedObject, dispatch);
        }
    });
};

/**
 * Updates an attachment.
 * 
 * @param {object} attachment - The attachment to update.
 * @param {string} parentType - The type of the parent object.
 * @param {Object} parentIds - Object containing the id number of the relevant parent entities. For example, if the parentType is "project", the parentIds should contain a field "project" with the id of the project.
 * @param {Object} [parentIds.project] - The id of the project.
 * @param {Object} [parentIds.phase] - The id of the phase.
 * @param {Object} [parentIds.loop] - The id of the loop.
 * @param {Object} [parentIds.design] - The id of the design.
 * @param {Object} [parentIds.advice] - The id of the advice.
 * @param {Object} [parentIds.projectTheme] - The id of the project theme.
 * @param {Object} [parentIds.projectSubtheme] - The id of the project subtheme.
 * @param {Object} parentObj - The object of the parent entity. Should contain a field "attachments" which is an array of attachment objects.
 * @param {Object} parentObj.id - The id of the parent entity.
 * @param {Object} parentObj.attachments - Array of attachment objects.

 * @param {function} dispatch - The dispatch function.
 */
export const updateAttachment = (attachment, parentType, parentIDs, parentObj = {}, dispatch) => {
    const attachmentID = Number(attachment.get("id"));
    patcher(`attachments/${attachmentID}/`, attachment, "multipart/form-data").then((response) => {
        if (response.status === 200) {
            let updatedObject = updateAttachmentInObject(parentObj, response.data);

            if (parentType !== "project") {
                updatedObject = { ...updatedObject, ...parentIDs };
            }
            updateObjectInStore(parentType, updatedObject, dispatch);
        }
    });
};

/**
 * Deletes an attachment.
 *
 * @param {Number} attachmentID - The ID of the attachment to delete.
 * @param {string} parentType - The type of the parent object.. (project, design, advice)
 * @param {Object} parentIDs - The IDs of the parent object.
 * @param {Object} parentObj - The parent object.
 * @param {Object} parentObj.id - ID of the parent object.
 * @param {Object} parentObj.attachments - Attachments of the parent object.
 * @param {function} dispatch - The dispatch function.
 */
export const deleteAttachment = (attachmentID, parentType, parentIDs, parentObj, dispatch) => {
    deleter(`attachments/${attachmentID}/`).then((response) => {
        if (response.status === 204) {
            let updatedObject = removeAttachmentFromObject(parentObj, attachmentID);

            if (parentType !== "project") {
                updatedObject = { ...updatedObject, ...parentIDs };
            }
            updateObjectInStore(parentType, updatedObject, dispatch);
        }
    });
};

export const fetchFiles = (dispatch) => {
    fetcher("files/").then((response) => {
        if (response.status === 200) {
            dispatch(setFiles(response.data));
        }
    });
};

/**
 * Downloads a fixed file based on the provided file parameter.
 * @param {string} id - The file parameter to determine the file to be downloaded. Options: "SeriousGame", "Handreiking", or the ID number of the file.
 */
export const downloadFixedFile = (id, fileName) => {
    let url = `files/${id}/download/`;

    fetcher(url, "blob").then((response) => {
        if (response.status === 200) {
            const blobURL = window.URL.createObjectURL(response.data);
            DownloadFile(blobURL, fileName);
        } else if (response.status === 404) {
            toast.error("Bestand niet gevonden");
        }
    });
};
