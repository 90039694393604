import React, { useState } from "react";
import {
    Autocomplete,
    Button,
    ButtonGroup,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Add, Delete, Refresh } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { deleteOrg, fetchOrgs } from "./OrganisationActions";

import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import NewOrganisationDialog from "./NewOrganisationDialog";
import { addUserToOrg } from "../UserManagement/UserActions";
import { global } from "../../context/global";
import { Capitalize, Pluralize } from "../../functions/Formatters";

export default function OrganisationOverview() {
    const dispatch = useDispatch();
    const organisations = useSelector((state) => state.organisations.organisations);
    const users = useSelector((state) => state.users.users);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [organisationIdToDelete, setOrganisationIdToDelete] = useState(null);
    const [newOrganisationDialogOpen, setNewOrganisationDialogOpen] = useState(false);

    const handleClickDeleteOrganisation = (id) => {
        setDeleteDialogOpen(true);
        setOrganisationIdToDelete(id);
    };
    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    const handleConfirmDeleteOrganisation = () => {
        deleteOrg(organisationIdToDelete, dispatch);
        closeDeleteDialog();
    };
    const handleClickNewOrganisation = () => {
        setNewOrganisationDialogOpen(true);
    };

    const NewAdminUserInput = ({ organisation }) => {
        const availableUsers = users.filter((user) => !organisation.admins.some((admin) => admin.id === user.id));
        const [inputOpen, setInputOpen] = useState(false);
        const [newAdminUser, setNewAdminUser] = useState(null);

        return (
            <>
                {inputOpen ? (
                    <Autocomplete
                        autoFocus
                        openOnFocus
                        options={availableUsers}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField autoFocus {...params} label="Extra beheerder" />}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => setNewAdminUser(value)}
                        onBlur={() => {
                            setInputOpen(false);
                            if (newAdminUser) {
                                addUserToOrg(newAdminUser, organisation.id, true, dispatch, () => setInputOpen(false));
                            }
                        }}
                    />
                ) : (
                    <Button onClick={() => setInputOpen(true)}>
                        <Add fontSize="small" />
                        {global.admin} {global.add}
                    </Button>
                )}
            </>
        );
    };

    return (
        <>
            <ConfirmDeleteDialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                dialogTitle={Capitalize(`${global.organisation} ${global.delete}`)}
                dialogContentText={`Weet u zeker dat u deze ${global.organisation} wilt verwijderen?`}
                dialogConfirmButton={Capitalize(global.delete)}
                handleConfirm={handleConfirmDeleteOrganisation}
            />
            <NewOrganisationDialog open={newOrganisationDialogOpen} onClose={() => setNewOrganisationDialogOpen(false)} />
            <Typography variant="h4" gutterBottom>
                {Capitalize(Pluralize(global.organisation))}
            </Typography>
            <ButtonGroup>
                <Button variant="contained" onClick={() => fetchOrgs(dispatch)}>
                    <Refresh />
                    Verversen
                </Button>
                <Button variant="contained" onClick={handleClickNewOrganisation}>
                    <Add />
                    Nieuwe {global.organisation}
                </Button>
            </ButtonGroup>

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h5">Naam</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h5">Beheerder(s)</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h5">Acties</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organisations.map((org) => (
                            <TableRow key={org.id}>
                                <TableCell>
                                    <Typography>{org.name}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        <Typography>{org.admins.map((user) => user.name).join(", ")}</Typography>
                                        <NewAdminUserInput organisation={org} />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <ButtonGroup>
                                        <IconButton variant="contained" onClick={() => handleClickDeleteOrganisation(org.id)}>
                                            <Delete />
                                        </IconButton>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
