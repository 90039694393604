import React from "react";
import { Dialog, DialogTitle, Typography, ButtonGroup, IconButton, Tooltip, Stack, DialogContent } from "@mui/material";

import { DownloadFile, OpenFileInTab } from "./AttachmentActions";
import { Close, Download, OpenInNew } from "@mui/icons-material";

/**
 * Renders a dialog component for displaying an image attachment.
 * The dialog includes buttons for opening the image in a new tab, downloading the image and closing the dialog.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.image - The image object containing the image URL and filename.
 * @param {boolean} props.image.open - Flag indicating whether the dialog is open or not.
 * @param {string} props.image.filename - The filename of the image.
 * @param {string} props.image.image - The URL of the image.
 * @param {Function} props.onClose - The function to be called when the dialog is closed.
 * @returns {JSX.Element} The rendered ImageDialog component.
 */
export const ImageDialog = ({ image, onClose }) => {
    return (
        <Dialog maxWidth="xl" open={image.open} onClose={onClose} scroll="paper" sx={{ p: 0 }}>
            <DialogTitle sx={{ p: 0 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">{image.filename}</Typography>
                    <ButtonGroup>
                        <IconButton onClick={() => OpenFileInTab(image.image)}>
                            <Tooltip placement="top-start" title={"Openen in nieuw tabblad"}>
                                <OpenInNew fontSize="large" />
                            </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => DownloadFile(image.image, image.filename)}>
                            <Tooltip placement="top-start" title={"Downloaden"}>
                                <Download fontSize="large" />
                            </Tooltip>
                        </IconButton>
                        <IconButton onClick={onClose}>
                            <Tooltip placement="top-start" title={"Sluiten"}>
                                <Close fontSize="large" />
                            </Tooltip>
                        </IconButton>
                    </ButtonGroup>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <img style={{ maxWidth: "100%" }} src={image.image} alt="attachment" />
            </DialogContent>
        </Dialog>
    );
};
