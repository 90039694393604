import React from "react";
import { useDispatch, useSelector } from "react-redux"; // Import Redux hooks
import * as Yup from "yup";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, MenuItem } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Select, TextField } from "formik-mui";
import { createTheme, updateThemeAction } from "./ThemeActions";

/**
 * ThemeDialog component for creating or editing a theme.
 *
 * @param {Object} props - Props object.
 * @param {boolean} props.open - Determines if the dialog is open.
 * @param {function} props.onClose - Callback to close the dialog.
 * @param {Object} props.edit - False if creating a new theme. Otherwise, the theme to edit.
 */
export default function ThemeDialog({ open, onClose, edit = false }) {
    const dispatch = useDispatch(); // Get the dispatch function from Redux
    const superthemes = useSelector((state) => state.themes.superthemes); // Get superthemes from Redux state

    const defaultValues = {
        name: !edit ? "" : edit.name,
        description: !edit ? "" : edit.description,
        supertheme: !edit ? "" : edit.supertheme,
    };

    /**
     * Handles the submission of the theme form.
     *
     * @param {string} name - The name of the theme.
     * @param {string} description - The description of the theme.
     * @param {number} superthemeID - The ID of the selected supertheme.
     */
    const handleSubmitTheme = (name, description, superthemeID) => {
        if (edit) {
            // If editing an existing theme, call the updateThemeAction
            updateThemeAction(
                {
                    id: edit.id,
                    name: name,
                    description: description,
                    supertheme: superthemeID,
                },
                dispatch,
                onClose
            );
        } else {
            // If creating a new theme, call the createTheme action
            createTheme(
                {
                    name: name,
                    description: description,
                    supertheme: superthemeID,
                },
                dispatch,
                onClose
            );
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={"new_theme_dialog"}>
            <Formik
                initialValues={{
                    name: defaultValues.name,
                    description: defaultValues.description,
                    supertheme: defaultValues.supertheme,
                }}
                validationSchema={Yup.object({
                    name: Yup.string().trim().required("Naam is vereist"),
                    description: Yup.string().trim(),
                    supertheme: Yup.number().required("Hoofdthema is vereist"),
                })}
                onSubmit={(values) => {
                    handleSubmitTheme(values.name, values.description, values.supertheme);
                }}
            >
                {({ isValid }) => (
                    <Form>
                        <DialogTitle>{!edit ? "Nieuw thema" : "Thema bewerken"}</DialogTitle>
                        <DialogContent>
                            <Stack spacing={2} sx={{ pt: 2 }}>
                                <Field
                                    component={TextField}
                                    name="name"
                                    label="Naam"
                                    type="text"
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                />
                                <Field
                                    component={TextField}
                                    name="description"
                                    label="Beschrijving"
                                    type="text"
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                />
                                <Field component={Select} name="supertheme" type="select" label="Hoofdthema">
                                    {superthemes.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                                Thema toevoegen
                            </Button>
                            <Button onClick={() => onClose()} color="primary">
                                Annuleren
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
