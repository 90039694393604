import React, { useMemo } from "react";
import { Clear } from "@mui/icons-material";
import {
    IconButton,
    Typography,
    Switch,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Tooltip,
    Box,
    Chip,
    Grid,
    Stack,
    TextField,
    Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserOverview from "./UserOverview";
import { ObjectFilter } from "../../functions/Filters";
import { Capitalize, Pluralize } from "../../functions/Formatters";
import { global, userOverview } from "../../context/global";
import { set } from "date-fns";
import MultiSelectFilter from "../CustomComponents/InputFields/MultiSelectFilter";

/**
 * AdminUsersOverview Component
 *
 * This React component provides an overview of users in an administrative context with filtering capabilities.
 * It allows administrators to filter users based on their attributes such as name, organization membership, and
 * whether they have administrative privileges. The filtered users are displayed using the UserOverview component.
 *
 * @component
 */
export default function AdminUsersOverview() {
    const users = useSelector((state) => state.users.users);
    const organisations = useSelector((state) => state.organisations.organisations);
    const [filteredUserIdArray, setFilteredUserIdArray] = useState(users.map((user) => user.id));

    const employerOptions = useMemo(() => {
        let employerSet = new Set();
        users.forEach((user) => {
            employerSet.add(user.employer);
        });
        return Array.from(employerSet).map((employer) => {
            return { value: employer, label: employer || "Onbekend" };
        });
    }, [users]);

    const organisationOptions = useMemo(() => {
        return organisations.map((org) => {
            return { value: org.id, label: org.name };
        });
    }, [organisations]);

    // State variables for user filtering
    const [nameFilter, setNameFilter] = useState("");
    const [orgFilter, setOrgFilter] = useState([]);
    const [employerFilter, setEmployerFilter] = useState([]);
    const [appAdminFilter, setAppAdminFilter] = useState(false);

    useEffect(() => {
        handleFilterChange();
    }, [orgFilter, appAdminFilter, nameFilter, users, employerFilter]);

    /**
     * Handle changes to the filtering criteria and update the filtered user list accordingly.
     */
    const handleFilterChange = () => {
        // Filter out non-admin users if the appAdminFilter is active
        let filterset = users.filter((user) => {
            if (appAdminFilter) {
                return user.is_staff;
            } else {
                return true;
            }
        });

        // Filter by name
        filterset = ObjectFilter(filterset, "name", nameFilter);

        // Filter by organization membership
        const orgFilterValues = orgFilter.map((org) => org.value);
        filterset = filterset.filter((user) => {
            if (orgFilterValues.length === 0) {
                return true; // Return all users if no organization filter is set
            } else {
                return user.organisations.some((org) => orgFilterValues.indexOf(org.id) > -1);
            }
        });

        // Filter by employer
        const employerFilterValues = employerFilter.map((employer) => employer.value);
        filterset = filterset.filter((user) => {
            if (employerFilterValues.length === 0) {
                return true; // Return all users if no employer filter is set
            } else {
                return employerFilterValues.includes(user.employer);
            }
        });
        setFilteredUserIdArray(filterset.map((user) => user.id));
    };

    const clearAllFilters = () => {
        setOrgFilter([]);
        setEmployerFilter([]);
        setNameFilter("");
        setAppAdminFilter(false);
    };

    const ClearNameFilterButton = () => (
        <>
            {nameFilter.length > 0 && (
                <Tooltip title="Selectie wissen">
                    <IconButton onClick={() => setNameFilter("")}>
                        <Clear />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );


    return (
        <>
            <Typography variant="h4" gutterBottom>
                Gebruikers
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    <Stack spacing={2}>
                        <Button variant="outlined" onClick={clearAllFilters}>
                            Filters wissen
                        </Button>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={appAdminFilter}
                                    onChange={(e) => setAppAdminFilter(e.target.checked)}
                                />
                            }
                            label={userOverview.filterAppAdmins}
                        />
                        <MultiSelectFilter
                            values={employerFilter}
                            options={employerOptions}
                            setValue={setEmployerFilter}
                            label={Capitalize(global.employer)}
                        />
                        <MultiSelectFilter
                            values={orgFilter}
                            options={organisationOptions}
                            setValue={setOrgFilter}
                            label={Capitalize(Pluralize(global.organisation))}
                        />
                        <Stack direction={"row"}>
                            <TextField
                                label={Capitalize(global.name)}
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                                size="standard"
                                fullWidth
                            />
                            <ClearNameFilterButton />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    <UserOverview
                        allowAdminActions={true}
                        allowStaffActions={true}
                        userIdArray={filteredUserIdArray}
                    />
                </Grid>
            </Grid>
        </>
    );
}
