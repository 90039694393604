import React, { useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Stack, Typography, Paper, Grid, Box, Tooltip } from "@mui/material";
import { ExpandMore, ArrowUpward, ArrowDownward, Close, InfoOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import QualKPIInput from "../../../CustomComponents/InputFields/QualKPIInput";
import QuantKPIInput from "../../../CustomComponents/InputFields/QuantKPIInput";
import QuantKPIInputSpreadsheet from "../../../CustomComponents/InputFields/QuantKPIInputSpreadsheet.js";
import { global, importanceLevels, scoreLevels } from "../../../../context/global";
import { EuroFormat, NumberFormat } from "../../../../functions/Formatters";
import { updateDesignScores } from "../DesignActions";
import InformationDialogue from "../../../CustomComponents/InformationDialogue.js";
import { TextField } from "formik-mui";
import { relativeScoreWarning } from "../../../../context/designPhase";

export default function DesignScoreComponent({ project, design, designScores, phaseID, loopID, editable }) {
    const dispatch = useDispatch();

    const flatThemes = useSelector((state) => state.themes.flatThemes);
    const flatSubthemes = useSelector((state) => state.themes.flatSubThemes);
    const referenceDesign = design.project === null ? project.reference_design : undefined;
    const [expanded, setExpanded] = useState(null);

    const projectThemes = project.themes;
    const filteredProjectThemes = projectThemes.filter((theme) => theme.subthemes.some((subtheme) => subtheme.goals.length > 0));

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCreateOrUpdateDesignScore = (scores, ids, projectThemeID) => {
        if (scores) {
            const scores_to_update = ids.reduce((acc, id) => {
                let score = scores[id];

                const theme = project.themes.find((theme) => theme.id === projectThemeID);
                const subtheme = theme.subthemes.find((subtheme) => subtheme.goals.some((goal) => goal.id === score.goal));
                if (subtheme !== undefined) {
                    score = { ...score, projectSubthemeID: subtheme.id };
                }
                return { ...acc, [id]: score };
            }, {});
            updateDesignScores(scores_to_update, phaseID, loopID, projectThemeID, dispatch);
        }
    };

    return (
        <Stack spacing={1}>
            {filteredProjectThemes.length > 0 ? (
                filteredProjectThemes.map((projecttheme, projectThemeIndex) => (
                    <Accordion key={projectThemeIndex} expanded={expanded === projectThemeIndex} onChange={handleChange(projectThemeIndex)} sx={{ m: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ m: 0 }}>
                            <Stack>
                                <Typography variant="h6">
                                    {flatThemes[projecttheme.theme]?.name}
                                    <Tooltip title={flatThemes[projecttheme.theme].description}>
                                        <InfoOutlined fontSize="small" />
                                    </Tooltip>
                                </Typography>
                                <Typography>Niveau: {importanceLevels[projecttheme.importance].label} </Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{ m: 0 }}>
                            <Formik
                                initialValues={{
                                    values: designScores.reduce((acc, score) => {
                                        return { ...acc, [score.id]: score };
                                    }, {}),
                                    comments: designScores.reduce((acc, comment) => {
                                        return { ...acc, [comment.id]: comment };
                                    }, {}),
                                    indexes: [],
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    handleCreateOrUpdateDesignScore(values["values"], values["indexes"], projecttheme.id, projecttheme.id);
                                    setSubmitting(false);
                                }}
                            >
                                {({ values }) => (
                                    <Form>
                                        {projecttheme.subthemes.map((projectsubtheme, subthemeIndex) => {
                                            const subtheme = flatSubthemes[projectsubtheme.subtheme];
                                            if (designScores !== null) {
                                                return (
                                                    <Paper key={`subtheme_${subthemeIndex}`} elevation={0}>
                                                        <Typography fontWeight={"bold"}>Aspect: {subtheme.name}</Typography>
                                                        {subtheme.description.length > 0 ? (
                                                            <Typography>
                                                                <InformationDialogue
                                                                    text={{
                                                                        icon: "information",
                                                                        title: "Toelichting",
                                                                        fullText: [projectsubtheme.description],
                                                                    }}
                                                                />
                                                            </Typography>
                                                        ) : (
                                                            ""
                                                        )}

                                                        <Stack spacing={2}>
                                                            {projectsubtheme.goals.map((goal, goalIndex) => {
                                                                return (
                                                                    <Box key={`goal_${goalIndex}`}>
                                                                        {designScores.map((score, index) => {
                                                                            if (score.goal === goal.id) {
                                                                                values.indexes.includes(score.id) ? "" : values.indexes.push(score.id);
                                                                                const kpi = subtheme.kpis.find((kpi) => kpi.id === goal.kpi);
                                                                                return (
                                                                                    <Grid container key={`gridcontainer_subtheme_${subtheme.id}_goal_${goalIndex}`}>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography>KPI</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={10}>
                                                                                            <Typography>{kpi.name}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography>Doel</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={10}>
                                                                                            <Typography>
                                                                                                {kpi.scale_type === 0
                                                                                                    ? scoreLevels[goal.value - 1]?.label
                                                                                                    : `${kpi.maximize ? "≥" : "≤"} ${kpi.unit === "€" ? EuroFormat(goal.value) : `${NumberFormat(goal.value)} ${kpi.unit}`}`}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        {kpi.scale_type === 0 ? (
                                                                                            <>
                                                                                                {referenceDesign ? (
                                                                                                    <>
                                                                                                        <Grid item xs={2}>
                                                                                                            <Typography>Referentie score</Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={10}>
                                                                                                            {scoreLevels[referenceDesign.scores.find((score) => score.kpi === kpi.id).value - 1]?.label}
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                                <Grid item xs={2}>
                                                                                                    <Typography>Score</Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={10}>
                                                                                                    <Field
                                                                                                        value={Number(score.value)}
                                                                                                        component={QualKPIInput}
                                                                                                        marks={scoreLevels}
                                                                                                        step={1}
                                                                                                        min={1}
                                                                                                        max={5}
                                                                                                        sx={{ maxWidth: 600 }}
                                                                                                        index={index}
                                                                                                        name={`values[${score.id}].value`}
                                                                                                        kpi={kpi}
                                                                                                        disabled={!editable}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Field
                                                                                                component={kpi.scale_type === 1 ? QuantKPIInputSpreadsheet : QuantKPIInput}
                                                                                                index={index}
                                                                                                name={`values[${score.id}].value`}
                                                                                                kpi={kpi}
                                                                                                segments={project.phases.find((phase) => phase.id === phaseID)?.segments || [{ id: "total", name: "Totaal" }]}
                                                                                                layers={project.potential_layers.filter((layer) => project.layers.includes(layer.id))}
                                                                                                value={score.value}
                                                                                                disabled={!editable}
                                                                                                referenceScore={referenceDesign?.scores.find((score) => score.kpi === kpi.id).value}
                                                                                                helperText={!design.loop && kpi.unit === "%" && relativeScoreWarning}
                                                                                            />
                                                                                        )}
                                                                                        <Grid item xs={2}>
                                                                                            <Typography>Opmerking</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={10}>
                                                                                            <Field
                                                                                                sx={{ width: '100%', maxWidth: 600 }}
                                                                                                component={TextField}
                                                                                                index={index}
                                                                                                name={`values[${score.id}].comment`}
                                                                                                disabled={!editable}
                                                                                                InputProps={{
                                                                                                    multiline: true,
                                                                                                    minRows: 1,
                                                                                                    maxRows: 6,
                                                                                                }}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Stack>
                                                    </Paper>
                                                );
                                            }
                                        })}

                                        <ButtonGroup variant="outlined">
                                            {projectThemeIndex > 0 && (
                                                <Button
                                                    type="submit"
                                                    onClick={() => {
                                                        setExpanded(Math.max(projectThemeIndex - 1, 0));
                                                    }}
                                                    startIcon={<ArrowUpward />}
                                                >
                                                    {global.save} {global.and} {global.previous} {global.theme}
                                                </Button>
                                            )}
                                            {projectThemeIndex < filteredProjectThemes.length - 1 ? (
                                                <Button
                                                    type="submit"
                                                    onClick={() => {
                                                        setExpanded(Math.min(projectThemeIndex + 1, filteredProjectThemes.length - 1));
                                                    }}
                                                    endIcon={<ArrowDownward />}
                                                >
                                                    {global.save} {global.and} {global.next} {global.theme}
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    onClick={() => {
                                                        setExpanded(undefined);
                                                    }}
                                                    endIcon={<Close />}
                                                >
                                                    {global.save} {global.and} {global.close}
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </Form>
                                )}
                            </Formik>
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <></>
            )}
        </Stack>
    );
}
