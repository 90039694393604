import React from "react";
import { Grid, Typography } from "@mui/material";
import { Field } from "formik";
import CustomNumberField from "./CustomNumberfield";

export default function QuantKPIInput({ field, form, ...props }) {
    const kpi = props.kpi;

    if (isNaN(Number(field.value) || field.value === null)) {
        form.setFieldValue(field.name, null);
        return null;
    }

    return (
        <>
            {props.referenceScore && (
                <>
                    <Grid item xs={2}>
                        <Typography>Referentie score</Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <Typography>
                            {kpi.unit === "€"
                                ? `€ ${props.referenceScore}`
                                : `${props.referenceScore} ${kpi.unit}`}
                        </Typography>
                    </Grid>
                </>
            )}
            <Grid item xs={2}>
                <Typography>Score</Typography>
            </Grid>
            <Grid item xs={10}>
                <Field
                    component={CustomNumberField}
                    size="small"
                    name={field.name}
                    form={form}
                    id={field.name}
                    unit={kpi.unit}
                    disabled={props.disabled}
                    helperText={props.helperText}
                />
            </Grid>
        </>
    );
}
