import React from "react";
import { Slider } from "@mui/material";

export const QualKPIInput = ({ field, form, ...props }) => (
    <Slider
        min={props.min}
        max={props.max}
        step={props.step}
        {...props}
        value={isNaN(field.value) ? Number(field.value) : field.value}
        onChange={(e, value) => form.setFieldValue(field.name, value)}
        disabled={props.disabled}
    />
);

export default QualKPIInput;
