import React from "react";
import { Outlet } from "react-router-dom";

/**
 * ThemePage Component
 *
 * This React component serves as a placeholder for rendering child components associated with
 * the theme-related pages in the application. It acts as a container for displaying content
 * based on the routing configuration provided by React Router.
 *
 * @component
 */
const ThemePage = () => {
    return (
        <>
            <Outlet />
        </>
    );
};

export default ThemePage;
