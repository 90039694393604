import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    PointElement,
    LineElement,
    LineController,
    BarController,
    Filler,
} from "chart.js";
import { Chart } from "react-chartjs-2";

export default function QuantGraphGlobal({ variants, initData, kpi, goal }) {
    const decoratedData = [
        {
            label: "Doel" + (kpi.maximize ? "(min)" : "(max)"),
            data: Array(variants.length).fill(goal.value),
            type: "line",
            borderWidth: 4,
            borderColor: "red",
            pointStyle: false,
            borderDash: [10, 10],
        },
        {
            ...initData,
            label: "Totaal",
        },
    ];

    ChartJS.register(
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Legend,
        Tooltip,
        LineController,
        BarController,
        Colors,
        Title,
        Filler
    );

    const options = {
        plugins: {
            title: {
                display: true,
                text: kpi.name,
                font: {
                    size: 14,
                },
            },
            filler: {
                propagate: true,
            },
            legend: {
                display: true,
                align: "start",
                position: "chartArea",
                labels: {
                    filter: function (legendItem) {
                        return legendItem.text !== "Totaal";
                    },
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: kpi.unit,
                },
            },
        },
    };

    const data = {
        labels: variants,
        datasets: decoratedData,
    };
    if (decoratedData === null) {
        return null;
    }

    return <Chart type="bar" options={options} data={data} redraw />;
}
