import { createSlice } from "@reduxjs/toolkit";

const initialState = { organisations: [] };

const organisationSlice = createSlice({
    name: "organisations",
    initialState,
    reducers: {
        setOrganisations(state, action) {
            state.organisations = action.payload;
        },
        addOrganisation(state, action) {
            state.organisations = [...state.organisations, action.payload];
        },
        removeOrganisation(state, action) {
            state.organisations = state.organisations.filter((organisation) => organisation.id !== action.payload);
        },
        clearOrganisations(state) {
            state.organisations = [];
        },
        updateOrganisation(state, action) {
            const nextState = state.organisations.map((organisation) => {
                if (organisation.id === action.payload.id) {
                    return action.payload;
                }
                return organisation;
            });
            state.organisations = nextState;
        },
    },
});

export const { setOrganisations, addOrganisation, removeOrganisation, clearOrganisations, updateOrganisation } = organisationSlice.actions;

export default organisationSlice.reducer;
