export function ObjectFilter(objects, filterKey, filterValue, filterType = "string", filterOperator = "contains") {
    // filterType: string, number
    // filterOperator: contains, equals, greaterThan, lessThan

    if (!objects) {
        return null;
    } else if (!filterKey || !filterValue || !filterType || !filterOperator) {
        return objects;
    } else if (filterType !== "string" && filterType !== "number") {
        return objects;
    } else if (filterOperator !== "contains" && filterOperator !== "equals" && filterOperator !== "greaterThan" && filterOperator !== "lessThan") {
        return objects;
    }
    const filtered = objects.filter((object) => {
        if (filterType === "string") {
            if (filterOperator === "contains") {
                return object[filterKey].toLowerCase().includes(filterValue.toLowerCase());
            } else if (filterOperator === "equals") {
                return object[filterKey].toLowerCase() === filterValue.toLowerCase();
            }
        } else if (filterType === "number") {
            if (filterOperator === "equals") {
                return object[filterKey] === filterValue;
            } else if (filterOperator === "greaterThan") {
                return object[filterKey] > filterValue;
            } else if (filterOperator === "lessThan") {
                return object[filterKey] < filterValue;
            }
        }
    });
    return filtered;
}

export function StringFilter(array, filterValue, filterOperator = "contains") {
    // filterOperator: contains, equals, greaterThan, lessThan

    if (!array) {
        return null;
    } else if (!filterValue || !filterOperator) {
        return array;
    } else if (filterOperator !== "contains" && filterOperator !== "equals" && filterOperator !== "greaterThan" && filterOperator !== "lessThan") {
        return array;
    }
    const filtered = array.filter((string) => {
        if (filterOperator === "contains") {
            return string.toLowerCase().includes(filterValue.toLowerCase());
        } else if (filterOperator === "equals") {
            return string.toLowerCase() === filterValue.toLowerCase();
        }
    });
    return filtered;
}

export function NumberFilter(array, filterValue, filterOperator = "equals") {
    // filterOperator: equals, greaterThan, lessThan

    if (!array) {
        return null;
    } else if (!filterValue || !filterOperator) {
        return array;
    } else if (filterOperator !== "equals" && filterOperator !== "greaterThan" && filterOperator !== "lessThan") {
        return array;
    }
    const filtered = array.filter((number) => {
        if (filterOperator === "equals") {
            return number === filterValue;
        } else if (filterOperator === "greaterThan") {
            return number > filterValue;
        } else if (filterOperator === "lessThan") {
            return number < filterValue;
        }
    });
    return filtered;
}
