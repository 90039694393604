import React from "react";
import {
    Button,
    ButtonGroup,
    Container,
    FormControlLabel,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAccount, logout } from "../store/slices/authSlice";
import { fetcher } from "../utils/axios";
import ToggleableInputField from "../components/CustomComponents/InputFields/ToggleableInputField";
import { patchCurrentUser } from "../components/UserManagement/UserActions";
import ChangePasswordForm from "../components/UserManagement/PasswordChangeForm";
import { Logout, Refresh } from "@mui/icons-material";
import { global, profilePage } from "../context/global";
import { Field, Form, Formik } from "formik";
import { Checkbox } from "formik-mui";
/**
 * Profile Component
 *
 * This React component represents the user profile page. It displays user information, allows
 * the user to change their password, and provides options for logging out and refreshing data.
 *
 * @component
 */
const Profile = () => {
    const auth = useSelector((state) => state.auth); // Retrieve authentication information from Redux store
    const dispatch = useDispatch(); // Redux dispatch function
    const [passwordChangeOpen, setPasswordChangeOpen] = React.useState(false); // State for password change form

    // Function to handle user logout
    const handleLogout = () => {
        dispatch(logout()); // Dispatch the logout action
    };

    // Function to retrieve user data from the server (testing if the token still works)
    const handleRetrieveMe = () => {
        fetcher(`/users/me/`).then((res) => {
            dispatch(setAccount(res.data)); // Update user account information in Redux store
        });
    };

    // Create a list of user's memberships in organizations, including admin roles
    const membershipListItems =
        auth.account.organisations.length > 0 ? (
            <ul>
                {auth.account.organisations.map((org) => (
                    <li key={org.id}>
                        <Typography>
                            {org.name}{" "}
                            {auth.account.managed_organisations
                                .map((org) => org.id)
                                .includes(org.id)
                                ? `(${global.admin})`
                                : ""}
                        </Typography>
                    </li>
                ))}
            </ul>
        ) : (
            <Typography>{profilePage.noOrganisations}</Typography>
        );

    return (
        <Container maxWidth="md" component={Paper}>
            <Typography variant="h4" gutterBottom>
                {profilePage.title}
            </Typography>
            <Typography variant="h6">{profilePage.personalData}</Typography>
            <ToggleableInputField
                label={profilePage.name}
                field="name"
                value={auth.account.name}
                onSave={(data) => patchCurrentUser({ ...data, id: auth.account.id }, dispatch)}
            />
            <ToggleableInputField
                label={profilePage.employer}
                field="employer"
                value={auth.account.employer}
                onSave={(data) => patchCurrentUser({ ...data, id: auth.account.id }, dispatch)}
            />
            <ToggleableInputField
                label={global.email}
                field="email"
                value={auth.account.email}
                onSave={(data) => patchCurrentUser({ ...data, email: data.email.toLowerCase(), id: auth.account.id }, dispatch)}
            />
            {passwordChangeOpen ? (
                // Render the password change form
                <ChangePasswordForm
                    userID={auth.account.id}
                    handleClose={() => setPasswordChangeOpen(false)}
                />
            ) : (
                <>
                    <Typography>{profilePage.password}: ●●●●●●●●</Typography>
                    <Button onClick={() => setPasswordChangeOpen(true)}>
                        {profilePage.changePassword}
                    </Button>
                </>
            )}
            <br />

            <Typography variant="h6">{profilePage.mailings}</Typography>
            <Formik
                initialValues={{
                    mailing_duda: auth.account.mailing_duda,
                    mailing_roadmap: auth.account.mailing_roadmap,
                }}
                enableReinitialize
                onSubmit={(values) => {
                    patchCurrentUser({...values, id: auth.account.id}, dispatch);
                }}
            >
                {({dirty}) => (
                <Form>
                    <Stack>
                        <FormControlLabel
                            control={
                                <Field component={Checkbox} type="checkbox" name="mailing_duda" />
                            }
                            label={profilePage.dudaMailing}
                        />
                        <FormControlLabel
                            control={
                                <Field
                                    component={Checkbox}
                                    type="checkbox"
                                    name="mailing_roadmap"
                                />
                            }
                            label={profilePage.roadmapMailing}
                        />
                    </Stack>
                        {dirty && <Button type="submit">{global.save}</Button>}
                </Form>
                    )}
            </Formik>

            <Typography variant="h6">{profilePage.roles}</Typography>
            <Stack direction="row" spacing={2}>
                <Stack>
                    <Typography>{profilePage.appAdmin}:</Typography>
                </Stack>
                <Stack>
                    <Typography>{auth.account.is_staff ? "Ja" : "Nee"}</Typography>
                </Stack>
            </Stack>
            <br />

            <Typography variant="h6">{profilePage.organisations}</Typography>
            {membershipListItems}
            <br />

            {/* Button group for logout and data refresh actions */}
            <ButtonGroup variant="contained">
                <Button onClick={handleLogout} color="primary">
                    <Logout />
                    {profilePage.logout}
                </Button>
                <Button onClick={handleRetrieveMe} color="primary">
                    <Refresh />
                    {profilePage.fetchData}
                </Button>
            </ButtonGroup>
        </Container>
    );
};

export default Profile;
