import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchProjects, deleteProject, unDeleteProject } from "../ProjectActions";
import { Button, ButtonGroup, FormControlLabel, Grid, Stack, Switch, Typography, TextField } from "@mui/material";

import ProjectCard from "./ProjectCard";
import NewProjectDialog from "../NewProjectDialog";
import { Add, Refresh } from "@mui/icons-material";
import ConfirmDeleteDialog from "../../ConfirmDeleteDialog";
import { global } from "../../../context/global";
import MultiSelectFilter from "../../CustomComponents/InputFields/MultiSelectFilter";
import { Capitalize, Pluralize } from "../../../functions/Formatters";

const ProjectOverview = () => {
    const dispatch = useDispatch();
    const [newProjectDialogOpen, setNewProjectDialogOpen] = useState(false);

    const account = useSelector((state) => state.auth.account);
    const projects = useSelector((state) => state.projects.projects);
    const organisations = useSelector((state) => state.organisations.organisations);

    const organisationOptions = useMemo(() => {
        return organisations.map((org) => ({ label: org.name, value: org.id })).sort((a, b) => a.label.localeCompare(b.label));
    }, [organisations]);

    const yearOptions = useMemo(() => {
        const years = new Set();
        projects.forEach((project) => {
            years.add(new Date(project.start_date).getFullYear());
        });
        const yearArray = Array.from(years).sort((a, b) => a - b);
        return yearArray.map((year) => ({ label: year > 1970 ? year : "Onbekend", value: year }));
    }, [projects]);

    const phaseOptions = useMemo(() => {
        const phases = new Set();
        projects.forEach((project) => {
            if (project.phases.length > 0) {
                phases.add(project.phases.slice(-1)[0].phase_name);
            } else {
                phases.add("Opstartfase");
            }
        });
        const phaseArray = Array.from(phases).sort((a, b) => a.localeCompare(b));
        return phaseArray.map((phase) => ({ label: phase, value: phase }));
    }, [projects]);

    const [filteredProjects, setFilteredProjects] = useState(projects);
    const [showDeleted, setShowDeleted] = useState(false);
    const [orgFilter, setOrgFilter] = useState([]);
    const [yearFilter, setYearFilter] = useState([]);
    const [phaseFilter, setPhaseFilter] = useState([]);
    const [nameFilter, setNameFilter] = useState("");

    const filterProjects = (organisations, phases, deleted, years, name) => {
        setOrgFilter(organisations);
        setPhaseFilter(phases);
        setShowDeleted(deleted);
        setYearFilter(years);
        setNameFilter(name);
        const orgsToInclude = organisations.length === 0 ? organisations : organisations.map((org) => org.value);
        const phasesToInclude = phases.length === 0 ? phases : phases.map((phase) => phase.value);
        const yearsToInclude = years.length === 0 ? years : years.map((year) => year.value);

        setFilteredProjects(
            projects.filter(
                (project) =>
                    (orgsToInclude.length === 0 || orgsToInclude.includes(project.organisation.id)) &&
                    ((phases === "Opstartfase" && project.phases.length === 0) ||
                        phasesToInclude.includes(project.phases.slice(-1)[0]?.phase_name) ||
                        phasesToInclude.length === 0) &&
                    (deleted ? project.deleted : !project.deleted) &&
                    (yearsToInclude.length === 0 || yearsToInclude.includes(new Date(project.start_date).getFullYear())) &&
                    (name === "" || project.name.toLowerCase().includes(name.toLowerCase()))
            ).sort((a, b) => a.name.localeCompare(b.name))
        );
    };
    useEffect(() => {
        filterProjects([], [], showDeleted, [], "");
    }, [projects]);

    const clearFilters = () => {
        setOrgFilter([]);
        setPhaseFilter([]);
        setYearFilter([]);
        filterProjects([], [], showDeleted, [], "");
    };

    const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const handleDeleteProject = (project) => {
        setProjectToDelete(project);
        setDeleteProjectOpen(true);
    };
    const [restoreProjectOpen, setRestoreProjectOpen] = useState(false);
    const [projectToRestore, setProjectToRestore] = useState(null);
    const handleRestoreProject = (project) => {
        setProjectToRestore(project);
        setRestoreProjectOpen(true);
    };

    return (
        <>
            <NewProjectDialog open={newProjectDialogOpen} onClose={() => setNewProjectDialogOpen(false)} />
            <ConfirmDeleteDialog
                open={deleteProjectOpen}
                onClose={() => setDeleteProjectOpen(false)}
                handleConfirm={() => {
                    deleteProject(projectToDelete?.id, dispatch);
                    setDeleteProjectOpen(false);
                }}
                dialogTitle={`Project ${projectToDelete?.deleted ? "definitief" : ""} verwijderen?`}
                dialogContentText={`Weet u zeker dat u project "${projectToDelete?.name}"${
                    projectToDelete?.deleted ? " definitief" : ""
                } wilt verwijderen?`}
                dialogConfirmButton="Verwijderen"
                critical={projectToDelete?.deleted}
                confirmationText={projectToDelete?.name}
            />
            <ConfirmDeleteDialog
                open={restoreProjectOpen}
                onClose={() => setRestoreProjectOpen(false)}
                handleConfirm={() => {
                    unDeleteProject(projectToRestore?.id, dispatch);
                    setRestoreProjectOpen(false);
                }}
                dialogTitle={`Project terugzetten?`}
                dialogContentText={`Weet u zeker dat u project "${projectToRestore?.name}" wilt terugzetten?`}
                dialogConfirmButton="Terugzetten"
            />

            <Typography variant="h4" component="h1" gutterBottom>
                {showDeleted ? "Verwijderde projecten" : "Projecten"}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    <Stack direction={{ xs: "column", sm: "row", md: "column" }} spacing={1}>
                        <Button sx={{spacing: 2}} variant="outlined" onClick={clearFilters}>
                            Filters wissen
                        </Button>
                        <TextField
                            label="Projectnaam"
                            value={nameFilter}
                            onChange={(e) => {
                                filterProjects(orgFilter, phaseFilter, showDeleted, yearFilter, e.target.value);
                            }}
                        />
                        {organisations.length > 1 && (
                            <MultiSelectFilter
                                values={orgFilter}
                                options={organisationOptions}
                                setValue={(value) => {
                                    filterProjects(value, phaseFilter, showDeleted, yearFilter, nameFilter);
                                }}
                                label={Capitalize(Pluralize(global.organisation))}
                            />
                        )}
                        <MultiSelectFilter
                            values={phaseFilter}
                            options={phaseOptions}
                            setValue={(value) => {
                                filterProjects(orgFilter, value, showDeleted, yearFilter, nameFilter);
                            }}
                            label="Fase"
                        />

                        <MultiSelectFilter
                            values={yearFilter}
                            options={yearOptions}
                            setValue={(value) => filterProjects(orgFilter, phaseFilter, showDeleted, value, nameFilter)}
                            label="Jaar"
                        />

                    </Stack>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
                        <ButtonGroup variant="contained">
                            <Button startIcon={<Refresh />} onClick={() => fetchProjects(dispatch)}>
                                Verversen
                            </Button>
                            {account.is_org_admin && !showDeleted && (
                                <Button startIcon={<Add />} onClick={() => setNewProjectDialogOpen(true)}>
                                    Nieuw project
                                </Button>
                            )}
                        </ButtonGroup>
                        {account.is_org_admin && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showDeleted}
                                        onChange={() =>
                                            filterProjects(orgFilter, phaseFilter, !showDeleted, yearFilter)
                                        }
                                        name="showDeleted"
                                        color="primary"
                                    />
                                }
                                label="Toon verwijderde projecten"
                            />
                        )}
                    </Stack>

                    {filteredProjects.length === 0 ? (
                        <Typography variant="h5">Geen projecten gevonden</Typography>
                    ) : (
                        <Grid container spacing={2}>
                            {filteredProjects.map((project) => (
                                <ProjectCard
                                    key={project.id}
                                    project={project}
                                    handleDeleteProject={handleDeleteProject}
                                    handleRestoreProject={handleRestoreProject}
                                />
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ProjectOverview;
