export const summary = {
    title: "Algemeen",
    description:
        "Hier kunt de algemene informatie over het project invullen, zoals de naam, beschrijving, en de betrokken medewerkers. Bij eigenaren geeft u aan welk medewerkers de mogelijkheid hebben om data en informatie aan het project toe te voegen of te verwijderen. Bij lezers selecteert u de medewerkers die toegang hebben tot het project, maar geen wijzigingen kunnen doorvoeren.",
    explanationName: "De naam van het project",
    explanationDescription: "Een beschrijving van het project",
    explanationOwners:
        "Eigenaars van het project kunnen het project inzien en bewerken, en de status van het project wijzigen. Eigenaars moeten lid zijn van de organisatie waar het project onder valt.",
    explanationCollaborators:
    "Medewerkers van het project kunnen het project inzien en bewerken, maar niet de status aanpassen. Medewerkers moeten lid zijn van de organisatie waar het project onder valt.",
    explanationReaders: "Lezers van het project kunnen het project inzien. Lezers moeten lid zijn van de organisatie waar het project onder valt.",
    attachments: "Hier kunt u bestanden toevoegen die betrekking hebben op het project.",
    status: "Hier kunt u een status selecteren. De status geeft aan vanaf welk punt de gegevens nog aangepast kunnen worden. Deze afscherming zorgt ervoor dat de informatie in latere stadia correct wordt weergegeven.",
};

export const layers = {
    title: "Onderdelen",
    description:
        "Kies hier de onderdelen die van toepassing zijn voor uw project. De onderdelen kunnen worden gebruikt om in een later stadium detailanalyses voor bepaalde thema’s uit te voeren. Standaard zijn alle algemene onderdelen aangevinkt. Daarnaast is er de mogelijkheid om specifieke onderdelen voor het project toe te voegen.",
    tableHeaderGeneral: "Algemene onderdelen",
    tableHeaderSpecific: "Project-specifieke onderdelen",
    addLayerButton: "Onderdeel toevoegen",
};

export const themes = {
    title: "Project thema's",
    //full text is separated in paragraphs, because for some reason all linebreaks are removed from this output.
    shortText: `Selecteer per thema van de duurzaamheidsroos het gewenste ambitieniveau, en geef indien wenselijk een korte toelichting wat wordt beoogd binnen dit thema. Klik hier onder voor een toelichting hoe de ambitieniveaus (en doelstellingen) kunnen worden vastgesteld. `,
    fullText: [
        `Duurzaamheid is een brede opgave; om doelgericht te werken is het belangrijk om te bepalen welke duurzaamheidsthema’s relevant zijn in jullie projectcontext.Tegelijkertijd is het ook belangrijk dat duurzaamheid een integraal onderdeel is van het project en dat de verantwoordelijkheid bij de gehele projectorganisatie ligt.`,
        `Om het bewustzijn binnen de projectorganisatie te vergroten, is een serious game ontwikkeld dat inzicht geeft in de dilemma’s en keuzes die mogelijk gemaakt moeten worden. Dit spel kan dienen als basis voor het uiteindelijk bepalen van de duurzaamheidsfocus voor dit project. Uiteraard is het mogelijk om direct met de projectorganisatie de gewenste ambitieniveaus te bepalen en de bijbehorende doelstellingen.`,
        ``,
        `De serious game ‘Duurzame dijken’ dient in eerste instantie 2 doelen:`,
        `<list>
 De betrokkenheid van de projectorganisatie te vergroten in het bepalen van de duurzaamheidsdoelen en hier invulling aan te geven. </>
 Inzicht vergroten binnen de projectorganisatie in wat de impact is van bepaalde keuzes en wat dit mogelijk betekent voor de duurzaamheidsambities van een project.
 </list>`,
        `Het spelen van het spel omvat 2 stappen: het daadwerkelijk spelen en het nabespreken van het spelverloop.De tweede stap is bedoeld om het(extra) inzicht te verschaffen in de mogelijke afwegingen, tegenstrijdige belangen en wat duurzaamheid kan betekenen in een project.Voor beide stappen wordt in de set uitgelegd hoe deze te doorlopen.`,
        `Onder aan de pagina, onder Downloads, vind je het spel.`,
        `In de focussessie wordt bepaald wat de focus is qua duurzaamheid en ruimtelijke kwaliteit in de komende fase. Het is aan te bevelen om niet voor alle thema’s uit de duurzaamheidsroos het maximale ambitieniveau te selecteren, maar hier gericht afwegingen in te maken. `,
        `Gedurende de sessie moet antwoord worden gegeven op de volgende 3 vragen:`,
        `<list>
  Op welk ambitieniveau de verschillende duurzaamheidsthema’s (en onderliggende) aspecten moeten worden meegenomen?</>
  Welke duurzaamheidsdoelstellingen gekoppeld kunnen worden aan de belangrijkste duurzaamheidsthema’s in het project? </>
 Met welke Key Performance Indicators (KPI’s) moet de voortgang voor duurzaamheid gedurende het project worden gemeten?
 </list>
 `,
    ],
};

export const goals = {
    title: "Project doelen",
    //full text is separated in paragraphs, because for somer reason all linebreaks are removed from this output.
    fullText: [
        `Geef per duurzaamheidsthema aan wat de doelstelling is en met behulp van welke KPI deze doelstelling meetbaar wordt gemaakt. Het is mogelijk om een korte toelichting te geven bij de keuze voor een bepaalde KPI. `,
    ],
};
export const goals2 = "Bepaal wat uw referentie ontwerp is, vóór u de doelen invult. U kunt dan de doelen berekenen ten opzichte van deze referentie."

export const referenceDesign = {
    title: "Project referentie",
    //full text is separated in paragraphs, because for somer reason all linebreaks are removed from this output.
    shortText: `Om ontwerpen in een later stadium te kunnen vergelijken, kan gekozen worden om een referentie-ontwerp op te nemen in de tool. Het referentie-ontwerp kan een eerste (schets)uitwerking zijn van de eindoplossing of de huidige status quo. 
    In de planuitwerkingsfase kan er ook worden gekozen om het voorkeursalternatief (VKA) als referentie te hanteren.`,
    fullText: [
        `<h>Ruimtelijke Kwaliteit`,
        `Voor het hoofdthema Ruimtelijke Kwaliteit is het wenselijk om de huidige situatie en de bijbehorende kernwaardes goed te definiëren. Op deze manier is het mogelijk om later ontwerpoplossingen te vergelijken met de huidige situatie. `,
        `In het algemeen is er hier een sterke link met het Ruimtelijk Kwaliteitskader (RKK): hierin worden de huidige kenmerken en karakteristieken beschreven van het gebied beschreven. `,
        `<h>Klimaat & Energie en Circulariteit`,
        `Voor de thema’s op het gebied van Klimaat & Energie en Circulariteit is het wenselijk om de duurzaamheidsimpact van een (schets)ontwerp te bepalen. Zo kan later in het project worden gekeken, want de behaalde duurzaamheidswinst is t.o.v. het initiële (schets)ontwerp.`,
        `Om deze winst in een later stadium inzichtelijk te maken, dient de milieu-impact (MKI (€) of CO2-eq (ton) ) en/of het materiaalgebruik (massabalans, % primair / secundair) inzichtelijk te worden gemaakt. `,
        `Mogelijk is aan het begin van het project nog geen ontwerp beschikbaar is; er dient dan gerekend te worden met grove hoeveelheden en aannames. Belangrijk hierbij is om de uitgangspunten en aannames goed te documenteren en argumenteren, voor een eerlijk vergelijk in een later stadium.`,
    ],
};
