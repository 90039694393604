import React from "react";
import { useSelector } from "react-redux";
import ProjectOverview from "../components/Projects/Overview/ProjectOverviewGrid";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Container, Paper } from "@mui/material";

/**
 * PlaceholderMainPage Component
 *
 * This React component represents the main page for the application. It is displays the projectoverview component
 * when the user is member of an organisation, otherwise it redirects admin users to the admin page
 * and normal users to the profile page.
 *
 * @component
 */
export default function PlaceholderMainPage() {
    const account = useSelector((state) => state.auth.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (!account.is_org_member) {
            if (account.is_staff) {
                navigate("/admin");
            } else {
                navigate("/profile");
            }
        }
    }, []);

    return (
        <Container component={Paper} maxWidth="xl">
            <ProjectOverview />
        </Container>
    );
}
