import { toast } from "react-toastify";
import { poster, patcher, deleter, fetcher } from "../../../utils/axios.js";
import { setReferenceDesign, updateDesign, addDesign, removeDesign, updateScores } from "../../../store/slices/projectsSlice.js";
import { addLoopToPhaseWithDesigns } from "../ProjectActions.js";
import { OpenFileInTab } from "../../Attachments/AttachmentActions.js";

export const createDesign = (data, phaseID, loopID, dispatch, setCreating) => {
    if (data.name) {
        poster("designs/", data).then((response) => {
            if (response?.status === 201) {
                if (!data.project) {
                    toast.success("Ontwerp gemaakt");
                    dispatch(addDesign({ ...response.data, phase: phaseID, loop: loopID }));
                } else {
                    toast.success("Referentie ontwerp gemaakt");
                    dispatch(setReferenceDesign(response.data));
                    setCreating(false);
                }
            }
        });
    } else {
        toast.error("Geen data");
    }
};

export const patchMultipleDesignsAndAddLoop = (list, projectID, phaseID, loopID, dispatch, performAction) => {
    //this creates an array with url
    let promiseArray = Object.entries(list["preferred"]).map((key) => {
        const data = {
            is_preferred: key[1],
            comment: Object.entries(list["comments"]).find((comment) => comment[0] === key[0])[1],
        };
        patcher(`designs/${key[0]}/`, data);
    });
    Promise.allSettled(promiseArray)
        .then((data) => {
            data.map((response) => {
                if (response?.status !== "fulfilled") {
                    toast.error("Niet alle ontwerpen zijn opgeslagen");
                }
            });
        })
        .then(() => {
            let preferred_designs = {
                designs: Object.entries(list["preferred"])
                    .map((key) => {
                        if (key[1] === true) return parseInt(key[0]);
                    })
                    .filter((value) => value !== undefined),
            };
            addLoopToPhaseWithDesigns(projectID, preferred_designs, dispatch);
        });
};

export const patchDesign = (data, phaseID, loopID, dispatch, closeDialog) => {
    patcher(`designs/${data.id}/`, data).then((response) => {
        if (response?.status === 200) {
            toast.success("Ontwerp opgeslagen");
            dispatch(updateDesign({ ...response.data, phase: phaseID, loop: loopID }));
            if (closeDialog !== undefined) {
                closeDialog();
            }
        }
    });
};

export const deleteDesign = (id, phaseID, loopID, dispatch) => {
    deleter(`/designs/${id}/`).then((response) => {
        if (response.status === 204) {
            if (!phaseID && !loopID) {
                dispatch(setReferenceDesign(null));
            }
            dispatch(removeDesign({ designID: id, phase: phaseID, loop: loopID }));
        }
    });
};

export const updateDesignScores = (scores, phaseID, loopID, projectThemeID, dispatch) => {
    for (const [id, data] of Object.entries(scores)) {
        patcher(`designscores/${id}/`, data).then((response) => {
            if (response?.status === 200) {
                const projectSubThemeID = data["projectSubthemeID"];
                dispatch(updateScores({ ...response.data, phase: phaseID, loop: loopID, themeID: projectThemeID, subthemeID: projectSubThemeID }));
            } else {
                toast.error(`Score met ID ${id} is niet opgeslagen: ${response?.status}`);
            }
        });
    }
    toast.success(`Scores zijn opgeslagen`);
};

export const downloadDesignExport = (design) => {
    toast.success("Export wordt gedownload. Dit kan even duren.", { autoClose: 1000 });
    fetcher(`/designs/${design.id}/export_to_pdf/`, "blob").then((response) => {
        if (response.status === 200) {
            toast.success("Export klaar!", { autoClose: 1000 });
            const blob = new Blob([response.data], { type: response.headers["content-type"], name: design.name + ".pdf" });
            const blobURL = URL.createObjectURL(blob);
            OpenFileInTab(blobURL);
        } else {
            toast.error("Er is iets misgegaan bij het downloaden van de export.");
        }
    });
};
