import { toast } from "react-toastify";
import { poster, fetcher, deleter, patcher } from "../../utils/axios.js";
import {
    addUser,
    setUsers,
    removeUser,
    updateUser,
    toggleAdminStatus,
    setEnrollments,
    removeEnrollment,
} from "../../store/slices/usersSlice.js";
import { setAccount, setWelcomeDialog } from "../../store/slices/authSlice.js";
import { updateOrganisation } from "../../store/slices/organisationsSlice.js";
import React from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { global } from "../../context/global.js";

/**
 * Registers a new user and dispatches relevant actions.
 *
 * @param {Object} userData - User data to register
 * @param {function} dispatch - Redux dispatch function
 * @param {function} closeDialog - Callback to close the dialog
 */
export const registerNewUser = (userData, dispatch, closeDialog) => {
    poster("/auth/register/", userData).then((response) => {
        toast.success("Account voor " + userData.email + " successvol aangemaakt.");
        dispatch(addUser(response.data));
        closeDialog();
    });
};

/**
 * Adds a user to an organization and dispatches relevant actions. If the user already exists, the existing user is
 * added to the organization instead.
 *
 * @param {Object} userData - User data to add to the organization
 * @param {number} orgID - ID of the organization to add the user to
 * @param {boolean} org_admin - Indicates if the user is an organization admin
 * @param {function} dispatch - Redux dispatch function
 * @param {function} closeDialog - Callback to close the dialog
 */
export const addUserToOrg = (userData, orgID, org_admin, dispatch, closeDialog) => {
    const data = {
        user: userData,
        is_org_admin: org_admin,
    };
    poster(`/organisations/${orgID}/add_user/`, data).then((response) => {
        if (response.status === 201) {
            toast.success(
                `Account voor ${
                    response.data.user.name
                } successvol aangemaakt en toegevoegd aan ${global.organisation} ${
                    response.data.organisation.name
                } als ${org_admin ? "beheerder" : "lid"}.`
            );
            dispatch(addUser(response.data.user));
            dispatch(updateOrganisation(response.data.organisation));
        } else if (response.status === 200) {
            toast.success(
                `Bestaande account voor ${response.data.user.name} toegevoegd aan ${global.organisation} ${
                    response.data.organisation.name
                } als ${org_admin ? "beheerder" : "lid"}.`
            );
            dispatch(updateUser(response.data.user));
            dispatch(updateOrganisation(response.data.organisation));
        }
        closeDialog();
    });
};

/**
 * Updates the organization admin status of a user and dispatches relevant actions.
 *
 * @param {number} userID - ID of the user to update
 * @param {number} orgID - ID of the organization
 * @param {boolean} org_admin - Indicates if the user is an organization admin
 * @param {function} dispatch - Redux dispatch function
 */
export const updateOrgAdminStatus = (userID, orgID, org_admin, dispatch) => {
    const data = {
        user: userID,
        is_org_admin: org_admin,
    };
    poster(`/organisations/${orgID}/update_admin/`, data).then((response) => {
        if (response.status === 200) {
            toast.success(
                "Beheerrechten voor " + response.data.user.name + " successvol aangepast."
            );
            dispatch(updateUser(response.data.user));
            dispatch(updateOrganisation(response.data.organisation));
        }
    });
};

/**
 * Removes a user from an organization and dispatches relevant actions.
 *
 * @param {number} userID - ID of the user to remove
 * @param {number} orgID - ID of the organization
 * @param {function} dispatch - Redux dispatch function
 */
export const removeUserFromOrg = (userID, orgID, dispatch) => {
    const data = {
        user: userID,
    };
    poster(`/organisations/${orgID}/remove_user/`, data).then((response) => {
        if (response.status === 200) {
            toast.success("Gebruiker successvol verwijderd.");
            dispatch(updateUser(response.data.user));
            dispatch(updateOrganisation(response.data.organisation));
        }
    });
};

/**
 * Fetches users and dispatches actions to update the Redux store.
 *
 * @param {function} dispatch - Redux dispatch function
 */
export const fetchUsers = (dispatch) => {
    fetcher("/users/").then((response) => {
        dispatch(setUsers(response.data));
    });
};

/**
 * Deletes a user and dispatches relevant actions.
 *
 * @param {number} id - ID of the user to delete
 * @param {function} dispatch - Redux dispatch function
 */
export const deleteUser = (id, dispatch) => {
    deleter(`/users/${id}/`).then((response) => {
        if (response.status === 204) {
            dispatch(removeUser(id));
        }
    });
};

/**
 * Patches (updates) user data and dispatches relevant actions.
 *
 * @param {Object} data - User data to update
 * @param {function} dispatch - Redux dispatch function
 */
export const patchUser = (data, dispatch) => {
    patcher(`/users/${data.id}/`, data).then((response) => {
        if (response.status === 200) {
            dispatch(updateUser(response.data));
            toast.success("Wijzigingen opgeslagen");
        }
    });
};

/**
 * Patches (updates) current user data and dispatches relevant actions.
 *
 * @param {Object} data - User data to update
 * @param {function} dispatch - Redux dispatch function
 */
export const patchCurrentUser = (data, dispatch) => {
    patcher(`/users/${data.id}/`, data).then((response) => {
        if (response.status === 200) {
            dispatch(updateUser(response.data));
            dispatch(setAccount(response.data));
            toast.success("Wijzigingen opgeslagen");
        }
    });
};

/**
 * Changes the password of a user and handles success and error cases.
 *
 * @param {number} userID - ID of the user to update the password for
 * @param {Object} data - Password change data
 * @param {function} setSubmitting - Callback to set form submitting state
 * @param {function} closeForm - Callback to close the form
 */
export const changePassword = (userID, data, setSubmitting, closeForm) => {
    poster(`/users/${userID}/change_password/`, data)
        .then((response) => {
            if (response.status === 204) {
                toast.success("Wachtwoord gewijzigd");
                setSubmitting(false);
                closeForm();
            }
        })
        .catch((err) => {
            console.log(err.response);
            setSubmitting(false);
        });
};

/**
 * Toggles the admin status of a user. If the user is an admin, they will be demoted to a regular user. If the user is
 * not an admin, they will be promoted to an admin.
 *
 * @param {number} userID - The ID of the user to toggle admin status for.
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @returns {void}
 */
export const patchAdminStatus = (userID, dispatch) => {
    fetcher(`/users/${userID}/toggle_admin_status/`).then((response) => {
        if (response.status === 200) {
            dispatch(toggleAdminStatus(userID));
            toast.success("Admin status gewijzigd");
        }
    });
};

export const setWelcomeState = (open, dispatch) => {
    dispatch(setWelcomeDialog(open));
};

export const createEnrollment = (data, dispatch) => {
    poster("/enrollments/", data).then((response) => {
        if (response.status === 201) {
            toast.success("Aanvraag voor inschrijving successvol ingediend.");
        } else {
            toast.error("Er is iets fout gegaan.");
        }
    });
};

const NavBut = ({ navigate }) => {
    return (
        <div>
            Er zijn nieuwe inschrijvingen.
            {navigate && <Button onClick={() => navigate("/admin/enrollments")}>Bekijk</Button>}
        </div>
    );
};
export const fetchEnrollments = (dispatch, navigate) => {
    fetcher("/enrollments/").then((response) => {
        dispatch(setEnrollments(response.data));
        if (response.data.length > 0) {
            toast.info(<NavBut navigate={navigate} />);
        }
    });
};

export const confirmEnrollment = (enrollmentID, orgID, dispatch) => {
    poster(`/enrollments/${enrollmentID}/confirm/`, { organisation: orgID }).then((response) => {
        if (response?.status === 200) {
            const { user, organisation } = response.data;
            toast.success(
                `Gebruiker ${user.name} successvol ingeschreven bij ${organisation.name}.`
            );
            dispatch(removeEnrollment(enrollmentID));
            dispatch(addUser(user));
            dispatch(updateOrganisation(organisation));
        } else {
            toast.error("Er is iets fout gegaan.");
        }
    });
};

export const deleteEnrollment = (enrollmentID, dispatch) => {
    deleter(`/enrollments/${enrollmentID}/`).then((response) => {
        if (response.status === 204) {
            dispatch(removeEnrollment(enrollmentID));
        } else {
            toast.error("Er is iets fout gegaan.");
        }
    });
};
