import React from "react";
import { ButtonBase, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function StyledLinkText({ text, onClick, disabled }) {
    const theme = useTheme();
    const style = {
        textDecoration: disabled ? "none" : "underline",
        color: disabled ? theme.palette.text.default : theme.palette.primary.dark,
        textWrap: text.length > 30 ? "wrap" : "nowrap",
    };
    return (
        <ButtonBase onClick={onClick} disabled={disabled}>
            <Typography sx={style}>{text}</Typography>
        </ButtonBase>
    );
}
