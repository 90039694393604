import React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
    CardHeader,
    IconButton,
    Box,
    Stack,
    ButtonGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Capitalize, DateFormat } from "../../../functions/Formatters";
import { downloadProjectExport, selectProject } from "../ProjectActions";
import { global, projectStatus } from "../../../context/global";

import { useNavigate } from "react-router-dom";
import { Download, Restore } from "@mui/icons-material";

export default function ProjectCard({ project, handleDeleteProject, handleRestoreProject }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const multipleOrgs = useSelector((state) => state.auth.account.organisations.length > 1);
    const handleSelectProject = (projectID) => {
        selectProject(projectID, dispatch);
        navigate("/project");
    };

    const projectOwnerString = project.owners
        .map((owner) => owner.name)
        .join(", ")
        .trim();

    const myRoleString = project.user_is_owner ? "Eigenaar" : project.user_is_reader ? "Lezer" : "Admin";

    const phaseString = (phases) => {
        if (phases.length === 0) {
            return `Opstartfase (${projectStatus[project.status].label})`;
        } else if (phases?.slice(-1)[0].phase_name != undefined) {
            return `${phases.slice(-1)[0].phase_name}${
                projectStatus[project.status] >= 4 ? ` (${projectStatus[project.status].label})` : ""
            }`;
        } else {
            return "Onbekend";
        }
    };

    return (
        <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Card
                raised
                sx={{
                    minWidth: 200,
                    height: "100%",
                    p: 0,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <CardHeader
                    title={project.name}
                    sx={{ pb: 0, cursor: "pointer" }}
                    onClick={() => handleSelectProject(project.id)}
                />
                <CardContent sx={{ pb: 0, cursor: "pointer" }} onClick={() => handleSelectProject(project.id)}>
                    <Typography>
                        <b>Mijn rol: </b>
                        {myRoleString}
                    </Typography>
                    {multipleOrgs && (
                        <Typography>
                            <b>{Capitalize(global.organisation)}: </b>
                            {project.organisation.name}
                        </Typography>
                    )}
                    <Typography>
                        <b>Eigenaars: </b>
                        {projectOwnerString}
                    </Typography>
                    <Typography>
                        <b>Startdatum: </b>
                        {DateFormat(project.start_date) || "Onbekend"}
                    </Typography>
                    <Typography>
                        <b>Fase: </b>
                        {phaseString(project.phases)}
                    </Typography>
                </CardContent>
                <CardActions sx={{ mt: "auto" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
                        <ButtonGroup variant="text" size="small">
                            <Button onClick={() => handleSelectProject(project.id)}>Bekijken</Button>
                            {project.user_is_orgadmin && (
                                <>
                                    {project.deleted && (
                                        <Button startIcon={<Restore />} onClick={() => handleRestoreProject(project)}>
                                            Terugzetten
                                        </Button>
                                    )}
                                    <Button onClick={() => handleDeleteProject(project)}>Verwijderen</Button>
                                </>
                            )}
                        </ButtonGroup>
                        <IconButton onClick={() => downloadProjectExport(project)}>
                            <Download />
                        </IconButton>
                    </Stack>
                </CardActions>
            </Card>
        </Grid>
    );
}
