import React from "react";
import { Box, Button, Tab, Tabs, Typography, Stack, List, ListItem } from "@mui/material";
import { Add, Download } from "@mui/icons-material";
import { useState } from "react";
import { createDesign, downloadDesignExport, patchDesign } from "../DesignActions";
import { useDispatch } from "react-redux";
import DesignScoreComponent from "./DesignScoreComponent";
import { CustomTabPanel, a11yProps } from "../../../CustomComponents/CustomTabPanel";
import StepperButtons from "../../StepperButtons";
import DesignDetails from "./DesignDetails";
import ToggleableInputField from "../../../CustomComponents/InputFields/ToggleableInputField";
import InformationDialogue from "../../../CustomComponents/InformationDialogue";
import { score } from "../../../../context/designPhase";

export default function DesignScoreTabs({ payload }) {
    const loop = payload.loop;
    const phaseID = payload.phase.id;
    const designs = loop.designs;
    const dispatch = useDispatch();
    let next_design_number = designs.length + 1;

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCreateDesign = () => {
        const data = {
            description: "",
            is_preferred: false,
            loop: loop.id,
            name: `Ontwerp ${next_design_number++}`,
            project: "",
            scores: [],
            cost: 0,
        };
        createDesign(data, phaseID, loop?.id, dispatch);
    };

    if (payload.project.status > 3) {
        return (
            <>
                {designs.length > 0 ? (
                    <>
                        <InformationDialogue text={score} maxLength={200} />
                        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="theme tabs">
                                {designs.map((design, index) => (
                                    <Tab key={`tab_design_${index}`} label={`${index + 1}. ${design.name}`} {...a11yProps(index)} />
                                ))}
                            </Tabs>
                            <Button
                                disabled={!payload.editable}
                                key={`add_design`}
                                startIcon={<Add />}
                                onClick={() => {
                                    handleCreateDesign();
                                }}
                            >
                                Toevoegen
                            </Button>
                        </Box>
                        {designs.map((design, index) => {
                            return (
                                <CustomTabPanel key={index} value={tabValue} index={index}>
                                    <Stack>
                                        <Stack direction={"row"} spacing={"auto"}>
                                            <ToggleableInputField
                                                label="Naam"
                                                field="name"
                                                value={design.name}
                                                header={true}
                                                onSave={(data) => patchDesign({ ...data, id: design.id }, phaseID, loop.id, dispatch)}
                                                allowEdit={payload.editable}
                                            />
                                            <Button onClick={()=>downloadDesignExport(design)}>
                                                <Download />
                                            </Button>
                                        </Stack>
                                        <DesignDetails
                                            design={design}
                                            payload={payload}
                                            loop={loop}
                                            phaseID={phaseID}
                                            setTabMethod={() => {
                                                setTabValue(Math.min(Math.max(tabValue - 1, 0), designs.length));
                                            }}
                                        />
                                        <Typography variant="h5">Scores</Typography>
                                        <DesignScoreComponent
                                            key={index}
                                            design={design}
                                            project={payload.project}
                                            designScores={design.scores}
                                            phaseID={phaseID}
                                            loopID={loop.id}
                                            editable={
                                                payload.editable && design.loop === payload.lastLoopID
                                            }
                                        />
                                    </Stack>
                                </CustomTabPanel>
                            );
                        })}
                    </>
                ) : (
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => {
                            handleCreateDesign();
                        }}
                    >
                        Voeg ontwerp toe
                    </Button>
                )}
                <StepperButtons payload={payload} updateStatus={false} />
            </>
        );
    } else {
        return (
            <Typography>
                Er kan nog geen ontwerp worden gemaakt. De volgende stappen zijn nog niet doorlopen:
                <List>
                    {payload.project.status > 1 ? "" : <ListItem>Onderdelen zijn niet vast gezet.</ListItem>}
                    {payload.project.status > 2 ? "" : <ListItem>Thema's zijn niet vast gezet.</ListItem>}
                    {payload.project.status > 3 ? "" : <ListItem>Doelen zijn niet vast gezet.</ListItem>}
                </List>
                Ga terug naar de betreffende stap om deze af te ronden.
            </Typography>
        );
    }
}
