import React from "react";
import { useDispatch, useSelector } from "react-redux"; // Importing necessary modules
import * as Yup from "yup";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, FormControlLabel, Tooltip, Radio, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Checkbox, RadioGroup, TextField } from "formik-mui";
import { createKPI, updateKPI } from "./ThemeActions";
import { HelpOutline } from "@mui/icons-material";
import { kpiDirections, kpiTypes } from "../../context/themes";

/**
 * This component renders a dialog for creating or editing Key Performance Indicators (KPIs) for a subtheme.
 *
 * @param {Object} props - Props object.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {function} props.onClose - Function to call when the dialog is closed.
 * @param {boolean} props.edit - Indicates whether the dialog is in edit mode (editing an existing KPI).
 * @param {Object} props.subtheme - The subtheme object to which the KPIs belong.
 */
export default function KPIDialog({ open, onClose, edit = false, subtheme }) {
    const userIsAdmin = useSelector((state) => state.auth.account.is_staff); // Check if the user is an admin
    const dispatch = useDispatch();

    const defaultValues = {
        name: !edit ? "" : edit.name,
        description: !edit ? "" : edit.description,
        unit: !edit ? "" : edit.unit,
        maximize: !edit ? false : edit.maximize,
        scale_type: !edit ? 0 : edit.scale_type,
    };

    const handleSubmitKPI = (name, description, unit, maximize, scale_type) => {
        maximize = scale_type === 0 ? true : maximize; // Qualitative KPI should always be maximized
        unit = scale_type === 0 ? "" : unit; // Score KPI should not have a unit

        if (edit) {
            // If in edit mode, update the existing KPI
            updateKPI(
                {
                    id: edit.id,
                    name: name,
                    description: description,
                    unit: unit,
                    maximize: maximize,
                    scale_type: scale_type,
                },
                dispatch,
                onClose
            );
        } else {
            // If not in edit mode, create a new KPI
            createKPI(
                {
                    name: name,
                    description: description,
                    unit: unit,
                    subtheme: subtheme.id,
                    maximize: maximize,
                    scale_type: scale_type,
                },
                dispatch,
                onClose
            );
        }
    };
    const HelpIconMaximize = () => {
        const helpText = kpiDirections.maximize.description;
        return (
            <Tooltip placement="right" title={helpText}>
                <HelpOutline />
            </Tooltip>
        );
    };
    const HelpIconScale = (scale_type) => {
        const helpText = kpiTypes[scale_type.scale_type].description;
        return (
            <Tooltip placement="right" title={helpText}>
                <HelpOutline />
            </Tooltip>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={"new_kpi_dialog"} maxWidth={"sm"} fullWidth>
            <Formik
                initialValues={{
                    name: defaultValues.name,
                    description: defaultValues.description,
                    unit: defaultValues.unit,
                    maximize: defaultValues.maximize,
                    scale_type: defaultValues.scale_type,
                }}
                validationSchema={Yup.object({
                    name: Yup.string().trim().required("Naam is vereist"),
                    description: Yup.string().trim(),
                    unit: Yup.string().max(100),
                    maximize: Yup.boolean(),
                    scale_type: Yup.number().required(),
                })}
                onSubmit={(values) => {
                    handleSubmitKPI(values.name, values.description, values.unit, values.maximize, values.scale_type);
                }}
                enableReinitialize
            >
                {({ isValid, values }) => (
                    <Form>
                        <DialogTitle>{!edit ? "Nieuwe KPI" : userIsAdmin ? "KPI bewerken" : "KPI details"}</DialogTitle>
                        <DialogContent>
                            <Stack spacing={2} sx={{ pt: 2 }}>
                                <Field
                                    component={TextField}
                                    name="name"
                                    label="Naam"
                                    type="text"
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                    disabled={!userIsAdmin}
                                    autoFocus
                                />
                                <Field
                                    component={TextField}
                                    name="description"
                                    label="Beschrijving"
                                    type="text"
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                    disabled={!userIsAdmin}
                                />
                                <Stack direction="row" spacing={2}>
                                    <Tooltip title={edit && userIsAdmin ? "Dit kan niet worden aangepast bij bestaande KPI's" : ""}>
                                        <Field component={RadioGroup} name="scale_type" disabled={!userIsAdmin || edit}>
                                            <Grid container spacing={2}>
                                                {Object.entries(kpiTypes).map(([key, value]) => (
                                                    <>
                                                        <Grid item xs={8} key={key}>
                                                            <FormControlLabel
                                                                key={key}
                                                                value={key}
                                                                control={
                                                                    <Field
                                                                        component={Radio}
                                                                        name="scale_type"
                                                                        type="radio"
                                                                        disabled={!userIsAdmin || edit}
                                                                    />
                                                                }
                                                                label={value.label}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HelpIconScale scale_type={key} />
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                        </Field>
                                    </Tooltip>
                                </Stack>
                                {values.scale_type > 0 && (
                                    <>
                                        <Field
                                            component={TextField}
                                            name="unit"
                                            label="Eenheid"
                                            type="text"
                                            inputProps={{
                                                autoComplete: "new-password",
                                            }}
                                            disabled={!userIsAdmin}
                                        />
                                        <Stack direction="row" spacing={2}>
                                            <Tooltip title={edit && userIsAdmin ? "Dit kan niet worden aangepast bij bestaande KPI's" : ""}>
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            component={Checkbox}
                                                            name="maximize"
                                                            label="Maximaliseren"
                                                            type="checkbox"
                                                            disabled={!userIsAdmin || edit}
                                                        />
                                                    }
                                                    label="Maximaliseren"
                                                />
                                            </Tooltip>
                                            <HelpIconMaximize />
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            {userIsAdmin && (
                                <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                                    KPI opslaan
                                </Button>
                            )}
                            <Button onClick={() => onClose()} color="primary">
                                {userIsAdmin ? "Annuleren" : "Sluiten"}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
