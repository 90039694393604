import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { addFeedback } from "./FeedbackActions";
import { Alert, Button, MenuItem, Stack, Typography } from "@mui/material";
import { Select, TextField } from "formik-mui";
import { text, types } from "./context";
import { Restore } from "@mui/icons-material";

export default function FeedbackForm() {
    const [response, setResponse] = useState({ status: "", detail: "" });
    const [helperText, setHelperText] = useState(types[0].description);

    const handleAddionalFeedback = (resetForm, setSubmitting) => {
        setResponse({ status: "", detail: "" });
        resetForm();
        setSubmitting(false);
    };
    return (
        <Formik
            initialValues={{
                summary: "",
                description: "",
                feedback_type: 0,
            }}
            validationSchema={Yup.object({
                summary: Yup.string()
                    .min(6, "Korte beschrijving moet minimaal 10 tekens zijn")
                    .max(255, "Korte beschrijving mag niet meer dan 255 tekens zijn")
                    .trim()
                    .required("Korte beschrijving is vereist"),
                description: Yup.string().trim(),
                feedback_type: Yup.number().required("Feedback type is vereist").integer().min(0).max(3),
            })}
            onSubmit={(values, actions) => {
                setResponse("");
                values = { ...values, location: window.location.href };
                addFeedback(values).then((resp) => {
                    if (resp.status === "error") {
                        actions.setSubmitting(false);
                    }
                    setResponse(resp);
                });
            }}
            enableReinitialize={true}
        >
            {({ isValid, isSubmitting, resetForm, setSubmitting }) => (
                <Form>
                    <Stack spacing={2}>
                        <Typography>{text}</Typography>
                        <Field
                            component={TextField}
                            name="summary"
                            label="Korte beschrijving"
                            type="text"
                            placeholder="Korte beschrijving"
                            helperText="Vul hier kort in waar de feedback over gaat"
                        />
                        <Field
                            component={Select}
                            size="small"
                            name="feedback_type"
                            label="Soort feedback"
                            placeholder="Soort feedback"
                            disabled={isSubmitting}
                            onChange={(e) => {
                                const helpText = types.find((type) => type.value === e.target.value)?.description;
                                setHelperText(helpText);
                            }}
                        >
                            {types.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Field>
                        <Field
                            component={TextField}
                            name="description"
                            label="Beschrijving"
                            type="text"
                            multiline
                            minRows={4}
                            maxRows={8}
                            placeholder="Beschrijving"
                            inputProps={{
                                autoComplete: "new-password",
                            }}
                            helperText={helperText}
                        />
                        {response.detail && <Alert severity={response.status}>{response.detail}</Alert>}
                        {response.status === "success" && (
                            <Button
                                sx={{ textAlign: "right" }}
                                color={response.status}
                                startIcon={<Restore />}
                                onClick={() => handleAddionalFeedback(resetForm, setSubmitting)}
                            >
                                Nieuwe feedback
                            </Button>
                        )}
                        {response.status !== "success" && (
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!isValid || isSubmitting}
                            >
                                Feedback opsturen
                            </Button>
                        )}
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}
