import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { createNewProject } from "./ProjectActions";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, MenuItem, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Select, TextField } from "formik-mui";
import { global } from "../../context/global";
import { Capitalize } from "../../functions/Formatters";

export default function NewProjectDialog({ open, onClose }) {
    const dispatch = useDispatch();
    const organisations = useSelector((state) => state.organisations.organisations).filter((org) => org.user_is_admin);

    const singleOrg = organisations.length === 1;
    const defaultOrg = singleOrg ? organisations[0].id : "";

    const createProjectAndCloseDialog = (data) => {
        createNewProject(data, dispatch, onClose);
    };

    const userOptions = (orgId) => {
        let org = organisations.find((org) => org.id == orgId);
        let members = org?.members || [];
        members = [...members].sort((a, b) => a.name.localeCompare(b.name));
        return members;
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={"new_project_dialog"}>
            <DialogTitle id={"new_project_dialog"}>Nieuw project</DialogTitle>
            <Formik
                initialValues={{
                    name: "",
                    description: "",
                    organisation: defaultOrg,
                    owners: [],
                    collaborators: [],
                    readers: [],
                }}
                validationSchema={Yup.object({
                    name: Yup.string().max(255, "Naam mag niet meer dan 255 tekens zijn").trim().required("Naam is vereist"),
                    description: Yup.string().trim(),
                    organisation: Yup.number().required(`${global.organisation} is vereist`),
                    owners: Yup.array().required().min(1, "Minimaal 1 eigenaar"),
                    collaborators: Yup.array(),
                    readers: Yup.array(),
                })}
                onSubmit={(values) => {
                    createProjectAndCloseDialog(values);
                }}
            >
                {({ isValid, values }) => (
                    <Form>
                        <DialogContent>
                            <Stack spacing={2}>
                                <Typography>
                                    Vul de gegevens van het nieuwe project in. De eigenaars van het project kunnen aanpassingen maken aan het project
                                    en de projectleden. Medewerkers kunnen alleen informatie invullen in het project, maar De lezers kunnen alleen het project bekijken.
                                </Typography>
                                <Field component={TextField} name="name" label="Naam" type="text" placeholder="Naam" />
                                <Field
                                    component={TextField}
                                    name="description"
                                    label="Beschrijving"
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                />
                                {organisations.length > 1 && (
                                    <Field
                                        component={Select}
                                        name="organisation"
                                        type="select"
                                        disabled={organisations.length === 1}
                                        label={Capitalize(global.organisation)}
                                        hidden={singleOrg}
                                    >
                                        {organisations.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                )}
                                <Field
                                    component={Select}
                                    name="owners"
                                    type="select"
                                    multiple
                                    disabled={values.organisation === ""}
                                    label="Eigenaren"
                                    onClose={() => {}}
                                    autoWidth={true}
                                >
                                    {userOptions(values.organisation).map(
                                        (option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                                <Field
                                    component={Select}
                                    name="collaborators"
                                    type="select"
                                    multiple
                                    disabled={values.organisation === ""}
                                    label="Medewerkers"
                                    onClose={() => {}}
                                    autoWidth={true}
                                >
                                    {userOptions(values.organisation).map(
                                        (option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                                <Field
                                    component={Select}
                                    name="readers"
                                    type="select"
                                    multiple
                                    placeholder="Lezers"
                                    disabled={values.organisation === ""}
                                    label="Lezers"
                                    autoWidth={true}
                                    onClose={() => {}}
                                >
                                    {userOptions(values.organisation).map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                                Project aanmaken
                            </Button>
                            <Button onClick={() => onClose()} color="primary">
                                Annuleren
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
