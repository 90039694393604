import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { CustomTabPanel, a11yProps } from "../../../CustomComponents/CustomTabPanel";
import ScoreGraph from "../../../Charts/ScoreGraph";
import QuantThemeTab from "./QuantThemeTabContent";
import { useTheme } from "@emotion/react";

export default function SuperThemeTab(props) {
    const supertheme = props.supertheme;
    const designs = props.designs;
    const projectLayers = props.projectLayers; // Later: get from redux
    const projectSegments = props.projectSegments; // Later: get from redux (?)

    const styling = useTheme();

    const projectThemes = useSelector((state) => state.projects.selectedProject.themes);
    const flatThemes = useSelector((state) => state.themes.flatThemes);
    const flatKpis = useSelector((state) => state.themes.flatKpis);
    const applicationThemes = useSelector((state) => state.themes.themes);

    const relatedThemeIDs = applicationThemes.filter((theme) => theme.supertheme === supertheme.id).map((theme) => theme.id);
    const relatedProjectThemes = projectThemes
        .filter((projectTheme) => relatedThemeIDs.includes(projectTheme.theme))
        .filter((projectTheme) => projectTheme.subthemes.some((projectSubtheme) => projectSubtheme.goals.length > 0));
    const qualitativeProjectThemes = relatedProjectThemes.filter((projectTheme) =>
        projectTheme.subthemes.some((projectSubtheme) => projectSubtheme.goals.some((goal) => flatKpis[goal.kpi].scale_type === 0))
    );
    const quantativeProjectThemes = relatedProjectThemes.filter((projectTheme) =>
        projectTheme.subthemes.some((projectSubtheme) => projectSubtheme.goals.some((goal) => flatKpis[goal.kpi].scale_type > 0))
    );

    const [themeTabValue, setThemeTabValue] = useState(
        qualitativeProjectThemes.length > 0 ? "scores" : quantativeProjectThemes.length > 0 ? quantativeProjectThemes[0].theme : null
    );
    const handleChangeThemeTab = (event, newValue) => {
        setThemeTabValue(newValue);
    };

    const qualitativeDataConstructor = (designs) => {
        let ix = -1;
        return qualitativeProjectThemes.reduce((acc, projectTheme) => {
            projectTheme.subthemes.reduce((acc, projectSubtheme) => {
                projectSubtheme.goals
                    .filter((goal) => flatKpis[goal.kpi].scale_type === 0)
                    .reduce((acc, goal) => {
                        let name = flatKpis[goal.kpi].name;
                        let value = goal.value;
                        if (name.toUpperCase() === "SCORE") {
                            name = `${flatThemes[projectTheme.theme].name}`;
                        }

                        ix += 1;
                        acc.push({
                            label: name,
                            data: [value, ...designs.map(
                                (design) =>
                                    design.scores_by_themes[projectTheme.id.toString()][projectSubtheme.id.toString()].find(
                                        (scoreObj) => scoreObj.kpi === goal.kpi
                                    ).value
                            )],
                            backgroundColor: Object.values(styling.palette.graphics)[ix],
                        });
                        return acc;
                    }, acc);
                return acc;
            }, acc);
            return acc;
        }, []);
    };

    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 0 }}>
            <Tabs value={themeTabValue} onChange={handleChangeThemeTab} aria-label="theme_tabs_container" scrollButtons variant="scrollable">
                {qualitativeProjectThemes.length > 0 && (
                    <Tab sx={{ maxWidth: 250 }} key={`scores_tab`} label={"Scores"} {...a11yProps("scores")} wrapped={true} value={"scores"} />
                )}

                {quantativeProjectThemes.map((projectTheme) => {
                    const theme = flatThemes[projectTheme.theme];
                    return (
                        <Tab
                            key={`theme_tab_${theme.id}`}
                            sx={{ maxWidth: 250 }}
                            {...a11yProps(theme.id)}
                            wrapped={true}
                            value={theme.id}
                            label={
                                <Tooltip title={theme.description} enterDelay={1000}>
                                    <Typography variant="small">{theme.name}</Typography>
                                </Tooltip>
                            }
                        />
                    );
                })}
            </Tabs>
            {qualitativeProjectThemes.length > 0 && (
                <CustomTabPanel key={`tab_scores`} value={themeTabValue} index={"scores"}>
                    <Grid container>
                        <Grid item xs={12} sx={{ maxHeight: 350, minHeight: 250 }}>
                            <ScoreGraph initData={qualitativeDataConstructor(designs)} variants={designs.map((design) => design.name)} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
            )}

            {quantativeProjectThemes.map((projectTheme) => {
                return (
                    <CustomTabPanel key={projectTheme.id} value={themeTabValue} index={`${projectTheme.theme}`}>
                        <QuantThemeTab
                            projectTheme={projectTheme}
                            designs={designs}
                            projectLayers={projectLayers}
                            projectSegments={projectSegments}
                        />
                    </CustomTabPanel>
                );
            })}
        </Box>
    );
}
