import { createSlice } from "@reduxjs/toolkit";

const initialState = { projects: [], selectedProject: null };

const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setProjects(state, action) {
            /**Sets the full array of projects */
            state.projects = action.payload;
        },
        addProject(state, action) {
            state.projects = [...state.projects, action.payload];
        },
        removeProject(state, action) {
            state.projects = state.projects.flatMap((project) => {
                if (project.id === action.payload) {
                    if (project.deleted === false) {
                        return [{ ...project, deleted: true }];
                    } else {
                        return []
                    }
                }
                return [project];
            }
            );
            if (state.selectedProject?.id === action.payload) {
                state.selectedProject = null;
            }
        },
        restoreProject(state, action) {
            state.projects = state.projects.map((project) => {
                if (project.id === action.payload) {
                    return { ...project, deleted: false };
                }
                return project;
            }
            );
            if (state.selectedProject?.id === action.payload) {
                state.selectedProject = { ...state.selectedProject, deleted: false };
            }
        },
        clearProjects(state) {
            state.projects = [];
        },
        updateProject(state, action) {
            const nextState = state.projects.map((project) => {
                if (project.id === action.payload.id) {
                    return action.payload;
                }
                return project;
            });
            state.projects = nextState;
            state.selectedProject = action.payload;
        },
        removeProjectSubtheme(state, action) {
            state.selectedProject = {
                ...state.selectedProject,
                themes: state.selectedProject.themes.map((theme) => {
                    if (theme.id === action.payload.themeID) {
                        return {
                            ...theme,
                            subthemes: theme.subthemes.filter((subtheme) => subtheme.id !== action.payload.subthemeID),
                        };
                    }
                    return theme;
                }),
            };
        },
        setSelectedProject(state, action) {
            state.selectedProject = action.payload;
        },
        setReferenceDesign(state, action) {
            state.selectedProject = { ...state.selectedProject, reference_design: action.payload };
        },
        updateStatus(state, action) {
            state.selectedProject = { ...state.selectedProject, status: action.payload.status };
        },
        updateDesign(state, action) {
            // require phase ID and loop ID in payload
            const updatedDesign = action.payload;
            if (!updatedDesign.loop) {
                state.selectedProject = {
                    ...state.selectedProject,
                    reference_design: updatedDesign,
                };
            } else {
                const updatedProject = {
                    ...state.selectedProject,
                    phases: state.selectedProject.phases.map((phase) => {
                        if (phase.id === action.payload.phase) {
                            return {
                                ...phase,
                                loops: phase.loops.map((loop) => {
                                    if (loop.id === action.payload.loop) {
                                        return {
                                            ...loop,
                                            designs: loop.designs.map((design) => {
                                                if (design.id === action.payload.id) {
                                                    return action.payload;
                                                }
                                                return design;
                                            }),
                                        };
                                    } else {
                                        return loop;
                                    }
                                }),
                            };
                        } else {
                            return phase;
                        }
                    }),
                };
                state.selectedProject = updatedProject;
            }
        },
        addDesign(state, action) {
            // require phase ID and loop ID in payload
            const newDesign = action.payload;
            if (!newDesign.loop) {
                state.selectedProject = {
                    ...state.selectedProject,
                    reference_design: newDesign,
                };
            } else {
                const updatedProject = {
                    ...state.selectedProject,
                    phases: state.selectedProject.phases.map((phase) => {
                        if (phase.id === action.payload.phase) {
                            return {
                                ...phase,
                                loops: phase.loops.map((loop) => {
                                    if (loop.id === newDesign.loop) {
                                        return {
                                            ...loop,
                                            designs: [...loop.designs, newDesign],
                                        };
                                    } else {
                                        return loop;
                                    }
                                }),
                            };
                        } else {
                            return phase;
                        }
                    }),
                };
                state.selectedProject = updatedProject;
            }
        },
        removeDesign(state, action) {
            // require phase ID and loop ID in payload
            if (!action.payload.loop) {
                state.selectedProject = {
                    ...state.selectedProject,
                    reference_design: null,
                };
            } else {
                const updatedProject = {
                    ...state.selectedProject,
                    phases: state.selectedProject.phases.map((phase) => {
                        if (phase.id === action.payload.phase) {
                            return {
                                ...phase,
                                loops: phase.loops.map((loop) => {
                                    if (loop.id === action.payload.loop) {
                                        return {
                                            ...loop,
                                            designs: loop.designs.filter((design) => design.id !== action.payload.designID),
                                        };
                                    } else {
                                        return loop;
                                    }
                                }),
                            };
                        } else {
                            return phase;
                        }
                    }),
                };
                state.selectedProject = updatedProject;
            }
        },
        updateScores(state, action) {
            // require phase ID, loop ID, projectThemeID, projectSubthemeID
            if (!action.payload.loop) {
                state.selectedProject = {
                    ...state.selectedProject,
                    reference_design: {
                        ...state.selectedProject.reference_design,
                        scores: state.selectedProject.reference_design.scores.map((score) =>
                            score.id === action.payload.id ? action.payload : score
                        ),
                        scores_by_themes: {
                            ...state.selectedProject.reference_design.scores_by_themes,
                            [action.payload.themeID]: {
                                ...state.selectedProject.reference_design.scores_by_themes[action.payload.themeID],
                                [action.payload.subthemeID]: [
                                    ...state.selectedProject.reference_design.scores_by_themes[action.payload.themeID][action.payload.subthemeID].map(
                                        (score) => (score.id === action.payload.id ? action.payload : score)
                                    ),
                                ],
                            },
                        },
                    },
                };
            } else {
                const updatedProject = {
                    ...state.selectedProject,
                    phases: state.selectedProject.phases.map((phase) => {
                        if (phase.id === action.payload.phase) {
                            return {
                                ...phase,
                                loops: phase.loops.map((loop) => {
                                    if (loop.id === action.payload.loop) {
                                        return {
                                            ...loop,
                                            designs: loop.designs.map((design) => {
                                                if (design.id === action.payload.design) {
                                                    return {
                                                        ...design,
                                                        scores: design.scores.map((score) =>
                                                            score.id === action.payload.id ? action.payload : score
                                                        ),
                                                        scores_by_themes: {
                                                            ...design.scores_by_themes,
                                                            [action.payload.themeID]: {
                                                                ...design.scores_by_themes[action.payload.themeID],
                                                                [action.payload.subthemeID]: [
                                                                    ...design.scores_by_themes[action.payload.themeID][action.payload.subthemeID].map(
                                                                        (score) => (score.id === action.payload.id ? action.payload : score)
                                                                    ),
                                                                ],
                                                            },
                                                        },
                                                    };
                                                }
                                                return design;
                                            }),
                                        };
                                    } else {
                                        return loop;
                                    }
                                }),
                            };
                        } else {
                            return phase;
                        }
                    }),
                };
                state.selectedProject = updatedProject;
            }
        },
        setPossibleLayers(state, action) {
            state.selectedProject = { ...state.selectedProject, possibleLayers: action.payload };
        },
        addProjectAttachment(state, action) {
            state.selectedProject = {
                ...state.selectedProject,
                attachments: [...state.selectedProject.attachments, action.payload],
            };
        },
        removeProjectAttachment(state, action) {
            state.selectedProject = {
                ...state.selectedProject,
                attachments: state.selectedProject.attachments.filter((attachment) => attachment.id !== action.payload),
            };
        },
        updateProjectAttachment(state, action) {
            state.selectedProject = {
                ...state.selectedProject,
                attachments: state.selectedProject.attachments.map((attachment) => {
                    if (attachment.id === action.payload.id) {
                        return action.payload;
                    }

                    return attachment;
                }),
            };
        },
        updateAdvice(state, action) {
            // require phase ID and loop ID in payload
            const updatedAdvice = action.payload;
            const updatedProject = {
                ...state.selectedProject,
                phases: state.selectedProject.phases.map((phase) => {
                    if (phase.id === updatedAdvice.phase) {
                        return {
                            ...phase,
                            loops: phase.loops.map((loop) => {
                                if (loop.id === updatedAdvice.loop) {
                                    return {
                                        ...loop,
                                        advice_by_themes: {
                                            ...loop.advice_by_themes,
                                            [updatedAdvice.themeID]: {
                                                ...loop.advice_by_themes[updatedAdvice.themeID],
                                                [updatedAdvice.subthemeID]: updatedAdvice,
                                            },
                                        },
                                    };
                                } else {
                                    return loop;
                                }
                            }),
                        };
                    } else {
                        return phase;
                    }
                }),
            };
            state.selectedProject = updatedProject;
        },
    },
});

export const {
    setProjects,
    addProject,
    removeProject,
    restoreProject,
    clearProjects,
    updateProject,
    removeProjectSubtheme,
    setSelectedProject,
    setReferenceDesign,
    updateDesign,
    addDesign,
    removeDesign,
    updateScores,
    updateStatus,
    addProjectAttachment,
    removeProjectAttachment,
    updateProjectAttachment,
    updateAdvice,
} = projectSlice.actions;
export default projectSlice.reducer;
