import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    PointElement,
    LineElement,
    LineController,
    BarController,
    Filler,
} from "chart.js";
import { Chart } from "react-chartjs-2";

export default function QuantGraphDetailed({ variants, initData, kpi, goal, showGoalLine }) {
    let filteredData = [...initData];

    if (showGoalLine) {
        filteredData = [
            {
                label: "Doel" + (kpi.maximize ? "(min)" : "(max)"),
                data: Array(variants.length).fill(goal.value),
                type: "line",
                borderWidth: 4,
                borderColor: "red",
                pointStyle: false,
                borderDash: [10, 10],
            },
            ...filteredData,
        ];
    }

    ChartJS.register(
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Legend,
        Tooltip,
        LineController,
        BarController,
        Colors,
        Title,
        Filler
    );

    const options = {
        plugins: {
            title: {
                display: true,
                text: kpi.name,
                fullSize: false,
                font: {
                    size: 14,
                },
            },
            legend: {
                display: true,
                position: initData.length > 8 ? 'right' : 'bottom',
                align: "start",
            },
            filler: {
                propagate: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: kpi.unit,
                },
            },
        },
    };

    const data = {
        labels: variants,
        datasets: filteredData,
    };
    if (filteredData === null) {
        return null;
    }

    return <Chart type="bar" options={options} data={data} redraw />;
}
