import React, { useEffect, useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import { advancePhase } from "../ProjectActions";
import { useDispatch } from "react-redux";

export default function SegmentsSelect({ project, isFirstPhase, design, open, onClose }) {
    const dispatch = useDispatch();
    const [segments, setSegments] = useState([]);
    const [copyFromPrevious, setCopyFromPrevious] = useState(!isFirstPhase);

    const [defaultName, setDefaultName] = useState("Dijkvak");
    const [phase, setPhase] = useState(0);
    const [customPhaseName, setCustomPhaseName] = useState("");
    const [useDefaultName, setUseDefaultName] = useState(true);
    const totalLength = segments.reduce((a, b) => a + Number(b.length), 0);
    const previousTotalLength = isFirstPhase ? 0 : project.phases[0].segments.reduce((a, b) => a + Number(b.length), 0);
    // To help the user, generate a string with the names chosen layers
    const layersString = project.potential_layers
        .filter((layer) => project.layers.includes(layer.id))
        .map((layer) => layer.name)
        .join(", ");

    // Wrapping what should be the default for the copyFromPrevious and segments useStates in a useEffect,
    // to force re-rendering when switching between projects
    useEffect(() => {
        if (copyFromPrevious && !isFirstPhase) {
            setSegments(project.phases[0].segments);
        }
    }, [copyFromPrevious]);
    useEffect(() => {
        setCopyFromPrevious(!isFirstPhase);
    }, [isFirstPhase]);

    const handleAddSegment = () => {
        setSegments([...segments, { name: useDefaultName ? `${defaultName} ${segments.length + 1}` : "", length: 0 }]);
    };
    const handleRemoveSegment = (index) => {
        if (index !== null) {
            setSegments(segments.filter((segment, i) => i !== index));
        } else if (segments.length > 0) {
            setSegments(segments.slice(0, -1));
        }
    };
    const handleSetSegmentNumber = (number) => {
        if (Number(number) === 0) {
            null
        } else if (number < segments.length) {
            setSegments(segments.slice(0, number));
        } else {
            setSegments([
                ...segments,
                ...Array(number - segments.length)
                    .fill({ name: "", length: 0 })
                    .map((segment, index) => ({ ...segment, name: `${defaultName} ${index + segments.length + 1}` })),
            ]);
        }
    };
    const handleChangeDefaultName = (name) => {
        setDefaultName(name);
        setSegments(segments.map((segment, index) => ({ ...segment, name: `${name} ${index + 1}` })));
    };
    const handleChangeUseDefaultName = (value) => {
        if (value) {
            setUseDefaultName(true);
            setSegments(segments.map((segment, index) => ({ ...segment, name: `${defaultName} ${index + 1}` })));
        } else {
            setUseDefaultName(false);
        }
    };
    const handleSaveAndContinue = () => {
        const phase_name = phase == 0 ? "Verkenningsfase" : phase == 1 ? "Planfase" : customPhaseName;

        advancePhase(project.id, segments, design, phase, phase_name, dispatch);
        onClose();
    };

    const determineError = () => {
        if (segments.length <= 0) {
            return (
                <Alert severity="error">
                Een fase moet minstens 1 segment hebben.
            </Alert>
            )
        }
        else if (!isFirstPhase && totalLength !== previousTotalLength) {
            return (

                <Alert severity="warning">
                    Pas op! De totale lengte van de segmenten ({totalLength} m) is niet gelijk aan de vorige fase (
                    {previousTotalLength} m). Controleer of dit klopt.
                </Alert>
            )
        }
        else{
            return
        }
    }

    return (
        <Dialog open={open} onClose={onClose} scroll="paper" sx={{ verticalAlign: "top", height: "100%" }}>
            <DialogTitle>Segmenten</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {design ? (
                        <Tooltip title={design.description}>
                            <Typography>In de volgende fase wordt het volgende ontwerp meegenomen: {design.name}</Typography>
                        </Tooltip>
                    ) : (
                        ""
                    )}
                    <br />
                    <FormControl>
                        Selecteer een naam voor de fase, of voer zelf een optie in.
                        <FormLabel id="demo-radio-buttons-group-label">Fase naam</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="0"
                            name="radio-buttons-group"
                            onChange={(event) => {
                                setPhase(event.target.value);
                            }}
                        >
                            <FormControlLabel value="0" control={<Radio />} label="Verkenningsfase" />
                            <FormControlLabel value="1" control={<Radio />} label="Planfase" />
                            <FormControlLabel value="2" control={<Radio />} label="Anders"></FormControlLabel>
                            {phase == 2 ? (
                                <TextField
                                    value={customPhaseName}
                                    onChange={(event) => {
                                        setCustomPhaseName(event.target.value);
                                    }}
                                    placeholder="Vul een ontwerp fase naam in"
                                />
                            ) : (
                                ""
                            )}
                        </RadioGroup>
                    </FormControl>
                    <Typography>
                        Kies hier de segmenten die in de volgende fase worden gebruikt. Samen met de{" "}
                        <Tooltip component="span" title={layersString}>
                            <u>gekozen onderdelen uit de Opstartfase</u>
                        </Tooltip>
                        , bepalen deze segmenten het niveau waarop bepaalde KPI's worden ingevuld.
                    </Typography>
                    {!isFirstPhase && (
                        <FormControlLabel
                            control={<Switch checked={copyFromPrevious} onChange={(e) => setCopyFromPrevious(e.target.checked)} />}
                            label={`Gebruik dezelfde segmenten als de ${project.phases.slice(-1)[0].phase_name}`}
                        />
                    )}
                    {!copyFromPrevious && (
                        <>
                            <Stack direction="row" spacing={1}>
                                <Tooltip title="Vink dit aan als u wilt dat alle segmenten dezelfde naam hebben."  placement="top-end">
                                    <Checkbox
                                        disabled={copyFromPrevious}
                                        checked={useDefaultName}
                                        onChange={() => handleChangeUseDefaultName(!useDefaultName)}
                                    />
                                </Tooltip>
                                <TextField
                                    label="Standaardnaam"
                                    value={defaultName}
                                    onChange={(e) => handleChangeDefaultName(e.target.value)}
                                    disabled={!useDefaultName || copyFromPrevious}
                                />
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <IconButton onClick={() => handleRemoveSegment(null)} disabled={copyFromPrevious}>
                                    <Remove />
                                </IconButton>
                                <TextField
                                    label="Aantal segmenten"
                                    type="number"
                                    min={1}
                                    value={segments.length}
                                    onChange={(e) => handleSetSegmentNumber(e.target.value)}
                                    disabled={copyFromPrevious}
                                />
                                <IconButton onClick={handleAddSegment} disabled={copyFromPrevious}>
                                    <Add />
                                </IconButton>
                            </Stack>
                        </>
                    )}
                    <TableContainer>
                        <Table padding="none">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">#</TableCell>
                                    <TableCell>Segment</TableCell>
                                    <TableCell>Lengte</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {segments.map((segment, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <TextField
                                                disabled={useDefaultName || copyFromPrevious}
                                                size="small"
                                                value={segment.name}
                                                onChange={(e) =>
                                                    setSegments(segments.map((s, i) => (i === index ? { ...s, name: e.target.value } : s)))
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                InputProps={{ endAdornment: "m" }}
                                                value={segment.length}
                                                type="number"
                                                min={0}
                                                onChange={(e) =>
                                                    setSegments(segments.map((s, i) => (i === index ? { ...s, length: e.target.value } : s)))
                                                }
                                                disabled={copyFromPrevious}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2}>Totaal:</TableCell>
                                    <TableCell>
                                        <Typography>{totalLength} m</Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={3}>
                                        {determineError()}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuleren</Button>
                <Button onClick={handleSaveAndContinue} disabled={segments.length === 0 || segments.some((segment) => segment.name === "")}>
                    Opslaan en volgende fase
                </Button>
            </DialogActions>
        </Dialog>
    );
}
