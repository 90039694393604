import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors, PointElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import { scoreLevels } from "../../context/global";

export default function ScoreGraph({ variants, initData }) {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors, PointElement);
    console.log(initData)

    const extraScoreLevels = [{ value: 0, label: "Geen score" }, ...scoreLevels];

    const footer = (tooltipItems) => {
        let str = "";
        tooltipItems.forEach(function (tooltipItem) {
            str += "Score: " + scoreLevels[Number(tooltipItem.parsed.y) - 1].label;
        });
        return str;
    };
    const options = {
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    footer: footer,
                },
            },
            legend: {
                position: "right",
            },
            title: {
                display: false,
                text: "Chart.js Horizontal Bar Chart",
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (val, index) {
                        return extraScoreLevels[index].label;
                    },
                    beginAtZero: false,
                    stepSize: 1,
                    max: 5,
                },
                min: 0,
                max: 5,
            },
        },
    };

    const data = {
        labels: ["Doel", ...variants],
        datasets: initData,
    };
    if (initData === null) {
        return null;
    }

    return <Bar options={options} data={data} redraw />;
}
