import React, { useMemo, useState } from "react";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { prettifyNumber, sanitizeNumber } from "../../../functions/Formatters";

export default function CustomNumberField(props) {
    const [inputValue, setInputValue] = useState(props?.field?.value ? props.field.value : props.value ? props.value : "");
    const [displayPrettyValue, setDisplayPrettyValue] = useState(true);

    const handleChange = (value) => {
        value = sanitizeNumber(value);
        if (!isNaN(value)) {
            setInputValue(value);
            if (props.onChange != null) {
                props.onChange(value);
            }
            if (props.field?.name) {
                props.form.setFieldValue(props.field.name, value);
            }
        }
    };

    const displayValue = useMemo(() => {
        return displayPrettyValue
            ? props.unit === "€"
                ? `${prettifyNumber(inputValue, props.unit)}`
                : `${prettifyNumber(inputValue, props.unit)} ${props.unit}`
            : inputValue;
    }, [inputValue, displayPrettyValue]);

    return (
        <Tooltip title={props.unit === "€" ? `${prettifyNumber(inputValue, props.unit)}` : `${prettifyNumber(inputValue, props.unit)} ${props.unit}`}>
            <TextField
                id={props.id}
                label={props.label}
                name={props.field?.name ? props.field?.name : props.name ? props.name : ""}
                placeholder={props.unit}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{props.startAdornment ? props.startAdornment : ""}</InputAdornment>,
                    endAdornment: <InputAdornment position="end">{props.endAdornment ? props.endAdornment : ""}</InputAdornment>,
                }}
                fullWidth={props.fullWidth}
                value={displayValue}
                onChange={(event) => handleChange(event.target.value)}
                onBlur={() => {
                    setDisplayPrettyValue(true);
                }}
                onFocus={() => {
                    setDisplayPrettyValue(false);
                }}
                error={props.error}
                helperText={props.helperText}
                disabled={props.disabled}
            />
        </Tooltip>
    );
}
