export const siteInfo = [
    `Deze website is gemaakt in opdracht van het Hoogwaterbeschermingsprogramma (HWBP) en is bedoeld om de duurzaamheidsambities van HWBP-projecten te ondersteunen.`,
    `De website is gebaseerd op de Duurzaamheidsroos, een instrument dat is ontwikkeld door HKV.`,
    `De website is ontwikkeld door het Smart Development team van Royal HaskoningDHV.`,
];

export const expandHeaders = {
    site: "Site informatie",
    links: "Externe links",
    files: "Downloads",
};
