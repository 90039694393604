import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { shortenString } from "../../functions/Formatters";


/**
 * Renders a short string with a tooltip if the string exceeds a certain length.
 *
 * @param {Object} props - The component props.
 * @param {string} props.string - The input string.
 * @param {number} [props.maxLength=50] - The maximum length of the string before it gets shortened.
 * @param {string} [props.ellipsis="..."] - The ellipsis to append to the shortened string.
 * @param {Object} [props.typographyProps={}] - Additional props to pass to the Typography component.
 * @returns {JSX.Element} The rendered ShortStringWithTooltip component.
 */
export default function ShortStringWithTooltip({
    string = "",
    maxLength = 50,
    ellipsis = "...",
    typographyProps = {},
}) {
    const shortString = shortenString(string, maxLength, ellipsis);
    const tooltipTitle = string.length > maxLength ? string : "";

    return (
        <Tooltip title={tooltipTitle}>
            <Typography {...typographyProps}>{shortString}</Typography>
        </Tooltip>
    );
}
