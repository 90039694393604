import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    themes: [],
    superthemes: [],
    selectedTheme: null,
    kpis: {},
    flatThemes: {},
    flatSubThemes: {},
    flatKpis: {},
    designPrinciples: [],
};

/**
 * Reduces an array of themes, which have an array of subthemes, which have an array of kpis to
 * an object with structure:
 * {
 *   themeId: {
 *     subthemeId: {
 *       kpiId: kpi
 *     }
 *   }
 * }
 *
 * @param {Array} themes - The array of themes to reduce.
 * @returns {Object} An object of kpis.
 */
function reduceKpis(themes) {
    return themes.reduce((acc, theme) => {
        const kpis = theme.subthemes.reduce((acc, subtheme) => {
            const kpis = subtheme.kpis.reduce((acc, kpi) => {
                acc[kpi.id] = kpi;
                return acc;
            }, {});
            acc[subtheme.id] = kpis;
            return acc;
        }, {});
        acc[theme.id] = kpis;
        return acc;
    }, {});
}

function flattenThemes(themes) {
    return themes.reduce((acc, theme) => {
        acc[theme.id] = theme;
        return acc;
    }, {});
}

function flattenSubThemes(themes) {
    return themes.reduce((acc, theme) => {
        theme.subthemes.reduce((acc, subtheme) => {
            acc[subtheme.id] = subtheme;
            return acc;
        }, acc);
        return acc;
    }, {});
}

function flattenKpis(themes) {
    return themes.reduce((acc, theme) => {
        theme.subthemes.reduce((acc, subtheme) => {
            subtheme.kpis.reduce((acc, kpi) => {
                acc[kpi.id] = kpi;
                return acc;
            }, acc);
            return acc;
        }, acc);
        return acc;
    }, {});
}

const themeSlice = createSlice({
    name: "themes",
    initialState,
    reducers: {
        setThemes(state, action) {
            state.themes = action.payload;
            state.kpis = reduceKpis(action.payload);
            state.flatThemes = flattenThemes(action.payload);
            state.flatSubThemes = flattenSubThemes(action.payload);
            state.flatKpis = flattenKpis(action.payload);
        },
        setSuperthemes(state, action) {
            state.superthemes = action.payload;
        },
        setDesignPrinciples(state, action) {
            state.designPrinciples = action.payload;
        },
        addTheme(state, action) {
            state.themes = [...state.themes, action.payload];
            state.kpis = reduceKpis(state.themes);
            state.flatThemes = flattenThemes(state.themes);
            state.flatSubThemes = flattenSubThemes(state.themes);
            state.flatKpis = flattenKpis(state.themes);
        },
        removeTheme(state, action) {
            state.themes = state.themes.filter((theme) => theme.id !== action.payload);
        },
        clearThemes(state) {
            state.themes = [];
            state.selectedTheme = null;
            state.superthemes = [];
            state.kpis = {};
        },
        updateTheme(state, action) {
            const nextState = state.themes.map((theme) => {
                if (theme.id === action.payload.id) {
                    return action.payload;
                }
                return theme;
            });
            state.themes = nextState;
            state.selectedTheme = action.payload;
            state.kpis = reduceKpis(nextState);
            state.flatThemes = flattenThemes(nextState);
            state.flatSubThemes = flattenSubThemes(nextState);
            state.flatKpis = flattenKpis(nextState);
        },
        selectTheme(state, action) {
            state.selectedTheme = state.themes.find((theme) => theme.id == action.payload);
        },
    },
});

export const { setThemes, setSuperthemes, addTheme, removeTheme, clearThemes, updateTheme, selectTheme, setDesignPrinciples } = themeSlice.actions;

export default themeSlice.reducer;
