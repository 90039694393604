import { InputBase, styled } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        fontSize: theme.typography.h4.fontSize,
        color: theme.typography.h4.color,
        margin: "none",
        padding: "10px 0px 0px 0px",
    },
}));
