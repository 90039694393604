import { appName } from "./global";

export const titleBar = {
    title: appName.full,
    logout: "Uitloggen",
    login: "Inloggen",
    profile: "Profiel",
    projects: "Projecten",
    themes: "Thema's",
    admin: "Applicatie",
    loggedInAs: "Ingelogd als:",
};
