import React, { useState } from "react";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray } from "formik";
import { TextField, Select } from "formik-mui";
import ThemePolarArea from "../../Charts/ThemePolarArea";
import { createOrUpdateProjectThemes } from "../ProjectActions";
import { importanceLevels, scoreLevels } from "../../../context/global";
import InformationDialogue from "../../CustomComponents/InformationDialogue";
import { themes as themesText } from "../../../context/preparationPhase.js";
import StepperButtons from "../StepperButtons.js";

export default function ProjectThemes({ payload }) {
    const dispatch = useDispatch();
    const availableThemes = useSelector((state) => state.themes.themes);
    const flatThemes = useSelector((state) => state.themes.flatThemes);
    // 0= "Begin",1= "Onderdelen selecteren",2= "Thema's selecteren",3="Doelen formuleren"4="Ontwerpen definiëren"
    payload.editable = payload.editable && (payload.project.status == 0 || payload.project.status == 2) ? true : false;

    function FilledTableRow({ item, index }) {
        const [openDialog, setOpenDialog] = useState(false);
        const name = flatThemes[item.theme].name;
        const description = flatThemes[item.theme].description;
        return (
            <>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={"md"}>
                    <DialogTitle>{name}</DialogTitle>
                    <DialogContent>
                        <Typography paragraph>{description}</Typography>
                        <Typography paragraph>{name} bevat de volgende aspecten en KPI's:</Typography>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Aspect</TableCell>
                                        <TableCell>KPI's</TableCell>
                                        <TableCell>Eenheid</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {availableThemes
                                        .find((theme) => theme.id === item.theme)
                                        .subthemes.map((subtheme) => {
                                            return subtheme.kpis.map((kpi, index) => (
                                                <TableRow key={`${subtheme.name}_${kpi.name}`} sx={{ width: "100%" }}>
                                                    {index === 0 ? (
                                                        <TableCell
                                                            sx={{
                                                                paddingRight: "5px",
                                                                paddingLeft: "5px",
                                                                width: "1fr",
                                                                minWidth: "50%",
                                                                maxWidth: "350px",
                                                            }}
                                                            rowSpan={subtheme.kpis.length}
                                                        >
                                                            <Tooltip title={subtheme.description}>
                                                                <Typography>{subtheme.name}</Typography>
                                                            </Tooltip>
                                                        </TableCell>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <TableCell sx={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                                        <Tooltip title={kpi.description}>
                                                            <Typography>{kpi.name}</Typography>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                                        {kpi.unit ? (
                                                            <Typography> {kpi.unit} </Typography>
                                                        ) : (
                                                            <Tooltip
                                                                title={`${scoreLevels[0].label}, ${scoreLevels[1].label}, ${scoreLevels[2].label}, ${scoreLevels[3].label} of ${scoreLevels[4].label}`}
                                                            >
                                                                <Typography>
                                                                    Schaal van 1 {scoreLevels[0].label} tot 5 {scoreLevels[4].label}
                                                                </Typography>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ));
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup>
                            <Button onClick={() => setOpenDialog(false)}>Sluiten</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Dialog>

                <TableRow key={`tablerow_${index}`}>
                    <TableCell padding="checkbox" align="right">
                        {index + 1}
                    </TableCell>
                    <TableCell>
                        <Tooltip placement={"left-start"} title={availableThemes.find((theme) => theme.id === item.theme).description}>
                            <Typography sx={{ width: "fit-content" }}>{name}</Typography>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Button onClick={() => setOpenDialog(true)} size="small">
                            Bekijken
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Field
                            component={Select}
                            size="small"
                            sx={{
                                minWidth: 220,
                            }}
                            autoWidth={true}
                            name={`projectThemes.${index}.importance`}
                            disabled={!payload.editable}
                        >
                            {availableLevels.map((option) => (
                                <MenuItem key={option.label} value={option.value}>
                                    {`${option.value}. ${option.label}`}
                                </MenuItem>
                            ))}
                        </Field>
                    </TableCell>
                    <TableCell>
                        <Field
                            component={TextField}
                            size="small"
                            sx={{ minWidth: 250 }}
                            id={index}
                            name={`projectThemes.${index}.description`}
                            placeholder="Beschrijving"
                            multiline={true}
                            minRows={1}
                            maxRows={4}
                            disabled={!payload.editable}
                        />
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const handleCreateOrUpdateProjectThemes = (projectThemes) => {
        createOrUpdateProjectThemes(projectThemes, dispatch);
    };

    const availableLevels = importanceLevels;

    return (
        <Stack width={"75vw"}>
            <InformationDialogue text={themesText} />
            <Formik
                enableReinitialize
                initialValues={{
                    projectThemes: payload.project?.themes || [],
                }}
                onSubmit={(values, { setSubmitting }) => {
                    handleCreateOrUpdateProjectThemes(values.projectThemes, setSubmitting);
                }}
            >
                {({ values }) => {
                    return (
                        <>
                            {payload.project?.themes && payload.project?.themes.length > 0 && (
                                <ThemePolarArea valueData={values} />
                            )}
                            <Form>
                                <TableContainer sx={{ display: "grid", gridAutoColumns: "minmax(fit-content(110%),1fr)", gap: "5px" }}>
                                    <Table size="small" padding="none">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">Nummer</TableCell>
                                                <TableCell>Thema</TableCell>
                                                <TableCell>Aspecten en KPI</TableCell>
                                                <TableCell>Niveau</TableCell>
                                                <TableCell>Beschrijving</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <FieldArray
                                                name="projectThemes"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {[...values.projectThemes].map((item, index) => (
                                                            <FilledTableRow
                                                                key={`tablerow_${index}`}
                                                                item={item}
                                                                index={index}
                                                                arrayHelpers={arrayHelpers}
                                                            />
                                                        ))}
                                                    </>
                                                )}
                                            />
                                        </TableBody>
                                    </Table>
                                    <StepperButtons
                                        payload={payload}
                                        saveAction={handleCreateOrUpdateProjectThemes}
                                        saveActionArguments={values.projectThemes}
                                        updateStatus={false}
                                        warningPopup={payload.project.status <= 2}
                                    />
                                </TableContainer>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Stack>
    );
}
