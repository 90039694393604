import React, { useState } from "react";
import { Button, Typography, Box, List, ListItem, Stack } from "@mui/material";
import { useDispatch } from "react-redux";

import DesignScoreComponent from "../DesignPhase/Step_2_Designs/DesignScoreComponent";
import { createDesign, downloadDesignExport } from "../DesignPhase/DesignActions";
import { Add, Download } from "@mui/icons-material";
import StepperButtons from "../StepperButtons";
import DesignDetails from "../DesignPhase/Step_2_Designs/DesignDetails";
import { referenceDesign as text } from "../../../context/preparationPhase";
import InformationDialogue from "../../CustomComponents/InformationDialogue";

export default function ProjectReference({ payload }) {
    const loop = 0;
    const phaseID = payload.phase?.id;
    const dispatch = useDispatch();
    const referenceDesign = payload.project.reference_design;

    const [creatingDesign, setCreatingDesign] = useState(false);

    const handleCreateDesign = () => {
        setCreatingDesign(true);
        const data = {
            description: "",
            is_preferred: false,
            loop: "",
            name: `Referentie`,
            project: payload.project.id,
            scores: [],
            cost: 0,
        };
        createDesign(data, phaseID, loop?.id, dispatch, setCreatingDesign);
    };
    if (payload.project.status > 3) {
        return (
            <Box>
                <InformationDialogue text={text} />

                {referenceDesign && Object.keys(referenceDesign).length > 0 ? (
                    <>
                        {referenceDesign.scores?.length > 0 ? (
                            <>
                                <Stack direction={"row"} spacing={"auto"}>
                            <Typography variant="h4">{referenceDesign.name}</Typography>
                                    <Button onClick={() => downloadDesignExport(referenceDesign)}>
                                        <Download />
                                    </Button>
                                </Stack>
                                    <DesignDetails design={referenceDesign} payload={payload} loop={loop} phaseID={phaseID} expanded={true} />
                                <DesignScoreComponent
                                    project={payload.project}
                                    design={referenceDesign}
                                    designScores={referenceDesign.scores}
                                    phaseID={null}
                                    loopID={null}
                                    editable={payload.editable}
                                />
                            </>
                        ) : (
                            <Typography>Referentie ontwerp heeft geen geassocieerde scores.</Typography>
                        )}
                    </>
                ) : (
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => {
                            handleCreateDesign();
                        }}
                        disabled={creatingDesign}
                    >
                        Voeg ontwerp toe
                    </Button>
                )}
                <Box sx={{ marginTop: !referenceDesign || Object.keys(referenceDesign).length === 0 ? "300px" : "10px" }}>
                    <StepperButtons payload={payload} />
                </Box>
            </Box>
        );
    } else {
        return (
            <Typography>
                Er kan nog geen ontwerp worden gemaakt. De volgende stappen zijn nog niet doorlopen:
                <List>
                    {payload.project.status > 1 ? "" : <ListItem>Onderdelen zijn niet vast gezet.</ListItem>}
                    {payload.project.status > 2 ? "" : <ListItem>Thema's zijn niet vast gezet.</ListItem>}
                    {payload.project.status > 3 ? "" : <ListItem>Doelen zijn niet vast gezet.</ListItem>}
                </List>
                Ga terug naar de betreffende stap om deze af te ronden.
            </Typography>
        );
    }
}
