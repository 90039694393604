import React from "react";

import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { patchOrg } from "./OrganisationActions";
import ToggleableInputField from "../CustomComponents/InputFields/ToggleableInputField";
import { OrgAdminPage } from "../../context/global";

export default function SelectedOrganisationPane({ organisation, allowAdminActions }) {
    const dispatch = useDispatch();
    if (!organisation) {
        return <div>{OrgAdminPage.helperTextSelectOrganisation}</div>;
    } else {
        return (
            <>
                <ToggleableInputField
                    label="Naam"
                    field="name"
                    value={organisation.name}
                    onSave={(data) => patchOrg({ id: organisation.id, ...data }, dispatch)}
                    allowEdit={allowAdminActions}
                    header
                />
                <Typography paragraph>Beheerrechten: {allowAdminActions ? "Ja" : "Nee"}</Typography>
                <ToggleableInputField
                    label="Beschrijving"
                    field="description"
                    value={organisation.description}
                    onSave={(data) => patchOrg({ id: organisation.id, ...data }, dispatch)}
                    allowEdit={allowAdminActions}
                    header
                    lines={4}
                />
            </>
        );
    }
}
