import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { EuroFormat, NumberFormat, sanitizeNumber } from "../../../functions/Formatters";
import Spreadsheet from "react-spreadsheet";
import { toast } from "react-toastify";

const QuantKPIInputSpreadsheet = ({ field, form, ...props }) => {
    const kpi = props.kpi;

    const segmentNames = useMemo(() => {
        return props.segments?.map((segment) => segment.name);
    }, [props.segments]);

    const layerNames = useMemo(() => {
        return props.layers?.map((layer) => layer.name);
    }, [props.layers]);

    const [data, setData] = useState(field?.value?.map((segment) => segment.values));
    const createData = (errorString="") => {
        const arr = props.segments?.map((segment) => ({
            segment: segment.id,
            values: props.layers?.map((layer) => ({ layer: layer.id, value: null })),
        }));
        console.log(errorString)
        console.log("New data object:", arr);
        form.setFieldValue(field.name, arr);
    };

    const checkData = () => {
        if (!(field.value instanceof Array)) {
            createData("Data is not an array. Creating new data object...");
            return;
        }
        if (field.value.length !== segmentNames.length) {
            createData("Number of segments in data does not match number of segments in props. Creating new data object...");
            return;
        }
        field.value.forEach((segment) => {
            if (!(segment instanceof Object)) {
                createData("Segment is not an object. Creating new data object...");
                return;
            }
            if (!("values" in Object.keys(segment))) {
                createData("Segment does not have a values array. Creating new data object...");
                return;
            }
            if (segment.values.length !== layerNames.length) {
                createData("Number of layers in data does not match number of layers in props. Creating new data object...");
                return;
            }
        });
    };
    useEffect(() => {
        checkData();
    }, [field.value]);

    const mappedData = useMemo(() => {
        return field.value?.map((segment) => segment.values.map((layer) => ({...layer, readOnly: props.disabled})));
    }, [field.value]);

    const pushDataToField = () => {
        // Only push data onBlur to prevent the infinite loops that somehow occur with this package.
        // Slice the data arrays to the correct length, in case the user has added more rows or columns than there are segments or layers.

        const newObj = data.slice(0, props.segments.length)?.map((segment, index) => ({
            segment: props.segments[index].id,
            values: segment.slice(0, props.layers.length)?.map((layer, index) => ({ layer: props.layers[index].id, value: layer.value })),
        }));

        form.setFieldValue(field.name, newObj);
    };

    const handleChange = (table) => {
        table = table?.map((segment, i) =>
            segment?.map((layer, j) => {
                let value = sanitizeNumber(layer?.value);

                if (isNaN(Number(value))) {
                    toast.error(`Het getal "${value}" is niet geldig. Controleer de input.`);
                    value = data[i][j].value;
                }
                return { value: value };
            })
        );
        setData(table);
    };
    const sumScore = (array) => {
        if (!array) {
            return 0;
        }
        const totalScore = array.reduce((acc, segment) => {
            return (
                acc +
                segment.reduce((acc, layer) => {
                    acc += Number(layer.value) || 0;
                    return acc;
                }, 0)
            );
        }, 0);

        if (kpi.unit === "€") {
            return EuroFormat(totalScore);
        }
        return NumberFormat(totalScore);
    };

    const formattedTotalScore = useMemo(() => {
        return sumScore(data);
    }, [data]);

    const formattedReferenceScore = useMemo(() => {
        if (!props.referenceScore) {
            return 0;
        }
        const mappedScore = props.referenceScore?.map((segment) => segment.values);
        return sumScore(mappedScore);
    }, [props.referenceScore]);

    const valueRenderer = (cell) => {
        let output = sanitizeNumber(cell?.cell?.value);

        if (kpi.unit === "€") {
            output = EuroFormat(output);
        } else {
            output = NumberFormat(output);
        }
        return <Typography textAlign={"right"}>{output}</Typography>;
    };

    return (
        <>
            {props.referenceScore && (
                <>
                    <Grid item xs={2}>
                        <Typography>Referentie score</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography>{formattedReferenceScore}</Typography>
                    </Grid>
                </>
            )}
            <Grid item xs={2}>
                Score
            </Grid>
            <Grid item xs={10}>
                <Typography>{formattedTotalScore}</Typography>
            </Grid>
            <Grid item xs={12} >
                <Box sx={{overflow: 'auto', maxWidth: "calc(100vw - 22rem)"}}>
                {mappedData && <Spreadsheet
                    data={mappedData}
                    columnLabels={layerNames}
                    rowLabels={segmentNames}
                    onChange={handleChange}
                    onBlur={() => {
                        pushDataToField();
                    }}
                    allowInsertRow={false}
                    allowAddColumn={false}
                    allowAddRow={false}
                    DataViewer={valueRenderer}
                />}

                </Box>
            </Grid>
        </>
    );
};

QuantKPIInputSpreadsheet.displayName = "Spreadsheet-style input component for quantitative KPIs";
export default QuantKPIInputSpreadsheet;
