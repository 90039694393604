import React from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import ProjectMain from "../components/Projects/ProjectMain";

export default function ProjectPage() {
    const project = useSelector((state) => state.projects.selectedProject);

    if (!project) {
        return null;
    }

    return (
        <Container maxWidth={false}>
            <ProjectMain project={project} />
        </Container>
    );
}
