import React from "react";
import {
    Container,
    Grid,
    Paper,
    Typography,
    Select,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    MenuItem,
    Autocomplete,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserOverview from "../components/UserManagement/UserOverview";
import SelectedOrganisationPane from "../components/OrganisationManagement/SingleOrganisationPane";
import { Capitalize, Pluralize } from "../functions/Formatters";
import { global } from "../context/global";

/**
 * OrgAdmin Component
 *
 * This React component represents the organization admin page. It displays a list of organizations
 * that the user is associated with, allows selection of a specific organization, and provides a
 * user overview for the selected organization. Users with appropriate permissions can perform admin actions.
 *
 * @component
 */
export default function OrgAdmin() {
    // Retrieve authentication information and organization/user data from Redux store
    const auth = useSelector((state) => state.auth);
    const organisations = useSelector((state) => state.organisations.organisations);
    const users = useSelector((state) => state.users.users);

    // Initialize state to store the user IDs for filtering the user overview
    const [filteredUserIdArray, setFilteredUserIdArray] = useState(users.map((user) => user.id));

    // Use useEffect to update the user list when the users change
    // This effect is triggered when the length of the users array changes, indicating user additions/removals
    useEffect(() => {
        setFilteredUserIdArray(users.map((user) => user.id));
    }, [users]);

    // Determine if the user is associated with multiple organizations
    const multipleOrganisations = organisations.length > 1;

    // Initialize state to track the selected organization ID
    const [selectedOrganisationId, setSelectedOrganisationId] = useState(
        multipleOrganisations ? false : organisations[0]?.id
    );

    // Find the selected organization based on the selected organization ID
    const selectedOrganisation = organisations.find((org) => org.id == Number(selectedOrganisationId));

    // Determine if the user has admin privileges for the selected organization or is a staff member
    const allowAdminActions =
        (selectedOrganisation
            ? selectedOrganisation.admins.map((user) => user.id).includes(auth.account.id)
            : false) || auth.account.is_staff;

    return (
        <Container maxWidth={"xl"} component={Paper}>
            <Typography variant="h4" component="h2" gutterBottom>
                {Capitalize(`${global.my} ${Pluralize(global.organisation, organisations.length)}`)}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    {multipleOrganisations && (
                        // Radio menu to select the organization
                        <FormControl sx={{ width: "100%" }}>
                            <Autocomplete
                                options={[{ name: "Alle", id: false }, ...organisations]}
                                getOptionLabel={(option) => option.name}
                                value={selectedOrganisation}
                                placeholder="Select an organization"
                                onChange={(e, value) => setSelectedOrganisationId(value.id)}
                                renderInput={(params) => (
                                    <TextField {...params} label={Capitalize(global.organisation)} />
                                )}
                                disableClearable
                            />
                        </FormControl>
                    )}
                    <SelectedOrganisationPane
                        organisation={selectedOrganisation}
                        allowAdminActions={allowAdminActions}
                    />
                </Grid>

                <Grid item xs={12} md={9} lg={10}>
                    {/* User overview component displaying users for the selected organization */}
                    <UserOverview
                        organisationId={selectedOrganisation?.id}
                        allowAdminActions={allowAdminActions}
                        userIdArray={filteredUserIdArray}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
