import React, { useEffect } from "react";
import {
    Button,
    ButtonGroup,
    IconButton,
    TableRow,
    Typography,
    TableCell,
    Tooltip,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Stack,
    Switch,
    Select,
    MenuItem,
    Divider,
    Box,
    TableFooter,
    TablePagination,
} from "@mui/material";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NewUserDialog from "./NewUserDialog";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import {
    deleteUser,
    fetchUsers,
    removeUserFromOrg,
    patchAdminStatus,
    updateOrgAdminStatus,
    addUserToOrg,
} from "./UserActions";
import {
    Add,
    ArrowDownward,
    ArrowUpward,
    Autorenew,
    Close,
    Delete,
    PersonRemove,
    Refresh,
    Save,
    SortByAlpha,
    ToggleOffOutlined,
    ToggleOnOutlined,
} from "@mui/icons-material";
import { fetchOrgs } from "../OrganisationManagement/OrganisationActions";
import { Capitalize, DateFormat, FormattedTimeDelta, Pluralize } from "../../functions/Formatters";
import { global } from "../../context/global";
import { SortObjects } from "../../functions/Sorts";

/**
 * This component renders a table row with user data.
 *
 * @param {Object} props - props object.
 * @param {User} props.user User object to render.
 * @param {function} props.handleDeleteUser Function to call when the user is deleted.
 * @param {function} props.handleRemoveUserFromOrg Function to call when the user is removed from an organisation.
 * @param {number} props.organisationId ID of the organisation which is being viewed. If false, show all organisations the user is a member of.
 * @param {function} props.updateUserMembership Function to call when the user's membership is updated (i.e. granted or removed admin rights)
 * @param {boolean} props.allowAdminActions Whether or not to allow Orgadmin actions (adding/removing user from org, making user orgadmin).
 * @param {boolean} props.allowStaffActions Whether or not to allow staff actions (deleting user from app, making user into staff user).
 * @param {Object} props.auth Auth object of the currently logged in user.
 * @returns If the user has a membership to the currently selected organisation, return TableRow with user data and actions for this user. Otherwise, return null.
 */
function FilledTableRow({
    user,
    organisationId,
    handleDeleteUser,
    handleRemoveUserFromOrg,
    updateUserMembership,
    allowAdminActions = false,
    allowStaffActions = false,
    auth,
    dispatch,
    // index
}) {
    // const orgIdArray = user.organisations.map((org) => org.id);
    const managedOrgIdArray = user.managed_organisations.map((org) => org.id);
    const isCurrentUser = auth.account.id === user.id;
    const is_admin = managedOrgIdArray.includes(organisationId);

    const RoleCell = () => {
        // If no organisation is selected, show all organisations the user is a member of.
        // This component includes a select dropdown to add the user to a new organisation.
        if (organisationId === false) {
            // State to hold the Organisation ID of the organisation to add the target user to.
            // Get all organisations the target user is currently a member of.
            const currentOrgSet = new Set(user.organisations.map((org) => org.id));
            // Get all organisations the current user is admin of.
            const managedOrgSet = new Set(auth.account.managed_organisations.map((org) => org.id));
            // Get all organisations the target user can be added to.
            let availableOrgArray = Array.from(managedOrgSet.difference(currentOrgSet));
            availableOrgArray = availableOrgArray.reduce((acc, orgId) => {
                const org = auth.account.managed_organisations.find((org) => org.id === orgId);
                if (org) {
                    acc.push(org);
                }
                return acc;
            }, []);
            const [newOrganisationId, setNewOrganisationId] = useState(false);

            return (
                <TableCell>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack>
                            {/* list of current memberships */}
                            {user.organisations.map((org) => (
                                <Typography noWrap key={org.id}>
                                    {auth.account.organisations.length > 1 ? "-" : ""} {org.name}{" "}
                                    {managedOrgIdArray.includes(org.id) ? "(admin)" : ""}
                                </Typography>
                            ))}
                            {/* Select component to add user to a new organisation. */}
                            {newOrganisationId !== false && (
                                <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"}>
                                    <Select
                                        autoFocus
                                        defaultOpen={availableOrgArray.length > 1}
                                        value={newOrganisationId}
                                        onChange={(e) => setNewOrganisationId(e.target.value)}
                                        size="small"
                                    >
                                        <MenuItem value={true} disabled>
                                            Kies een organisatie
                                        </MenuItem>
                                        <MenuItem value={false}>
                                            Annuleren <Close sx={{ ml: "auto" }} fontSize="small" />
                                        </MenuItem>
                                        <MenuItem disabled>
                                            <Divider />
                                        </MenuItem>
                                        {availableOrgArray.map((org) => (
                                            <MenuItem key={org.id} value={org.id}>
                                                {org.name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <IconButton
                                        disabled={newOrganisationId === true}
                                        onClick={() =>
                                            addUserToOrg(user, newOrganisationId, false, dispatch, () => null)
                                        }
                                    >
                                        <Save fontSize="small" />
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                        {availableOrgArray.length > 0 && newOrganisationId === false && (
                            <Tooltip title="Omgeving toevoegen">
                                <IconButton
                                    onClick={() =>
                                        setNewOrganisationId(
                                            availableOrgArray.length === 1 ? availableOrgArray[0].id : true
                                        )
                                    }
                                >
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </TableCell>
            );
        } else {
            // If an organisation is selected, show the role of the user in that organisation.
            const toolTipText = is_admin ? "Beheerrechten verwijderen" : "Beheerder maken";
            return (
                <TableCell>
                    <Stack direction="row" alignItems={"center"}>
                        {managedOrgIdArray.includes(organisationId) ? "Ja" : "Nee"}
                        {allowAdminActions && (
                            <Tooltip placement="right-end" title={toolTipText}>
                                <Switch
                                    disabled={isCurrentUser}
                                    checked={is_admin}
                                    onChange={() => updateUserMembership(user.id, organisationId, !is_admin)}
                                >
                                    {<Autorenew />}
                                </Switch>
                            </Tooltip>
                        )}
                    </Stack>
                </TableCell>
            );
        }
    };
    const ActionCell = () => {
        if (organisationId === false) {
            return (
                <TableCell>
                    {allowAdminActions && !isCurrentUser && (
                        <ButtonGroup>
                            <IconButton onClick={() => handleDeleteUser(user)}>
                                <Tooltip placement="top" title={"Account verwijderen"}>
                                    <Delete />
                                </Tooltip>
                            </IconButton>
                        </ButtonGroup>
                    )}
                </TableCell>
            );
        } else {
            return (
                <TableCell>
                    {!isCurrentUser && (
                        <ButtonGroup>
                            <IconButton onClick={() => handleRemoveUserFromOrg(user.id)}>
                                <Tooltip
                                    placement="right-end"
                                    title={`Gebruiker verwijderen uit ${global.organisation}`}
                                >
                                    <PersonRemove />
                                </Tooltip>
                            </IconButton>
                        </ButtonGroup>
                    )}
                </TableCell>
            );
        }
    };
    const AdminCell = () => (
        <TableCell>
            <Tooltip
                placement="right"
                title={user.is_staff ? "Verwijder beheerdersrechten" : "Maak gebruiker applicatiebeheerder"}
            >
                <Switch
                    disabled={isCurrentUser}
                    checked={user.is_staff}
                    onChange={() => patchAdminStatus(user.id, dispatch)}
                >
                    {user.is_staff ? <ToggleOnOutlined /> : <ToggleOffOutlined />}
                </Switch>
            </Tooltip>
        </TableCell>
    );
    const LastLoginCell = () => (
        <TableCell>
            <Tooltip title={DateFormat(user.last_login, "long", true)}>
                <Typography>{FormattedTimeDelta(user.last_login, "nooit")}</Typography>
            </Tooltip>
        </TableCell>
    );
    const EmailCell = () => (
        <TableCell>
            <Typography>{user?.email}</Typography>
        </TableCell>
    );
    const EmployerCell = () => (
        <TableCell>
            <Typography>{user.employer || "Onbekend"}</Typography>
        </TableCell>
    );

    // if (organisationId === false || orgIdArray.includes(organisationId)) {
        return (
            <TableRow key={user.id}>
                {/* <TableCell>
                    {index}
                </TableCell> */}
                <TableCell>
                    <Typography variant="h6" fontStyle={isCurrentUser ? "italic" : "inherit"}>
                        {user.name} {isCurrentUser ? "(huidig)" : ""}
                    </Typography>
                </TableCell>
                {(allowStaffActions || allowAdminActions) && <EmailCell />}
                <EmployerCell />
                <RoleCell />
                {(allowStaffActions || allowAdminActions) && <LastLoginCell />}
                {allowStaffActions && <AdminCell />}
                {allowAdminActions && <ActionCell />}
            </TableRow>
        );
    // } else {
    //     return null;
    // }
}

/**
 * React component for the user overview table component. Includes a header, a table with user data and buttons to add new users.
 *
 * @param {Object} props props object
 * @param {number[]} props.userIdArray Array of user IDs to show in the table.
 * @param {number} [props.organisationId=false] ID of the organisation which is being viewed.
 * @param {boolean} [props.allowAdminActions] Whether or not to allow admin actions.
 * @returns Component JSX
 */
export default function UserOverview({
    userIdArray,
    organisationId = false,
    allowAdminActions = false,
    allowStaffActions = false,
}) {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const allUsers = useSelector((state) =>
        state.users.users.filter((user) => (userIdArray.includes(user.id) && organisationId === false || user.organisations.some((org) => org.id === organisationId)
    )));

    const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
    const [deleteMembershipDialogOpen, setMembershipDeleteDialogOpen] = useState(false);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    const handleClickRemoveUserFromOrg = (userID) => {
        setMembershipDeleteDialogOpen(true);
        setUserToDelete(userID);
    };
    const handleConfirmRemoveUserFromOrg = () => {
        removeUserFromOrg(userToDelete, organisationId, dispatch);
        setMembershipDeleteDialogOpen(false);
    };
    const handleUpdateMembership = (userID, orgID, admin) => {
        updateOrgAdminStatus(userID, orgID, admin, dispatch);
    };
    const handleClickDeleteUser = (user) => {
        setDeleteUserDialogOpen(true);
        setUserToDelete(user);
    };
    const handleConfirmDeleteUser = () => {
        deleteUser(userToDelete?.id, dispatch);
        setDeleteUserDialogOpen(false);
    };

    const [rowsPerPage, setRowsperPage] = useState(10);
    const [page, setPage] = useState(0);
    const handleChangeLength = (e) => {
        setRowsperPage(e.target.value);
        setPage(0);
    };

    const [sortBy, setSortBy] = useState(["name", "asc"]);
    const [sortedUsers, setSortedUsers] = useState(allUsers);

    const handleSort = (key, direction) => {
        if (!direction) {
            direction = sortBy[0] === key && sortBy[1] === "asc" ? "desc" : "asc";
        }
        setSortedUsers(SortObjects(allUsers, key, direction));
        setPage(0);
        setSortBy([key, direction]);
    };
    useEffect(() => {
        handleSort(sortBy[0], sortBy[1]);
    }, [userIdArray]);

    const SortIcon = (props) => {
        let icon = <SortByAlpha fontSize="small" />;
        if (sortBy[0] === props.sortKey) {
            icon = sortBy[1] === "asc" ? <ArrowUpward /> : <ArrowDownward />;
        }
        return (
            <Box
                sx={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
                onClick={() => handleSort(props.sortKey)}
            >
                <Typography variant="h5">{props.label || props.sortKey}</Typography>
                <Typography color="primary.dark">{icon}</Typography>
            </Box>
        );
    };

    return (
        <>
            <NewUserDialog
                open={newUserDialogOpen}
                handleClose={() => setNewUserDialogOpen(false)}
                selectedOrganisationId={organisationId}
            />
            <ConfirmDeleteDialog
                open={deleteMembershipDialogOpen}
                onClose={() => setMembershipDeleteDialogOpen(false)}
                dialogTitle={`Gebruiker verwijderen uit ${global.organisation}`}
                dialogContentText={`Weet je zeker dat je deze gebruiker wilt verwijderen uit de ${global.organisation}?`}
                dialogConfirmButton="Verwijder gebruiker"
                handleConfirm={handleConfirmRemoveUserFromOrg}
            />
            <ConfirmDeleteDialog
                open={deleteUserDialogOpen}
                onClose={() => setDeleteUserDialogOpen(false)}
                dialogTitle="Gebruiker verwijderen"
                dialogContentText={`Weet je zeker dat je de account van ${userToDelete?.name} wilt verwijderen?`}
                dialogConfirmButton="Verwijder gebruiker"
                handleConfirm={handleConfirmDeleteUser}
            />

            <ButtonGroup variant="contained">
                <Button startIcon={<Refresh />} onClick={() => fetchUsers(dispatch)}>
                    Gebruikers verversen
                </Button>
                <Button startIcon={<Refresh />} onClick={() => fetchOrgs(dispatch)}>
                    {Pluralize(global.organisation)} verversen
                </Button>
                {allowAdminActions && (
                    <Button startIcon={<Add />} onClick={() => setNewUserDialogOpen(true)}>
                        Nieuwe gebruiker
                    </Button>
                )}
            </ButtonGroup>
            {sortedUsers.length === 0 ? (
                <Typography variant="h5">Er zijn geen gebruikers gevonden.</Typography>
            ) : (
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <SortIcon sortKey={"name"} label={"Naam"} />
                                </TableCell>
                                {(allowStaffActions || allowAdminActions) && (
                                    <TableCell>
                                        <SortIcon sortKey={"email"} label={"Email"} />
                                    </TableCell>
                                )}
                                <TableCell>
                                    <SortIcon sortKey={"employer"} label={"Organisatie"} />
                                </TableCell>

                                <TableCell>
                                    <Typography variant="h5">
                                        {organisationId === false
                                            ? Capitalize(Pluralize(global.organisation))
                                            : "Beheerder"}
                                    </Typography>
                                </TableCell>

                                {(allowStaffActions || allowAdminActions) && (
                                    <TableCell>
                                        <SortIcon sortKey="last_login" label="Laatst ingelogd" />
                                    </TableCell>
                                )}

                                {allowStaffActions && (
                                    <TableCell>
                                        <Typography variant="h5">Applicatie beheerder</Typography>
                                    </TableCell>
                                )}
                                {allowAdminActions && (
                                    <TableCell>
                                        <Typography variant="h5">Acties</Typography>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...sortedUsers.slice(page * rowsPerPage, (page + 1) * rowsPerPage)]
                                
                                .map((user, index) => (
                                    <FilledTableRow
                                        key={user.id}
                                        user={user}
                                        handleRemoveUserFromOrg={handleClickRemoveUserFromOrg}
                                        handleDeleteUser={handleClickDeleteUser}
                                        organisationId={organisationId}
                                        updateUserMembership={handleUpdateMembership}
                                        allowAdminActions={allowAdminActions}
                                        allowStaffActions={allowStaffActions}
                                        allowDelete={auth.account.is_staff}
                                        auth={auth}
                                        dispatch={dispatch}
                                        index={index}
                                    />
                                ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            </TableRow>
                        </TableFooter>
                    </Table>
                    <TablePagination
                        component={'div'}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        count={allUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, value) => setPage(value)}
                        onRowsPerPageChange={handleChangeLength}
                    />
                </TableContainer>
            )}
        </>
    );
}
