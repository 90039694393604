import { Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import StepperButtons from "../StepperButtons";

export default function StartDesignPhase({ payload, handleAdvancePhase }) {
    const [showDesignLoopWarning, setShowDesignLoopWarning] = useState(false);

    return (
        <>
            <Dialog open={showDesignLoopWarning}>
                <DialogTitle>Nieuw ontwerpproces starten?</DialogTitle>

                <DialogContent>
                    <Typography>
                        Weet je zeker dat je een ontwerpproces starten? Dit zet alle informatie in de Opstartfase vast. Dit kan niet ongedaan gemaakt
                        worden.
                    </Typography>
                </DialogContent>
                <ButtonGroup>
                    <Button onClick={() => setShowDesignLoopWarning(false)}>Annuleren</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleAdvancePhase();
                            setShowDesignLoopWarning(false);
                        }}
                    >
                        Ontwerpproces starten
                    </Button>
                </ButtonGroup>
            </Dialog>
            <Box>
                <Typography variant="body1" gutterBottom>
                    U kunt nu de ontwerpfase starten. In deze fase gaat u aan de slag met het ontwerpen van een oplossing voor uw project.
                </Typography>
                <ButtonGroup>
                    <Button onClick={() => setShowDesignLoopWarning(true)} disabled={payload.project.phases.length > 0 || !payload.editable}>
                        {payload.project.phases.length > 0 ? "Ontwerpfase is reeds gestart" : "Start"}
                    </Button>
                </ButtonGroup>
            </Box>
            <StepperButtons payload={payload} updateStatus={false} warningPopup={false} />
        </>
    );
}
