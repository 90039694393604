import { toast } from "react-toastify";
import { poster, fetcher, patcher, deleter } from "../../utils/axios.js";
import { addProject, setProjects, removeProject, updateProject, updateStatus, removeProjectSubtheme, restoreProject } from "../../store/slices/projectsSlice.js";
import { setReferenceDesign } from "../../store/slices/designSlice.js";
import { OpenFileInTab } from "../Attachments/AttachmentActions.js";

export const createNewProject = (projectData, dispatch, closeDialog) => {
    poster("/projects/", projectData).then((response) => {
        if (response?.status === 201) {
            toast.success("Project aangemaakt");
            dispatch(addProject(response.data));
            closeDialog();
        }
    });
};

export const fetchProjects = (dispatch) => {
    fetcher("/projects/").then((response) => {
        if (response?.status === 200) {
            dispatch(setProjects(response.data.sort((a, b) => a.id - b.id)));
        }
    });
};

export const deleteProject = (id, dispatch) => {
    deleter(`/projects/${id}/`).then((response) => {
        if (response.status === 204) {
            dispatch(removeProject(id));
        }
    });
};

export const unDeleteProject = (id, dispatch) => {
    poster(`/projects/${id}/undelete/`).then((response) => {
        if (response.status === 200) {
            dispatch(restoreProject(id));
        }
    })
}

export const selectProject = (id, dispatch) => {
    fetcher(`/projects/${id}/`).then((response) => {
        dispatch(updateProject(response.data));
        if (response.data.reference_design) {
            dispatch(setReferenceDesign(response.data.reference_design));
        }
    });
};
export const patchProject = (data, dispatch) => {
    patcher(`/projects/${data.id}/`, data).then((response) => {
        if (response.status === 200) {
            dispatch(updateProject(response.data));
            toast.success("Wijzigingen opgeslagen");
        }
    });
};

export const advancePhase = (id, segments, design, phase, phase_name, dispatch) => {
    console.log(phase, phase_name);
    const data = {
        segments: segments,
        phase: phase,
        phase_name: phase_name,
    };
    design ? (data.design = design) : "";

    console.log(data);

    poster(`/projects/${id}/advance_phase/`, data).then((response) => {
        if (response.status === 200) {
            dispatch(updateProject(response.data));
            toast.success("Projectfase aangepast");
        }
    });
};

export const advanceStatus = (id, data, dispatch) => {
    poster(`/projects/${id}/advance_status/`, data).then((response) => {
        if (response.status === 200) {
            console.log(response.data);
            dispatch(updateStatus(response.data));
            toast.success("Status geüpdate");
        }
    });
};

export const addLoopToPhaseWithDesigns = (id, data, dispatch) => {
    poster(`/projects/${id}/add_loop_to_phase/`, data).then((response) => {
        if (response.status === 200) {
            dispatch(updateProject(response.data));
            toast.success("Ontwerploop toegevoegd");
        }
    });
};

export const addLoopToPhase = (id, dispatch) => {
    poster(`/projects/${id}/add_loop_to_phase/`).then((response) => {
        if (response.status === 200) {
            dispatch(updateProject(response.data));
            toast.success("Ontwerploop toegevoegd");
        }
    });
};

export const createOrUpdateProjectThemes = (data, dispatch) => {
    poster("/projectthemes/", data).then((response) => {
        if (response?.status === 201) {
            toast.success("Projectthema's gewijzigd");
            dispatch(updateProject(response.data));
        }
    });
};

export const createOrUpdateProjectSubThemes = (data, dispatch) => {
    poster("/projectsubthemes/", data).then((response) => {
        if (response?.status === 201) {
            toast.success("Aspecten gewijzigd");
            dispatch(updateProject(response.data));
        }
    });
};

export const deleteProjectSubTheme = (subthemeID, projectThemeID, dispatch) => {
    deleter(`/projectsubthemes/${subthemeID}/`).then((response) => {
        if (response.status === 204) {
            dispatch(removeProjectSubtheme(subthemeID, projectThemeID))
        }
    });
};

export const createOrUpdateProjectGoals = (data, dispatch) => {
    poster("/projectgoals/", data).then((response) => {
        if (response?.status === 201) {
            toast.success("Doelen gewijzigd");
            dispatch(updateProject(response.data));
        }
    });
};

export const patchProjectAdvice = (advices, project, { phaseID, loopID, projectTheme }, dispatch) => {
    let responses = advices.map((advice) => {
        patcher(`/advices/${advice.id}/`, advice).then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
    });

    // Perform update on redux state if necessary
    if (responses.length === advices.length) {
        // Find the loop that needs to be updated
        let updatedLoop = project.phases.find((phase) => phase.id === phaseID).loops.find((loop) => loop.id === loopID);
        // For each updated advice, update the advice_by_themes object
        // The reduce function will transform the array of repsponses into an object
        let updatedAdvice = {
            ...updatedLoop.advice_by_themes,
            [projectTheme]: {
                ...updatedLoop.advice_by_themes[projectTheme],
                ...responses.reduce((acc, response) => {
                    return (
                        {
                            ...acc,
                            [response.project_subtheme]: response,
                        },
                        {}
                    );
                }),
            },
        };
        updatedLoop = { ...updatedLoop, advice_by_themes: updatedAdvice };

        // Perform the project state update. This is a bit tricky because of the nested structure
        dispatch(
            updateProject({
                ...project,
                phases: project.phases.map((phase) => {
                    if (phase.id === phaseID) {
                        return {
                            ...phase,
                            loops: phase.loops.map((loop) => {
                                if (loop.id === loopID) {
                                    return updatedLoop;
                                } else {
                                    return loop;
                                }
                            }),
                        };
                    } else {
                        return phase;
                    }
                }),
            })
        );
        toast.success("Advies opgeslagen");
    }
};

export const downloadProjectExport = (project) => {
    toast.success("Export wordt gedownload. Dit kan even duren.", { autoClose: 1000 });
    fetcher(`/projects/${project.id}/export_to_pdf/`, "blob").then((response) => {
        if (response.status === 200) {
            toast.success("Export klaar!", { autoClose: 1000 });
            const blob = new Blob([response.data], { type: response.headers["content-type"], name: project.name + ".pdf" });
            const blobURL = URL.createObjectURL(blob);
            OpenFileInTab(blobURL);
        } else {
            toast.error("Er is iets misgegaan bij het downloaden van de export.");
        }
    });
};


