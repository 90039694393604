export const links = [
    {
        text: "Roadmap Duurzame Dijkversterkingen",
        description:
            "De roadmap geeft aan welke duurzaamheids-issues er zijn tijdens de voorverkenning, verkenning, planuitwerkingfase en realisatie.",
        to: "http://www.hwbp.nl/documenten/rapporten/2020/04/14/roadmap-duurzaamheid",
    },
    {
        text: "Duurzaamheidsroos van HWBP",
        description: "De duurzaamheidsroos biedt een gezamenlijk beeld en begrippenkader voor duurzame dijkversterkingen.",
        to: "http://hoogwaterbescherming.foleon.com/magazine/duurzame-dijken/duurzaamheidsroos-duplicate/",
    },
    {
        text: "DuboCalc",
        description: "Dit is het rekenprogramma milieu-impact GWW",
        to: "http://dubocalc.nl",
    },
    {
        text: "Handreiking DuboCalc in dijkversterkingen",
        description: "Handreiking DuboCalc & MKI in dijkversterkingsprojecten",
        to: "http://www.hwbp.nl/documenten/handreikingen/2021/11/26/handreiking-dubocalc-in-dijkversterkingen",
    },
    {
        text: "Circulaire Peiler",
        description: "De Circulaire Peiler maakt de circulariteit van een project meetbaar en inzichtelijk.",
        to: "http://www.vallei-veluwe.nl/wat-doet-waterschap/circulair/duurzame-etalage/circulaire-peiler/",
    },
    {
        text: "Platform CB'23",
        description:
            "Platform CB'23 is een initiatief dat ernaar streeft om vóór 2023 nationale, bouwsector-brede afspraken op te stellen over circulair bouwen.",
        to: "http://platformcb23.nl/",
    },
    {
        text: "Bouwstenen duurzame dijken",
        description:
            "De Roadmap Duurzame dijkversterkingen is de basis voor projectmedewerkers die opzoek zijn naar informatie over duurzaamheid en ruimtelijke kwaliteit.",
        to: "http://www.hwbp.nl/documenten/handreikingen/2022/4/15/bouwstenen-duurzame-dijkversterkingen",
    },
    {
        text: "Toetsingskader emissieloos bouwen HWBP 2022",
        description:
            "Het toetsingskader emissieloos bouwen HWBP 2022 is een handreiking voor het toepassen van emissieloos bouwen in HWBP-projecten.",
        to: "https://www.hwbp.nl/documenten/handreikingen/2022/09/23/toetsingskader-emissieloos-bouwen-hwbp-2022",
    },
    // {
    //     text: "3 x perspectief op stikstofproblematiek",
    //     description:
    //         "3 praktijkverhalen met een andere invalshoek op de stikstofproblematiek.",
    //     to: "https://hoogwaterbescherming.foleon.com/magazine/hwbp-maart-2023/stikstof",
    // },
];
