import React, { useState } from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    FormControl,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { ExpandMore, ArrowUpward, ArrowDownward, HelpOutline, MenuBook } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray } from "formik";
import { TextField } from "formik-mui";
import { patchProjectAdvice } from "../../ProjectActions";
import { global, importanceLevels, scoreLevels } from "../../../../context/global";
import DesignPrincipleDialog from "./DesignPrincipleDialog";
import AttachmentTable from "../../../Attachments/AttachmentTable";
import StepperButtons from "../../StepperButtons";
import InformationDialogue from "../../../CustomComponents/InformationDialogue";
import { advice } from "../../../../context/designPhase";
import { NumberFormat } from "../../../../functions/Formatters";

export default function ProjectAdvice({ payload }) {
    const dispatch = useDispatch();
    const projectThemes = payload.project.themes;
    const flatThemes = useSelector((state) => state.themes.flatThemes);
    const flatSubthemes = useSelector((state) => state.themes.flatSubThemes);

    const flatKpis = useSelector((state) => state.themes.flatKpis);
    const { project, loop, phase } = payload;

    const [minimumImportanceFilter, setMinimumImportanceFilter] = useState(3); // chioces: [0, 1, 2, 3]
    const filteredProjectThemes = projectThemes.filter((theme) => theme.importance == minimumImportanceFilter).sort((a, b) => flatThemes[a.theme].supertheme - flatThemes[b.theme].supertheme);

    const [openPrincipleDialog, setOpenPrincipleDialog] = useState(false);
    const [designPrincipleFocus, setDesignPrincipleFocus] = useState(false);

    const showFocusedDesignPrinciples = (themeID) => {
        setDesignPrincipleFocus(themeID);
        setOpenPrincipleDialog(true);
    };

    // Add a check to toggle editable. Inputs can can only be changed in the last loop of the last phase.
    const lastPhase = payload.project.phases.slice(-1)[0];
    const lastLoop = lastPhase.loops.slice(-1)[0];
    payload.editable = payload.loop.id === lastLoop.id ? payload.editable : false;

    const [expandedTheme, setExpandedTheme] = useState(0);
    const handleThemeExpansion = (panel) => (event, isExpanded) => {
        setExpandedTheme(isExpanded ? panel : false);
    };

    return (
        <>
            <InformationDialogue text={advice} />
            <DesignPrincipleDialog open={openPrincipleDialog} onClose={() => setOpenPrincipleDialog(false)} focus={designPrincipleFocus} setFocus={setDesignPrincipleFocus} />
            <Stack spacing={1}>
                <Typography>Hieronder kunt per thema en aspect de de ontwerpadviezen invullen voor de huidige ontwerpronde. Met de knop 'Ontwerpprincipes' kunt u zien welke ontwerpprincipes van toepassing zijn op het thema.</Typography>

                {/* <Stack direction={"row"} spacing={2} alignItems={"start"}> */}
                <ButtonGroup>
                    <Button variant="contained" onClick={() => showFocusedDesignPrinciples(false)} startIcon={<MenuBook />}>
                        Alle ontwerpprincipes
                    </Button>
                </ButtonGroup>
                <FormControl>
                    <FormLabel>Niveau</FormLabel>
                    <RadioGroup value={minimumImportanceFilter} onChange={(e) => setMinimumImportanceFilter(e.target.value)} row>
                        {importanceLevels.map((level) => (
                            <FormControlLabel key={level.value} value={level.value} control={<Radio />} label={level.label} />
                        ))}
                    </RadioGroup>
                </FormControl>
                {filteredProjectThemes.length > 0 ? (
                    filteredProjectThemes.map((projecttheme, projectThemeIndex) => (
                        <Accordion key={projecttheme.id} expanded={expandedTheme === projectThemeIndex} onChange={handleThemeExpansion(projectThemeIndex)}>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ m: 0 }}>
                                <Stack sx={{ pb: 0, pt: 0, mb: 0, mt: 0 }}>
                                    <Typography variant="h6">
                                        {flatThemes[projecttheme.theme]?.name}
                                        {
                                            <Tooltip title={flatThemes[projecttheme.theme]?.description}>
                                                <HelpOutline fontSize="small" />
                                            </Tooltip>
                                        }
                                    </Typography>
                                    <Typography>Niveau: {importanceLevels[projecttheme.importance].label}</Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails sx={{ m: 0 }}>
                                {projecttheme?.description && <Typography paragraph>Beschrijving: {projecttheme.description}</Typography>}
                                <Button startIcon={<MenuBook />} variant="contained" color="secondary" onClick={() => showFocusedDesignPrinciples(projecttheme.theme)} sx={{ margin: "15px" }}>
                                    Ontwerpprincipes
                                </Button>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        subthemes:
                                            projecttheme.subthemes.map((projectsubtheme) => ({
                                                ...projectsubtheme,
                                                advice: loop.advice_by_themes[projecttheme.id]?.[projectsubtheme.id],
                                            })) || [],
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        const advices = values.subthemes.map((projectsubtheme) => projectsubtheme.advice);
                                        if (payload.editable) {
                                            patchProjectAdvice(
                                                advices,
                                                payload.project,
                                                {
                                                    phaseIndex: payload.phaseIndex,
                                                    loopIndex: payload.loopIndex,
                                                    projectTheme: projecttheme.id,
                                                },
                                                dispatch
                                            );
                                        }
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ values, handleSubmit }) => (
                                        <Form>
                                            {values.subthemes.length === 0 ? (
                                                <Typography>Er zijn geen aspecten gekozen voor dit thema.</Typography>
                                            ) : (
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Aspect</TableCell>
                                                                <TableCell>Doel</TableCell>
                                                                <TableCell>Ontwerpadvies</TableCell>
                                                                <TableCell>Bijlagen</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <FieldArray
                                                                name="subthemes"
                                                                render={() => (
                                                                    <>
                                                                        {values.subthemes.map((projectsubtheme, subthemeIndex) => (
                                                                            <TableRow
                                                                                key={`advicerow_${projectThemeIndex}_${subthemeIndex}`}
                                                                                sx={{
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <TableCell sx={{ minWidth: 120 }}>
                                                                                    <Typography>{flatSubthemes[projectsubtheme.subtheme]?.name}</Typography>
                                                                                </TableCell>
                                                                                <TableCell sx={{ minWidth: 250 }}>
                                                                                    {projectsubtheme.goals.map((goal) => {
                                                                                        const kpi = flatKpis[goal.kpi];
                                                                                        return (
                                                                                            <Typography key={`${projectsubtheme.id}_${goal.id}`}>
                                                                                                {kpi.name === "Score" ? `${scoreLevels[goal.value - 1].label} ` : `${kpi.name}: ${kpi.maximize ? "≥" : "≤"} ${NumberFormat(goal.value)} ${kpi.unit}`}
                                                                                            </Typography>
                                                                                        );
                                                                                    })}
                                                                                </TableCell>
                                                                                <TableCell sx={{ minWidth: 400 }}>
                                                                                    <Field
                                                                                        component={TextField}
                                                                                        name={`subthemes.${subthemeIndex}.advice.text`}
                                                                                        inputProps={{
                                                                                            id: `subthemes.${subthemeIndex}.advice.text`,
                                                                                        }}
                                                                                        multiline
                                                                                        minRows={2}
                                                                                        maxRows={5}
                                                                                        fullWidth
                                                                                        disabled={!payload.editable}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: 0 }}>
                                                                                    <AttachmentTable
                                                                                        parentObj={projectsubtheme.advice}
                                                                                        parentType="advice"
                                                                                        parentIds={{
                                                                                            project: project.id,
                                                                                            phase: phase.id,
                                                                                            loop: loop.id,
                                                                                            themeID: projecttheme.id,
                                                                                            subthemeID: projectsubtheme.id,
                                                                                        }}
                                                                                        defaultExpanded={false}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            />
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                            <ButtonGroup variant="outlined">
                                                {projectThemeIndex > 0 && (
                                                    <Button
                                                        onClick={() => {
                                                            if (payload.editable) handleSubmit();
                                                            setExpandedTheme(Math.max(projectThemeIndex - 1, 0));
                                                        }}
                                                        startIcon={<ArrowUpward />}
                                                    >
                                                        {payload.editable ? `${global.save} ${global.and}` : ""} {global.previous} {global.theme}
                                                    </Button>
                                                )}
                                                {projectThemeIndex < filteredProjectThemes.length - 1 && (
                                                    <Button
                                                        onClick={() => {
                                                            if (payload.editable) handleSubmit();
                                                            setExpandedTheme(Math.min(projectThemeIndex + 1, filteredProjectThemes.length - 1));
                                                        }}
                                                        endIcon={<ArrowDownward />}
                                                    >
                                                        {payload.editable ? `${global.save} ${global.and}` : ""} {global.next} {global.theme}
                                                    </Button>
                                                )}
                                            </ButtonGroup>
                                        </Form>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Typography variant="body1">{projectThemes.length === 0 ? "Er zijn nog geen thema's toegevoegd aan dit project." : "Er zijn geen thema's van het geselecteerde niveau."}</Typography>
                )}
                <StepperButtons payload={payload} updateStatus={false} />
            </Stack>
        </>
    );
}
