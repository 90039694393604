import React from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    ButtonGroup,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { createNewOrg } from "./OrganisationActions";
import { Capitalize } from "../../functions/Formatters";
import { global } from "../../context/global";

export default function NewOrganisationDialog({ open, onClose }) {
    const dispatch = useDispatch();

    const createOrgAndCloseDialog = (name, description) => {
        createNewOrg({ name: name, description: description }, dispatch, onClose);
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={"new_organisation_dialog"}>
            <DialogTitle id={"new_organisation_dialog"}>
                {Capitalize(`${global.organisation} ${global.add}`)}
            </DialogTitle>
            <Formik
                initialValues={{
                    name: "",
                    description: "",
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .max(255, "Naam mag niet meer dan 255 tekens zijn")
                        .trim()
                        .required("Naam is vereist"),
                    description: Yup.string().trim(),
                })}
                onSubmit={(values) => {
                    createOrgAndCloseDialog(values.name, values.description);
                }}
            >
                {({ isValid }) => (
                    <Form>
                        <DialogContent>
                            <Stack spacing={2}>
                                <Field
                                    component={TextField}
                                    name="name"
                                    label="Naam"
                                />
                                <Field
                                    component={TextField}
                                    name="description"
                                    label="Beschrijving"
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <ButtonGroup variant="contained">
                                <Button type="submit" color="primary" disabled={!isValid}>
                                    {Capitalize(`${global.organisation} ${global.add}`)}
                                </Button>
                                <Button onClick={() => onClose()} color="error">
                                    Annuleren
                                </Button>
                            </ButtonGroup>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
