import React, { useState } from "react";

import { CardActionArea, Stack, Card, Collapse, CardContent, Box, Typography, Button, ButtonGroup } from "@mui/material";
import { Delete, ExpandMore } from "@mui/icons-material";

import { useDispatch } from "react-redux";

import { patchDesign } from "../DesignActions";
import ToggleableInputField from "../../../CustomComponents/InputFields/ToggleableInputField";
import AttachmentTable from "../../../Attachments/AttachmentTable";
import ConfirmDeleteDialog from "../../../ConfirmDeleteDialog";
import { deleteDesign } from "../DesignActions";

export default function DesignDetails({ design = null, loop = null, phaseID = null, payload, expanded = true }) {
    const dispatch = useDispatch();
    const [details, setDetails] = useState(expanded);
    const [deleteDesignOpen, setDeleteDesignOpen] = useState(false);

    const openDeleteDialogue = (design) => {
        setDeleteDesignOpen(design);
        setDeleteDesignOpen(true);
    };

    return (
        <>
            <ConfirmDeleteDialog
                open={deleteDesignOpen}
                onClose={() => setDeleteDesignOpen(false)}
                handleConfirm={() => {
                    deleteDesign(design.id, phaseID, loop?.id, dispatch);
                    setDeleteDesignOpen(false);
                }}
                dialogTitle={`Ontwerp verwijderen?`}
                dialogContentText={`Weet u zeker dat u ontwerp "${design?.name}" wilt verwijderen?`}
                dialogConfirmButton="Verwijderen"
            />
            <Card elevation={0} sx={{ p: 1 }}>
                <CardActionArea onClick={() => setDetails(!details)}>
                    <Stack direction="row" justifyContent={details ? "space-between" : "flex-start"}>
                        <Typography variant="h5" fontSize={"1rem"}>
                            Details
                        </Typography>
                        {/* <Edit /> */}
                        <ExpandMore sx={{ rotate: details ? "180deg" : "" }} />
                    </Stack>
                </CardActionArea>
                <Collapse in={details}>
                    <CardContent sx={{ p: 0, pl: 2 }}>
                        {/* <Grid container> */}
                        <Stack direction="row">
                            <Box flexGrow={1}>
                                <ToggleableInputField
                                    label="Beschrijving"
                                    field="description"
                                    value={design.description}
                                    header={false}
                                    lines={4}
                                    onSave={(data) => patchDesign({ ...data, id: design.id }, phaseID, loop.id, dispatch)}
                                    allowEdit={true}
                                    type="text"
                                />
                                <ToggleableInputField
                                    label="Investeringskosten"
                                    field="investment_cost"
                                    name="investment_cost"
                                    value={design.investment_cost}
                                    header={false}
                                    onSave={(data) => patchDesign({ ...data, id: design.id }, phaseID, loop.id, dispatch)}
                                    allowEdit={payload.editable}
                                    type="number"
                                    unit="€"
                                />
                                <Typography>Bijlagen:</Typography>
                                <AttachmentTable
                                    parentType="design"
                                    parentIds={{ project: payload.project.id, phase: phaseID, loop: loop.id, design: design.id }}
                                    parentObj={design}
                                    defaultExpanded={true}
                                    showHeader={true}
                                />
                            </Box>
                            <Box>
                                <ButtonGroup>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        // sx={{ marginLeft: "auto" }}
                                        startIcon={<Delete />}
                                        onClick={() => openDeleteDialogue(design)}
                                        disabled={!payload.editable || design.loop !== payload.lastLoopID}
                                    >
                                        Verwijderen
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Stack>
                        {/* </Grid> */}
                    </CardContent>
                </Collapse>
            </Card>
        </>
    );
}
