import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateThemeAction } from "./ThemeActions";
import ToggleableInputField from "../CustomComponents/InputFields/ToggleableInputField";

/**
 * SingleThemePane component for displaying and editing a single theme's information.
 * Use the ToggleableInputField component to display and edit the theme's name and description.
 *
 * @param {Object} props - Props object.
 * @param {Object} props.theme - The theme to display and edit.
 */
export default function SingleThemePane({ theme }) {
    const userIsAdmin = useSelector((state) => state.auth.account.is_staff);
    const dispatch = useDispatch();

    if (theme === null) {
        return <div>Selecteer een thema</div>;
    } else {
        return (
            <>
                <ToggleableInputField
                    label="Naam"
                    field="name"
                    value={theme.name}
                    onSave={(data) => updateThemeAction({ ...data, id: theme.id }, dispatch)}
                    header
                    allowEdit={userIsAdmin}
                />
                <ToggleableInputField
                    label="Beschrijving"
                    field="description"
                    value={theme.description}
                    onSave={(data) => updateThemeAction({ ...data, id: theme.id }, dispatch)}
                    header
                    lines={8}
                    allowEdit={userIsAdmin}
                />
            </>
        );
    }
}
