import { Container, Paper, Tooltip } from "@mui/material";
import React from "react";
import StyledLink from "../components/CustomComponents/StyledLink";
import "../assets/dog_logo.jpg";

// frontend/src/assets/dog_logo.jpg

export default function Links() {
    const linkList = [
        {
            text: "DuboCalc",
            description: "Dit is het rekenprogramma milieu-impact GWW",
            to: "http://dubocalc.nl",
        },
        {
            text: "Handreiking",
            description: "Handreiking DuboCalc & MKI in dijkversterkingsprojecten",
            to: "http://www.hwbp.nl/documenten/handreikingen/2021/11/26/handreiking-dubocalc-in-dijkversterkingen",
        },
        {
            text: "Roadmap Duurzame Dijkversterkingen",
            description:
                "De roadmap geeft aan welke duurzaamheids-issues er zijn tijdens de voorverkenning, verkenning, planuitwerkingfase en realisatie.",
            to: "http://www.hwbp.nl/documenten/rapporten/2020/04/14/roadmap-duurzaamheid",
        },
        {
            text: "Circulaire Peiler",
            description: "De Circulaire Peiler maakt de circulariteit van een project meetbaar en inzichtelijk.",
            to: "http://www.vallei-veluwe.nl/wat-doet-waterschap/circulair/duurzame-etalage/circulaire-peiler/",
        },
        {
            text: "Platform CB'23",
            description:
                "Platform CB'23 is een initiatief dat ernaar streeft om vóór 2023 nationale, bouwsector-brede afspraken op te stellen over circulair bouwen.",
            to: "http://platformcb23.nl/",
        },
        {
            text: "Duurzaamheidsroos van HWBP",
            description: "De duurzaamheidsroos biedt een gezamenlijk beeld en begrippenkader voor duurzame dijkversterkingen.",
            to: "http://hoogwaterbescherming.foleon.com/magazine/duurzame-dijken/duurzaamheidsroos-duplicate/",
        },
        {
            text: "Bouwstenen duurzame dijken",
            description:
                "De Roadmap Duurzame dijkversterkingen is de basis voor projectmedewerkers die opzoek zijn naar informatie over duurzaamheid en ruimtelijke kwaliteit.",
            to: "http://www.hwbp.nl/documenten/handreikingen/2022/4/15/bouwstenen-duurzame-dijkversterkingen",
        },
    ];

    return (
        <Container component={Paper} sx={{ display: "flex", flexDirection: "column", margin: "-20px auto", width: "100%", maxWidth: "1200px" }}>
            {linkList.map((link, index) => {
                link.inMenuItem = true;
                link.margin = "5px";
                link.target = "_blank";
                link.rel = "noopener noreferrer";
                return (
                    <Tooltip key={`${index}_${link.text}`}>
                        <StyledLink {...link} />
                    </Tooltip>
                );
            })}
        </Container>
    );
}
