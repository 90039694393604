export const kpiDirections = {
    maximize: {
        label: "Maximaliseren",
        description: `Maximaliseren betekent dat een project idealiter zo hoog mogelijk scoort op deze KPI`,
    },
    minimize: {
        label: "Minimaliseren",
        description: `Minimaliseren betekent dat een project idealiter zo laag mogelijk scoort op deze KPI`,
    },
};

export const kpiTypes = {
    0: {
        label: "Kwalitatief",
        description: `
        Een kwalitatieve KPI heeft geen eenheid, maar een schaal waarop 1 het laagst haalbare en 5 het hoogst haalbare is. 
        Dit type is van toepassing wanneer de waarde van de KPI niet kan worden gemeten of berekend. 
        Bijvoorbeeld: landschappelijke kwaliteit of beleving.`,
    },
    1: {
        label: "Kwantitatief gedetailleerd",
        description: `
        Een kwantitatieve, gedetailleerde KPI kan wel worden gemeten of berekend en heeft een eenheid.
        De waarde van de KPI is het totaal van een deel van een ontwerp. De totale waarde voor een ontwerp is de optelsom van de waardes van de delen.
        Dit type is van toepassing wanneer de waarde logischerwijs kan worden uitgesplitst en er gedetailleerde informatie bekend is.`,
    },
    2: {
        label: "Kwantitatief globaal",
        description: `
        Een kwantitatieve, globale KPI kan wel worden gemeten of berekend en heeft een eenheid. 
        De waarde van de KPI is het totaal van een geheel ontwerp. Dit type is van toepassing wanneer de waarde niet logischerwijs kan worden uitgesplitst, 
        of wanneer er geen gedetailleerde informatie bekend is. Bijvoorbeeld: Return on Investment of efficiëntie.`,
    },
};
