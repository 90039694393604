import React from "react";

import {
    Container,
    Typography,
    Box,
    Paper,
    useTheme,
    Grid,
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    CardActionArea,
    Stack,
} from "@mui/material";
import { Download, ExpandMore, OpenInNew } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { downloadFixedFile } from "./Attachments/AttachmentActions";
import { links } from "../context/externalLinks";
import { expandHeaders, siteInfo } from "../context/footer";

function Footer() {
    const theme = useTheme();
    const files = useSelector((state) => state.auth.files);
    const [cardContent, setCardContent] = React.useState(null);
    const [generalInfoOpen, setGeneralInfoOpen] = React.useState(false);

    const handleExpand = (content) => {
        if (cardContent === content) {
            setCardContent(null);
        } else {
            setCardContent(content);
        }
    };

    return (
        <Paper
            sx={{
                position: "sticky",
                height: cardContent || generalInfoOpen ? "max-content" : 70,
                width: "100%",
                marginTop: 3,
                backgroundColor: theme.palette.primary.main,
            }}
            component="footer"
            square
            variant="outlined"
        >
            <Container maxWidth={false}>
                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        mb: 2,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <CardActionArea onClick={() => setGeneralInfoOpen(!generalInfoOpen)}>
                                <Stack direction="row" alignItems={"center"}>
                                    <ExpandMore sx={{ rotate: generalInfoOpen ? "180deg" : "270deg", color: theme.palette.text.white }} />
                                    <Typography textAlign={"center"} variant="h6" color={theme.palette.text.white}>
                                        {expandHeaders.site}
                                    </Typography>
                                </Stack>
                            </CardActionArea>
                            <Collapse in={generalInfoOpen} sx={{ p: 1 }}>
                                {siteInfo.map((text, index) => (
                                    <Typography paragraph variant="body2" key={`text_${index}`}>
                                        {text}
                                    </Typography>
                                ))}
                            </Collapse>
                        </Grid>
                        <Grid item xs={files.length > 0 ? 4 : 8}>
                            <CardActionArea onClick={() => handleExpand("links")}>
                                <Stack direction="row" alignItems={"center"}>
                                    <ExpandMore sx={{ rotate: cardContent === "links" ? "180deg" : "270deg", color: theme.palette.text.white }} />
                                    <Typography textAlign={"center"} variant="h6" color={theme.palette.text.white}>
                                        {expandHeaders.links}
                                    </Typography>
                                </Stack>
                            </CardActionArea>
                            <List sx={{ width: "60vw" }} disablePadding>
                                <Collapse in={cardContent === "links"}>
                                    {links.map((link, index) => (
                                        <ListItem key={`link_${index}`} disablePadding disableGutters>
                                            <ListItemButton
                                                component={Link}
                                                to={link.to}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{ width: "100%", p: 0 }}
                                                disableGutters
                                            >
                                                <ListItemIcon>
                                                    <OpenInNew />
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={{ width: "100%" }}
                                                    primary={link.text}
                                                    secondary={link.description}
                                                    secondaryTypographyProps={{ sx: { display: "block", maxWidth: "100%", width: "max-content" } }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </Collapse>
                            </List>
                        </Grid>
                        {files.length > 0 && (
                            <Grid item xs={4}>
                                <CardActionArea onClick={() => handleExpand("files")}>
                                    <Stack direction="row" alignItems={"center"}>
                                        <ExpandMore sx={{ rotate: cardContent === "files" ? "180deg" : "270deg", color: theme.palette.text.white }} />
                                        <Typography textAlign={"center"} variant="h6" color={theme.palette.text.white}>
                                            {expandHeaders.files}
                                        </Typography>
                                    </Stack>
                                </CardActionArea>
                                <List disablePadding>
                                    <Collapse in={cardContent === "files"}>
                                        {files.map((file, index) => (
                                            <ListItem key={`file_${index}`} disablePadding disableGutters>
                                                <ListItemButton
                                                    onClick={() => downloadFixedFile(file.id, file.filename)}
                                                    sx={{ width: "100%", p: 0 }}
                                                    disableGutters
                                                >
                                                    <ListItemIcon>
                                                        <Download />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{ width: "100%" }} primary={file.name} secondary={file.description} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </Collapse>
                                </List>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Container>
        </Paper>
    );
}

export default Footer;
