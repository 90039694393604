import { toast } from "react-toastify";
import { poster, fetcher, patcher, deleter } from "../../utils/axios.js";
import { addOrganisation, setOrganisations, removeOrganisation, updateOrganisation } from "../../store/slices/organisationsSlice.js";
import { Capitalize } from "../../functions/Formatters.js";
import { global } from "../../context/global.js";

/**
 * Creates a new organization, adds it to the Redux store and closes the dialog.
 * 
 * @param {Object} orgData - The data of the organization to be created.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {Function} closeDialog - The function to close the dialog.

 */
export const createNewOrg = (orgData, dispatch, closeDialog) => {
    poster("/organisations/", orgData).then((response) => {
        if (response?.status === 201) {
            toast.success(Capitalize(`${global.organisation} ${global.added}`));
            dispatch(addOrganisation(response.data));
            closeDialog();
        }
    });
};

/**
 * Fetches organisations from the server and dispatches the retrieved data to the provided dispatch function.
 * @param {function} dispatch - The dispatch function from Redux.
 */
export const fetchOrgs = (dispatch) => {
    fetcher("/organisations/").then((response) => {
        dispatch(setOrganisations(response.data));
    });
};

/**
 * Deletes an organization by its ID.
 * @param {string} id - The ID of the organization to delete.
 * @param {function} dispatch - The dispatch function from the Redux store.
 */
export const deleteOrg = (id, dispatch) => {
    deleter(`/organisations/${id}/`).then((response) => {
        if (response.status === 204) {
            dispatch(removeOrganisation(id));
        }
    });
};

/**
 * Updates an organisation by sending a PATCH request to the server.
 * @param {Object} data - The data to be sent in the request.
 * @param {Function} dispatch - The dispatch function from Redux.
 */
export const patchOrg = (data, dispatch) => {
    patcher(`/organisations/${data.id}/`, data).then((response) => {
        if (response.status === 200) {
            dispatch(updateOrganisation(response.data));
            toast.success("Wijzigingen opgeslagen");
        }
    });
};
