import React from "react";
import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

/**
 * Error page with link to homepage
 * @returns Error page component with link to homepage
 */
export default function ErrorPage() {
    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Niet gevonden
            </Typography>
            <Typography>Er is iets misgegaan.</Typography>
            <Typography>Klik {<Link to="/">hier</Link>} om terug te gaan naar de hoofdpagina.</Typography>
        </Container>
    );
}
