import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonGroup,
    IconButton,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    InputAdornment,
    FormControl,
    Grid,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import { Add, Delete, Save, ExpandMore, Cancel, ArrowUpward, ArrowDownward } from "@mui/icons-material";
import ConfirmDeleteDialog from "../../ConfirmDeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray } from "formik";
import { TextField, Select} from "formik-mui";
import { createOrUpdateProjectSubThemes, deleteProjectSubTheme } from "../ProjectActions";
import { global, importanceLevels, scoreLevels } from "../../../context/global";
import { goals2, goals as goalsText } from "../../../context/preparationPhase.js";
import InformationDialogue from "../../CustomComponents/InformationDialogue";
import StepperButtons from "../StepperButtons.js";
import { toast } from "react-toastify";
import CustomNumberfield from "../../CustomComponents/InputFields/CustomNumberfield.js";
import * as Yup from "yup";

export default function ProjectGoals({ payload }) {
    const dispatch = useDispatch();
    const projectThemes = payload.project.themes;
    const [minimumImportanceFilter, setMinimumImportanceFilter] = useState(3); // [0, 1, 2, 3]
    const filteredProjectThemes = projectThemes.filter((theme) => theme.importance == minimumImportanceFilter);
    const [subthemeToDelete, setSubthemeToDelete] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedKpisInGoal, setSelectedKpisInGoal] = useState({});

    // 0= "Begin",1= "Onderdelen selecteren",2= "Thema's selecteren",3="Doelen formuleren"4="Ontwerpen definiëren"
    payload.editable = payload.editable && (payload.project.status == 0 || payload.project.status == 3) ? true : false;

    const flatThemes = useSelector((state) => state.themes.flatThemes);
    const flatSubthemes = useSelector((state) => state.themes.flatSubThemes);
    const flatKpis = useSelector((state) => state.themes.flatKpis);

    const [expanded, setExpanded] = useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCreateOrUpdateProjectSubThemes = (subthemes, setSubmitting) => {
        if (subthemes.length > 0) {
            createOrUpdateProjectSubThemes(subthemes, dispatch);
        }
        setSubmitting(false);
    };

    const handleClickDeleteSubtheme = (subtheme) => {
        setSubthemeToDelete(subtheme);
        setDeleteDialogOpen(true);
    };

    const newProjectSubTheme = (projectTheme) => {
        const subThemes = flatThemes[projectTheme.theme]?.subthemes;
        return {
            project_theme: projectTheme.id,
            importance: projectTheme.importance,
            description: "",
            subtheme: Object.values(subThemes).length === 1 ? Object.values(subThemes)[0].id : "",
            goals: [],
        };
    };

    const newProjectGoal = (projectSubtheme) => {
        return {
            project_subtheme: projectSubtheme.id,
            kpi: "",
            description: "",
            value: "",
        };
    };

    if (payload.project.status < 2) {
        return <Typography>Vergrendel de thema's om doelen toe te kunnen voegen.</Typography>;
    } else
        return (
            <>
                <ConfirmDeleteDialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        deleteProjectSubTheme(subthemeToDelete?.id, subthemeToDelete?.project_theme, dispatch);
                        setDeleteDialogOpen(false);
                    }}
                    dialogTitle={`Aspect verwijderen?`}
                    dialogContentText={`Weet u zeker dat u dit aspect wilt verwijderen?`}
                />
                <Stack spacing={1}>
                    <InformationDialogue text={goalsText} />
                    <Typography fontStyle={"italic"}>{goals2}</Typography>

                    {projectThemes.length > 0 && (
                        <>
                        <FormControl>
                            <FormLabel>Niveau</FormLabel>
                            <RadioGroup
                                value={minimumImportanceFilter}
                                onChange={(e) => setMinimumImportanceFilter(e.target.value)}
                                row
                            >
                                {importanceLevels.map((level) => (
                                    <FormControlLabel
                                        key={level.value}
                                        value={level.value}
                                        control={<Radio />}
                                        label={level.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        </>
                    )}
                    {filteredProjectThemes.length > 0 ? (
                        filteredProjectThemes.map((projecttheme, projectThemeIndex) => (
                            <Accordion key={projecttheme.id} expanded={expanded === projectThemeIndex} onChange={handleChange(projectThemeIndex)}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Stack>
                                        <Typography variant="h6">{flatThemes[projecttheme.theme]?.name}</Typography>
                                        <Typography>Niveau: {importanceLevels[projecttheme.importance].label}</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{projecttheme.description}</Typography>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            subthemes: projecttheme.subthemes || [],
                                            isValid: true,
                                        }}
                                        validationSchema={Yup.object({
                                            subthemes: Yup.array().of(
                                                Yup.object().shape({
                                                    subtheme: Yup.number().required("Aspect is verplicht"),
                                                    goals: Yup.array().of(
                                                        Yup.object().shape({
                                                            kpi: Yup.number().required("KPI is verplicht"),
                                                            value: Yup.string().required("Waarde is verplicht"),
                                                        })
                                                    ),
                                                })
                                            ),
                                            isValid: Yup.boolean().required(),
                                        
                                        })}
                                        
                                        onSubmit={(values, { setSubmitting }) => {
                                            if (values.isValid) handleCreateOrUpdateProjectSubThemes(values.subthemes, setSubmitting);
                                            else {
                                                {
                                                    toast.error("Er zijn ongeldige waarden ingevuld");
                                                    setSubmitting(false);
                                                }
                                            }
                                        }}
                                    >
                                        {({ values, isSubmitting, setFieldValue, handleSubmit, isValid, errors }) => (
                                            <Form>
                                                {values.subthemes?.length > 0 ? (
                                                    <TableContainer>
                                                        <Table size="small" padding="none">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell padding="checkbox">Aspect</TableCell>
                                                                    {/* <TableCell>Niveau</TableCell> */}
                                                                    <TableCell>Toelichting</TableCell>
                                                                    <TableCell>Doelen</TableCell>
                                                                    {/* <TableCell>Waarde</TableCell> */}
                                                                    <TableCell>Acties</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <FieldArray
                                                                    name="subthemes"
                                                                    render={(arrayHelpers) => {
                                                                        const chosenSubthemeIDs = values.subthemes.map((projectSubTheme) => projectSubTheme.subtheme);
                                                                        const availableSubthemes = flatThemes[projecttheme.theme]?.subthemes.filter((subtheme) => !chosenSubthemeIDs.includes(subtheme.id.toString()));
                                                                        return (
                                                                        <>
                                                                            {values.subthemes.map((projectsubtheme, subthemeIndex) => (
                                                                                <TableRow key={subthemeIndex} sx={{ verticalAlign: "top" }}>
                                                                                    <TableCell padding="checkbox">
                                                                                        <Field
                                                                                            size="small"
                                                                                            component={Select}
                                                                                            name={`subthemes.${subthemeIndex}.subtheme`}
                                                                                            inputProps={{
                                                                                                id: `subthemes.${subthemeIndex}.subtheme`,
                                                                                            }}
                                                                                            autoWidth={true}
                                                                                            sx={{
                                                                                                minWidth: 200,
                                                                                                display: "flex",
                                                                                            }}
                                                                                            disabled={!payload.editable}
                                                                                        >
                                                                                            {[...availableSubthemes, flatSubthemes[projectsubtheme.subtheme]].map((subtheme) => (
                                                                                                <MenuItem key={subtheme?.id} value={subtheme?.id}>
                                                                                                    {subtheme?.name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </TableCell>

                                                                                    <TableCell sx={{ minWidth: 400 }}>
                                                                                        <Field
                                                                                            component={TextField}
                                                                                            name={`subthemes.${subthemeIndex}.description`}
                                                                                            inputProps={{
                                                                                                id: `subthemes.${subthemeIndex}.description`,
                                                                                            }}
                                                                                            multiline
                                                                                            maxRows={6}
                                                                                            fullWidth
                                                                                            disabled={!payload.editable}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell sx={{ minWidth: 500 }}>
                                                                                        <Grid container spacing={0} justifyContent={"end"}>
                                                                                            <FieldArray
                                                                                                name={`subthemes.${subthemeIndex}.goals`}
                                                                                                render={(arrayHelpers) => {

                                                                                                    return (
                                                                                                    <>
                                                                                                        {projectsubtheme.goals.map((goal, goalIndex) => {
                                                                                                            const isValid = (subtheme, goalIndex, value) => {
                                                                                                                let valid = true;
                                                                                                                for (let key in selectedKpisInGoal[subtheme]) {
                                                                                                                    if (
                                                                                                                        Number(key) !== Number(goalIndex) &&
                                                                                                                        selectedKpisInGoal[subtheme][key] === value
                                                                                                                    ) {
                                                                                                                        toast.error("Dubbele KPI's zijn niet toegestaan");
                                                                                                                        valid = false;
                                                                                                                    } else if (
                                                                                                                        Number(key) === Number(goalIndex) &&
                                                                                                                        selectedKpisInGoal[subtheme][goalIndex] !== value
                                                                                                                    ) {
                                                                                                                        addValueToObject(subtheme, goalIndex, value);
                                                                                                                    }
                                                                                                                }
                                                                                                                return valid;
                                                                                                            };

                                                                                                            const addValueToObject = (subtheme, goalIndex, value) => {
                                                                                                                setSelectedKpisInGoal({
                                                                                                                    ...selectedKpisInGoal,
                                                                                                                    [subtheme]: selectedKpisInGoal[subtheme]
                                                                                                                        ? {
                                                                                                                              ...selectedKpisInGoal[subtheme],
                                                                                                                              [goalIndex]: value,
                                                                                                                          }
                                                                                                                        : { [goalIndex]: value },
                                                                                                                });
                                                                                                            };
                                                                                                            const chosenKpiIDs = projectsubtheme.goals.map((goal) => goal.kpi);
                                                                                                            const availableKpis = flatSubthemes[projectsubtheme.subtheme]?.kpis.filter((kpi) => !chosenKpiIDs.includes(kpi.id.toString())) || [];

                                                                                                            return (
                                                                                                                <>
                                                                                                                    <Grid item xs={goal.kpi ? 6 : 11} alignItems={"end"}>
                                                                                                                        <Field
                                                                                                                            // label="KPI"
                                                                                                                            size="small"
                                                                                                                            component={Select}
                                                                                                                            name={`subthemes.${subthemeIndex}.goals.${goalIndex}.kpi`}
                                                                                                                            inputProps={{
                                                                                                                                id: `subthemes.${subthemeIndex}.goals.${goalIndex}.kpi`,
                                                                                                                            }}
                                                                                                                            sx={{ width: 250 }}
                                                                                                                            disabled={!payload.editable}
                                                                                                                            onBlur={(event) => {
                                                                                                                                if (!isValid(subthemeIndex, goalIndex, Number(event.target.value))) {
                                                                                                                                    console.log(selectedKpisInGoal);
                                                                                                                                    setFieldValue(
                                                                                                                                        `subthemes.${subthemeIndex}.goals.${goalIndex}.kpi`,
                                                                                                                                        ""
                                                                                                                                    );
                                                                                                                                } else {
                                                                                                                                    addValueToObject(
                                                                                                                                        subthemeIndex,
                                                                                                                                        goalIndex,
                                                                                                                                        Number(event.target.value)
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            
                                                                                                                            
                                                                                                                        >
                                                                                                                            {[...availableKpis, flatKpis[projectsubtheme.goals[goalIndex].kpi]].map((kpi) => (
                                                                                                                                <MenuItem key={kpi?.id} value={kpi?.id}>
                                                                                                                                    {kpi?.name} {kpi?.unit !== undefined ? `(${kpi?.unit || "punten"})` : null}
                                                                                                                                </MenuItem>
                                                                                                                            ))}
                                                                                                                        </Field>
                                                                                                                    </Grid>
                                                                                                                    {goal.kpi &&
                                                                                                                        (flatKpis[values.subthemes[subthemeIndex].goals[goalIndex].kpi].scale_type ===
                                                                                                                        0 ? (
                                                                                                                            <Grid item xs={5} sx={{ width: 200 }}>
                                                                                                                                <Field
                                                                                                                                    size="small"
                                                                                                                                    component={Select}
                                                                                                                                    name={`subthemes.${subthemeIndex}.goals.${goalIndex}.value`}
                                                                                                                                    inputProps={{
                                                                                                                                        id: `subthemes.${subthemeIndex}.goals.${goalIndex}.value`,
                                                                                                                                    }}
                                                                                                                                    sx={{ width: 208 }}
                                                                                                                                >
                                                                                                                                    {scoreLevels.map((option) => (
                                                                                                                                        <MenuItem key={option.label} value={option.value}>
                                                                                                                                            {option.value}. {option.label}
                                                                                                                                        </MenuItem>
                                                                                                                                    ))}
                                                                                                                                </Field>
                                                                                                                            </Grid>
                                                                                                                        ) : (
                                                                                                                            <Grid item xs={5}>
                                                                                                                                <Field
                                                                                                                                    size="small"
                                                                                                                                    component={CustomNumberfield}
                                                                                                                                    name={`subthemes.${subthemeIndex}.goals.${goalIndex}.value`}
                                                                                                                                    id={`subthemes.${subthemeIndex}.goals.${goalIndex}.value`}
                                                                                                                                    unit={
                                                                                                                                        flatKpis[values.subthemes[subthemeIndex].goals[goalIndex].kpi]
                                                                                                                                            .unit
                                                                                                                                    }
                                                                                                                                    
                                                                                                                                    startAdornment={
                                                                                                                                        <InputAdornment position="start">
                                                                                                                                            {flatKpis[
                                                                                                                                                values.subthemes[subthemeIndex].goals[goalIndex].kpi
                                                                                                                                            ].maximize
                                                                                                                                                ? "≥"
                                                                                                                                                : "≤"}
                                                                                                                                        </InputAdornment>
                                                                                                                                    }
                                                                                                                                    disabled={!payload.editable}
                                                                                                                                    fullWidth={false}
                                                                                                                                    error={errors.subthemes?.[subthemeIndex]?.goals?.[goalIndex]?.value}
                                                                                                                                    helperText={errors.subthemes?.[subthemeIndex]?.goals?.[goalIndex]?.value}
                                                                                                                                    
                                                                                                                                />
                                                                                                                                
                                                                                                                            </Grid>
                                                                                                                        ))}

                                                                                                                    <Grid item xs={1}>
                                                                                                                        <IconButton
                                                                                                                            onClick={() => arrayHelpers.remove(goalIndex)}
                                                                                                                            disabled={!payload.editable || isSubmitting}
                                                                                                                        >
                                                                                                                            <Cancel />
                                                                                                                        </IconButton>
                                                                                                                    </Grid>
                                                                                                                </>
                                                                                                            );
                                                                                                        })}
                                                                                                        {projectsubtheme.goals.length <
                                                                                                            flatThemes[payload.project.themes.find((theme) => theme.id === projecttheme.id).theme]
                                                                                                                .subthemes[0]?.kpis?.length && (
                                                                                                            <Grid item xs={12}>
                                                                                                                <Button
                                                                                                                    startIcon={<Add />}
                                                                                                                    onClick={() => {
                                                                                                                        arrayHelpers.push(newProjectGoal(projectsubtheme));
                                                                                                                    }}
                                                                                                                    disabled={!payload.editable || isSubmitting}
                                                                                                                >
                                                                                                                    {global.add}
                                                                                                                </Button>
                                                                                                            </Grid>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}}
                                                                                            />
                                                                                        </Grid>
                                                                                    </TableCell>
                                                                                    <TableCell width={"5%"}>
                                                                                        <ButtonGroup>
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    handleClickDeleteSubtheme(projectsubtheme);
                                                                                                    arrayHelpers.remove(subthemeIndex);
                                                                                                }}
                                                                                                disabled={isSubmitting || !payload.editable}
                                                                                            >
                                                                                                <Tooltip placement="right-end" title={"Aspect verwijderen"}>
                                                                                                    <Delete />
                                                                                                </Tooltip>
                                                                                            </IconButton>
                                                                                        </ButtonGroup>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </>
                                                                    )}}
                                                                />
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                ) : (
                                                    <Typography>{flatThemes[projecttheme.theme]?.subthemes?.length === 0 ? "Er zijn geen aspecten of KPI's voor dit thema." : ""}</Typography>
                                                )}
                                                <ButtonGroup variant="outlined">
                                                    {values.subthemes.length !== flatThemes[projecttheme.theme]?.subthemes?.length && (
                                                        <Button
                                                            startIcon={<Add />}
                                                            onClick={() => {
                                                                setFieldValue("subthemes", [...values.subthemes, newProjectSubTheme(projecttheme)]);
                                                            }}
                                                            disabled={isSubmitting || !payload.editable}
                                                        >
                                                            {global.subtheme} {global.add}
                                                        </Button>
                                                    )}
                                                    {payload.editable && <Button type="submit" startIcon={<Save />} disabled={isSubmitting}>
                                                        {/* {global.save} */}
                                                    </Button>}
                                                    {projectThemeIndex > 0 && (
                                                        <Button
                                                            onClick={() => {
                                                                if (payload.editable) {
                                                                    handleSubmit();
                                                                }
                                                                setExpanded(Math.max(projectThemeIndex - 1, 0));
                                                            }}
                                                            startIcon={<ArrowUpward />}
                                                            disabled={isSubmitting || !isValid}
                                                        >
                                                            {payload.editable ? `${global.save} ${global.and} ` : ""} {global.previous} {global.theme}
                                                        </Button>
                                                    )}
                                                    {projectThemeIndex < filteredProjectThemes.length - 1 && 
                                                    <Button
                                                        onClick={() => {
                                                            if (payload.editable) {
                                                                handleSubmit();
                                                            }
                                                            setExpanded(Math.min(projectThemeIndex + 1, filteredProjectThemes.length - 1));
                                                        }}
                                                        endIcon={projectThemeIndex < filteredProjectThemes.length - 1 && <ArrowDownward />}
                                                        disabled={isSubmitting || !isValid}
                                                    >
                                                        {payload.editable && `${global.save} ${global.and} `} {`${global.next} ${global.theme}`}
                                                    </Button>
                                                    }
                                                </ButtonGroup>
                                            </Form>
                                        )}
                                    </Formik>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Typography>
                            {projectThemes.length === 0 ? "Er zijn nog geen thema's toegevoegd aan dit project." : "Er zijn geen thema's van het geselecteerde niveau."}
                        </Typography>
                    )}
                    <StepperButtons payload={payload} updateStatus={false} warningPopup={payload.project.status <= 3} />
                </Stack>
            </>
        );
}
