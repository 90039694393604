import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { changePassword } from "./UserActions";
import { Button, ButtonGroup, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

/**
 * ChangePasswordForm Component
 *
 * This React component provides a form for changing a user's password. It allows the user to enter their old password
 * and their new password. The component includes client-side validation for password strength and matching new
 * password entries. When submitted, it calls the 'changePassword' function to update the user's password.
 *
 * @component
 * @param {number} userID - The ID of the user whose password will be changed.
 * @param {function} handleClose - A callback function to close the password change dialog.
 */
export default function ChangePasswordForm({ userID, handleClose }) {
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Configuration for form fields
    const fields = [
        {
            fieldName: "old_password",
            label: "Oud wachtwoord",
        },
        {
            fieldName: "new_password1",
            label: "Nieuw wachtwoord",
        },
        {
            fieldName: "new_password2",
            label: "Herhaal nieuw wachtwoord",
        },
    ];

    return (
        <Formik
            initialValues={{
                old_password: "",
                new_password1: "",
                new_password2: "",
            }}
            validationSchema={Yup.object({
                old_password: Yup.string().required("Dit veld is vereist"),
                new_password1: Yup.string()
                    .required("Dit veld is vereist")
                    .matches(
                        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        "Wachtwoord moet minimaal 8 tekens lang zijn en minimaal 1 hoofdletter en 1 cijfer bevatten"
                    ),
                new_password2: Yup.string()
                    .oneOf([Yup.ref("new_password1")], "Wachtwoorden komen niet overeen")
                    .required("Dit veld is vereist"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                // Call the 'changePassword' function to update the user's password
                changePassword(userID, values, setSubmitting, handleClose);
            }}
        >
            {({ isSubmitting, isValid, touched }) => (
                <Form>
                    <Stack spacing={1}>
                        <Typography variant="h6">Wachtwoord wijzigen</Typography>
                        {fields.map((field) => (
                            <Field
                                key={field.fieldName}
                                sx={{ maxWidth: 300 }}
                                component={TextField}
                                name={field.fieldName}
                                type={passwordVisible ? "text" : "password"}
                                label={field.label}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    autoComplete: "new-password",
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    ),
                                }}
                            />
                        ))}
                    </Stack>
                    <ButtonGroup variant="contained" color="primary">
                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || !isValid || !touched}>
                            Wijzig wachtwoord
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    );
}
