import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { registerNewUser, addUserToOrg } from "./UserActions";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, MenuItem, FormControlLabel } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Checkbox, Select, TextField } from "formik-mui";
import { global, profilePage } from "../../context/global";
import { Capitalize } from "../../functions/Formatters";

/**
 * NewUserDialog Component
 *
 * This React component provides a dialog for creating a new user. Users can be added to an organization (optional),
 * assigned as an admin for the organization (optional), and registered for the application.
 *
 * @component
 * @param {boolean} open - Controls the visibility of the dialog.
 * @param {function} handleClose - A callback function to close the dialog.
 * @param {number} selectedOrganisationId - The ID of the selected organization for adding the new user (optional).
 */
export default function NewUserDialog({ open, handleClose, selectedOrganisationId = null }) {
    const dispatch = useDispatch();
    const organisations = useSelector((state) => state.organisations.organisations);
    const currentUser = useSelector((state) => state.auth.account);
    const currentUserId = currentUser.id;

    const managedOrganisations = currentUser.is_staff ? organisations : organisations.filter((org) => org.admins.some((admin) => admin.id === currentUserId));

    const orgDefault = organisations.length === 1 ? organisations[0].id : selectedOrganisationId || false;

    const handleCreateUser = (email, name, organisation, admin) => {
        // Depending on the selected organization, either register the user for the application or add them to an organization (which also adds the user).
        if (!organisation) {
            registerNewUser({ email: email, name: name }, dispatch, handleClose);
        } else {
            addUserToOrg({ email: email, name: name }, organisation, admin, dispatch, handleClose);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby={"new_user_dialog"} sx={{ minWidth: "400px" }}>
            <Formik
                initialValues={{
                    email: "",
                    name: "",
                    organisation: orgDefault,
                    admin: false,
                    employer: "",
                }}
                validationSchema={Yup.object({
                    email: Yup.string().trim().email("Geen valide email adres").required("Email is vereist"),
                    name: Yup.string().trim().required("Naam is vereist"),
                })}
                onSubmit={(values) => {
                    handleCreateUser(values.email, values.name, values.organisation, values.admin);
                }}
            >
                {({ isValid, values, setFieldValue }) => (
                    <Form>
                        <DialogTitle id={"new_user_dialog"}>
                            Gebruiker toevoegen aan{" "}
                            {values.organisation === false ? "applicatie" : organisations.find((org) => org.id == values.organisation)?.name}
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={1} sx={{ pt: 1 }}>
                                <Field
                                    component={TextField}
                                    name="name"
                                    label="Naam"
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                    autoFocus
                                />
                                <Field
                                    component={TextField}
                                    name="email"
                                    label="Email"
                                    type="email"
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                />
                                {organisations.length > 1 && (
                                    <Field
                                        component={Select}
                                        name="organisation"
                                        type="select"
                                        label={Capitalize(global.organisation)}
                                        disabled={organisations.length === 1}
                                        onChange={(e) => {
                                            setFieldValue("employer", organisations.find((org) => org.id === e.target.value)?.name || "");
                                        }}
                                    >
                                        <MenuItem key={"none"} value={false}>
                                            Geen {global.organisation}
                                        </MenuItem>
                                        {managedOrganisations.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                )}
                                <Field
                                    component={TextField}
                                    name="employer"
                                    label={profilePage.employer}
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                />
                                {values.organisation && (
                                    <>
                                        <FormControlLabel
                                            control={<Field component={Checkbox} name="admin" type="checkbox" />}
                                            label={`Maak ${global.admin} van ${global.organisation}`}
                                        />
                                    </>
                                )}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                                Gebruiker toevoegen
                            </Button>
                            <Button onClick={() => handleClose()} color="primary">
                                Annuleren
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
