export function SortObjects(objects, sortKey, sortDirection = "asc", sortType = "string") {
    if (!objects) {
        return null;
    }
    if (!sortKey) {
        return objects;
    }
    const sorted = objects.sort((a, b) => {
        if (!a[sortKey]) {
            a = { ...a, [sortKey]: "" };
        }
        if (!b[sortKey]) {
            b = { ...b, [sortKey]: "" };
        }

        if (sortType === "string") {
            if (sortDirection === "asc") {
                return a[sortKey].localeCompare(b[sortKey]);
            } else {
                return b[sortKey].localeCompare(a[sortKey]);
            }
        } else if (sortType === "number") {
            if (sortDirection === "asc") {
                return a[sortKey] - b[sortKey];
            } else {
                return b[sortKey] - a[sortKey];
            }
        }
    });
    return sorted;
}
