import React, { useState } from "react";

import { Menu, MenuItem, IconButton, Toolbar, Typography, AppBar, Box, Stack, Button, Fab, useMediaQuery } from "@mui/material";
import { AccountCircle, Logout, Menu as MenuIcon, Person, QuestionMark } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { fetchAllObjects, handleLogout } from "../store/storeFunc";
import { fetcher } from "../utils/axios";
import { updateProject } from "../store/slices/projectsSlice";
import { Capitalize, Pluralize } from "../functions/Formatters";
import WelcomeDialog from "./WelcomeDialog";
import { setWelcomeState } from "./UserManagement/UserActions";
import StyledLink from "./CustomComponents/StyledLink";
import { appName, global } from "../context/global";
import { titleBar } from "../context/titleBar";

/**
 * TitleBar Component
 *
 * This React component represents the title bar or header of the application. It displays
 * navigation links, a logo, and user-related options, such as user profile and logout.
 * The appearance and behavior of the title bar adapt based on the user's authentication status
 * and screen size.
 *
 * @component
 */
export default function TitleBar() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Breakpoints to hide components based on viewport size
    const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
    const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));

    const auth = useSelector((state) => state.auth);
    const isLoggedIn = auth.account ? true : false;

    const organisations = useSelector((state) => state.organisations.organisations);
    // Handler for welcome dialog
    const handleSetWelcomeState = (open) => {
        setWelcomeState(open, dispatch);
    };

    const selectedProject = useSelector((state) => state.projects.selectedProject);
    const fetchSelectedProject = () => {
        if (selectedProject?.id)
            [
                fetcher(`/projects/${selectedProject.id}/`).then((response) => {
                    dispatch(updateProject(response.data));
                }),
            ];
    };
    const handleClickRefreshAll = () => {
        fetchAllObjects(dispatch);
        fetchSelectedProject();
    };

    //  State and handlers for navigation menu
    const [anchorElNav, setAnchorElNav] = useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    //  State and handlers for user menu
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // Handlers for user menu items
    const handleClickProfile = () => {
        handleCloseUserMenu();
        navigate("/profile");
    };

    const handleClickLogout = () => {
        handleCloseUserMenu();
        handleLogout(dispatch);
    };

    // Array of navigation links. Is defined here so it can be used in both the navigation menu and the title bar, depending on screen size.
    const LinkArr = [
        {
            text: titleBar.projects,
            to: "/",
            requiredRole: isLoggedIn && auth.account.is_org_member,
        },
        {
            text: `${Capitalize(global.my)} ${Pluralize(global.organisation, organisations.length)}`,
            to: "/organisation",
            requiredRole: isLoggedIn && auth.account.is_org_member,
        },
        {
            text: titleBar.admin,
            to: "/admin",
            requiredRole: isLoggedIn && auth.account.is_staff,
        },
        {
            text: titleBar.themes,
            to: "/themes",
            requiredRole: isLoggedIn && !auth.account.is_staff,
        },
    ];

    return (
        <>
            <WelcomeDialog setOpen={() => handleSetWelcomeState(false)} />
            <Fab
                size="small"
                onClick={() => {
                    handleSetWelcomeState(true);
                }}
                sx={{ position: "fixed", bottom: 80, right: "1rem" }}
                color="primary"
                aria-label="add"
            >
                <QuestionMark fontSize="large" />
            </Fab>
            <Box sx={{ top: 0, height: 100, position: "sticky", zIndex: 50 }}>
                <AppBar sx={{ backgroundColor: theme.palette.primary.main, p: 0 }}>
                    <Toolbar disableGutters>
                        <Box sx={{ mr: 4, pr: 1, pl: 1, backgroundColor: theme.palette.background.white }}>
                            <Button onClick={() => navigate("/")} sx={{ p: 0 }}>
                                <img src="hwbp-logo.svg" alt="HWBP Logo" height={80} />
                            </Button>
                        </Box>
                        {isLoggedIn && ( // Only show navigation links if the user is logged in
                            <>
                                {matchesLG ? ( // If the screen size is large, show the links in the title bar. Otherwise, show a menu button that opens a navigation menu.
                                    <Stack direction="row" spacing={2} alignItems={"center"}>
                                        {LinkArr.filter((item) => item.requiredRole === true).map((item, index) => (
                                            <StyledLink key={index} {...item} inMenuItem={false} />
                                        ))}
                                    </Stack>
                                ) : (
                                    <>
                                        <IconButton onClick={handleOpenNavMenu} color="inherit">
                                            <MenuIcon fontSize="large" />
                                        </IconButton>
                                        <Menu
                                            id="menu-navigation"
                                            anchorEl={anchorElNav}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}
                                        >
                                            {LinkArr.filter((item) => item.requiredRole === true).map((item, index) => (
                                                <MenuItem key={index} onClick={handleCloseNavMenu}>
                                                    <StyledLink {...item} inMenuItem={true} />
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </>
                                )}
                            </>
                        )}

                        <Typography
                            component={Button}
                            onClick={() => {
                                isLoggedIn ? handleClickRefreshAll() : navigate("/");
                            }}
                            // onClick={() => navigate("/")} (original code. I changed it to the above line to make the logo clickable)
                            variant="h3"
                            sx={{
                                flexGrow: 1,
                                margin: "auto",
                                textTransform: "none",
                                fontWeight: 650,
                                fontSize: "2.6rem",
                                color: theme.palette.text.white,
                            }}
                        >
                            {matchesSM ? appName.full : appName.abbreviated}
                        </Typography>

                        {isLoggedIn && (
                            <Stack direction="row" spacing={2} alignItems={"center"}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenUserMenu}
                                    color="inherit"
                                >
                                    {matchesLG && (
                                        <div style={{ mr: 2 }}>
                                            <Typography>{titleBar.loggedInAs}</Typography>
                                            <Typography>{auth.account.name}</Typography>
                                        </div>
                                    )}
                                    <AccountCircle sx={{ ml: 2 }} fontSize="large" />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleClickProfile}>
                                        <Person />
                                        {titleBar.profile}
                                    </MenuItem>
                                    <MenuItem onClick={handleClickLogout}>
                                        <Logout />
                                        {titleBar.logout}
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
}
