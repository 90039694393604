import React from "react";
import { MenuItem, Grid, Button, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SubthemeOverview from "./SubthemeOverview";
import SingleThemePane from "./ThemeInformationPane";
import { ArrowBack } from "@mui/icons-material";
import { BootstrapInput } from "../CustomComponents/StyledInputBase";

/**
 * Component for displaying details of a theme and its subthemes.
 */
export default function ThemeDetails() {
    const navigate = useNavigate();
    const { themeID } = useParams();

    // Get the selected theme and list of themes from the Redux store.
    const themes = useSelector((state) => state.themes.themes);
    const selectedTheme = themes.find((theme) => Number(theme.id) === Number(themeID));

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12} md={5} lg={4}>
                    <>
                        <Select
                            id="select-theme"
                            value={themeID}
                            onChange={(e) => navigate(`../${e.target.value}`)}
                            input={<BootstrapInput />}
                        >
                            <MenuItem value={"."}>
                                <ArrowBack />
                                Terug naar overzicht
                            </MenuItem>
                            {themes.map((theme) => (
                                <MenuItem key={theme.id} value={theme.id}>
                                    {theme.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button variant="outlined" onClick={() => navigate("..")}>
                            <ArrowBack />
                            Terug
                        </Button>

                        <SingleThemePane theme={selectedTheme} />
                    </>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                    <SubthemeOverview />
                </Grid>
            </Grid>
        </>
    );
}
