export const advice = {
    title: "Adviezen",
    fullText: [
        `In deze stap kan door experts advies worden aangegeven hoe de verschillende duurzaamheidsthema’s mee kunnen worden genomen in het ontwerpproces. Dit kunnen ontwerpprincipes of handvaten zijn, of juist projectspecifieke kansen om invulling te geven aan dit thema.`,
        `Voor inspiratie qua principes, kan worden geklikt op de knop ‘Ontwerpprincipes’. Hier staan diverse ontwerpprincipes per duurzaamheidsthema die als inspiratie kunnen worden gebruikt. `,
        `Daarnaast is het mogelijk om per advies ook documenten ter onderbouwing aan te leveren. Denk bijvoorbeeld aan kaarten van de omgeving of voorbeelden uit andere projecten.`,
    ],
};

export const score = {
    title: "Ontwerp score",
    fullText: [
        `De ontwerpen die zijn gemaakt moeten per duurzaamheidsthema worden getoetst.`,
        `Voeg als eerste de ontwerpen toe die in deze ontwerploop zijn gemaakt. Let hierbij op de naamgeving en omschrijving, zodat duidelijk is wat er wordt bedoeld met het betreffende ontwerp.`,
        `Vervolgens kan per duurzaamheidsthema worden aangegeven hoe de ontwerpen scoren. Geef waar mogelijk ook een korte onderbouwing of toelichting voor de score. `,
    ],
};
export const relativeScoreWarning = "Het is vaak niet mogelijk om een exacte score in te vullen voor KPI's met eenheid \"%\" voor het referentie ontwerp. U kunt in dit geval het beste uitgaan van het worst-case scenario. Dit is meestal 100% of 0%. "

export const compare = {
    title: "Ontwerpen vergelijken",
    fullText: [
        "Vergelijk hier de ontwerpen per duurzaamheidsthema. Voor de thema’s die horen bij Klimaat & Energie en Circulariteit is het ook mogelijk om te zien in welke mate de verschillende onderdelen en dijkvakken bijdragen aan de totale score. ",
    ],
};

export const discussion = {
    title: "Ontwerpen bespreken",
    fullText: [
        "Hier wordt een overzicht weergegeven van alle ontwerp informatie, om makkelijk de verschillende aspecten te kunnen bespreken.",
    ],
};
