import React, { useState } from "react";
import {
    Typography,
    ButtonGroup,
    Button,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    Table,
    FormControlLabel,
    Stack,
    Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { scoreLevels } from "../../../../context/global";
import { Field, Form, Formik } from "formik";
import { TextField, Checkbox } from "formik-mui";
import { EuroFormat, NumberFormat } from "../../../../functions/Formatters";
import { patchMultipleDesignsAndAddLoop } from "../DesignActions";
import { Check, HelpOutline, ThumbDown, ThumbUp } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import StepperButtons from "../../StepperButtons";
import InformationDialogue from "../../../CustomComponents/InformationDialogue";
import { discussion } from "../../../../context/designPhase";

export default function DesignDiscussion({ handleAddDesignLoop, handleAdvancePhase, payload }) {
    const dispatch = useDispatch();
    const project = useSelector((state) => state.projects.selectedProject);
    const referenceDesign = project.reference_design;
    let designs = referenceDesign ? [referenceDesign, ...payload.loop.designs] : payload.loop.designs;
    const styling = useTheme();

    payload.editable = payload.loop === payload.project.phases.slice(-1)[0].loops.slice(-1)[0] ? payload.editable : false;

    const flatThemes = useSelector((state) => state.themes.flatThemes);
    const flatSubThemes = useSelector((state) => state.themes.flatSubThemes);
    const flatKpis = useSelector((state) => state.themes.flatKpis);

    //This is the design that is preferred to continue in the next phase
    const [preferredDesign, setPreferredDesign] = useState(undefined);

    const advancePhase = () => {
        handleAdvancePhase(designs.find((design) => design.id === preferredDesign));
    };
    const addLoop = (values) => {
        patchMultipleDesignsAndAddLoop(values, project.id, payload.phase.id, payload.loop.id, dispatch, handleAddDesignLoop);
    };

    const formatSwitcher = (value, unit) => {
        if (unit === "€") {
            return EuroFormat(value);
        } else {
            return `${NumberFormat(value)} ${unit}`;
        }
    };
    const sizeProps = { sx: { p: 0, m: 0, ml: 1 }, fontSize: "small" };
    const CheckIcon = ({ hoverText }) => (
        <Tooltip title={hoverText}>
            <Check {...sizeProps} color="warning" />
        </Tooltip>
    );
    const GoodIcon = ({ hoverText }) => (
        <Tooltip title={hoverText}>
            <ThumbUp {...sizeProps} color="success" />
        </Tooltip>
    );
    const BadIcon = ({ hoverText }) => (
        <Tooltip title={hoverText}>
            <ThumbDown {...sizeProps} color="error" />
        </Tooltip>
    );

    /**
     * Renders an icon based on the provided diff value.
     * @param {number} diff - The diff value.
     * @param {string} [hoverText=""] - The hover text for the icon.
     * @returns {JSX.Element|null} The rendered icon component.
     */
    const DiffSign = (diff, hoverText = "") => {
        switch (diff) {
            case 0:
                // return <SentimentNeutral {...sizeProps} color="warning" />;
                return <GoodIcon hoverText={hoverText} />;
            case 1:
                // return <SentimentSatisfied {...sizeProps} color="success" />;
                return <GoodIcon hoverText={hoverText} />;
            case 2:
                // return <SentimentSatisfiedAlt {...sizeProps} color="success" />;
                return (
                    <>
                        {/* <GoodIcon /> */}
                        <GoodIcon hoverText={hoverText} />
                        <GoodIcon hoverText={hoverText} />
                        
                    </>
                );
            case -1:
                // return <SentimentDissatisfied {...sizeProps} color="error" />;
                return <BadIcon hoverText={hoverText} />;
            case -2:
                // return <SentimentVeryDissatisfied {...sizeProps} color="error" />;
                return (
                    <>
                        {/* <BadIcon /> */}
                        <BadIcon hoverText={hoverText} />
                        <BadIcon hoverText={hoverText} />
                    </>
                );
            default:
                return null;
        }
    };

    const formattedTotalScore = (score, kpi, compareTo) => {
        let diff = null;
        let label = "";
        let hoverText = "";
        if (score?.value === null) {
            return "Geen score gevonden";
        }
        if (kpi.qualitative_scale || kpi.scale_type === 0) {
            if (compareTo !== undefined) {
                diff = score.value - compareTo;
            }
            label = scoreLevels[score.value - 1] ? scoreLevels[score.value - 1].label : "Geen score";
        } else {
            // return null
            let total = 0;
            if (kpi.scale_type === 1) {
                if (score.value instanceof Array) {
                    try {
                        total = score.value.reduce((acc, segment) => acc + segment.values.reduce((acc, layer) => acc + Number(layer.value), 0), 0);
                    } catch {
                        console.log("Error in calculating total score for", kpi.name);
                        total = 0;
                    }
                }
                if (!isNaN(Number(score.value))) {
                    total = Number(score.value);
                }
            }
            if (kpi.scale_type === 2) {
                total = Number(score.value);
            }
            if (compareTo !== undefined) {
                const deltaAbsolute = total - compareTo;
                const deltaRelative = (100 * Math.abs(deltaAbsolute)) / compareTo;
                const compareWord = deltaAbsolute > 0 ? "hoger" : "lager";
                const compareSign = deltaAbsolute > 0 ? "+" : "-";
                const unit = kpi.unit === "%" ? "procentpunten" : kpi.unit;
                hoverText = `Deze score is ${formatSwitcher(Math.abs(deltaAbsolute), unit)} ${compareWord} dan het doel van ${formatSwitcher(
                    compareTo,
                    kpi.unit
                )}. (${compareSign}${deltaRelative.toFixed(1)}%)`;
                if (kpi.maximize) {
                    if (total > compareTo) {
                        diff = total > 1.5 * compareTo ? 2 : 1;
                    } else if (total < compareTo) {
                        diff = total < compareTo / 2 ? -2 : -1;
                    } else {
                        diff = 0;
                    }
                } else {
                    if (total > compareTo) {
                        diff = total > 1.5 * compareTo ? -2 : -1;
                    } else if (total < compareTo) {
                        diff = total < compareTo / 2 ? 2 : 1;
                    } else {
                        diff = 0;
                    }
                }
            }

            label = formatSwitcher(total, kpi.unit);
        }
        return (
            <Stack direction="row" spacing={0}>
                <Typography>{label}</Typography>
                <Box>{DiffSign(diff, hoverText)}</Box>
            </Stack>
        );
    };

    if (designs) {
        return (
            <>
                <InformationDialogue text={discussion}/>
                <Formik
                    initialValues={{
                        preferred: designs.reduce((acc, design) => {
                            if (design) return { ...acc, [design.id]: design.is_preferred ? design.is_preferred : false };
                        }, {}),
                        comments: designs.reduce((acc, design) => {
                            if (design) return { ...acc, [design.id]: design.comment };
                        }, {}),
                    }}
                >
                    {({ values }) => (
                        <Form>
                            <TableContainer>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell key={"corner_cell"} />
                                            {designs.map((design, index) => (
                                                <>
                                                    {(referenceDesign && index === 0) || (!referenceDesign && index === 1) ? (
                                                        <TableCell
                                                            key={"goal_cell"}
                                                            sx={{
                                                                borderLeft: "1px solid white",
                                                            }}
                                                        >
                                                            <Typography variant="h5" textOverflow={"no-wrap"}>
                                                                Doel
                                                            </Typography>
                                                        </TableCell>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <TableCell key={"design_" + index}>
                                                        <Tooltip title={design.description}>
                                                            <Typography variant="h5" textOverflow={"no-wrap"}>
                                                                {design.name}
                                                            </Typography>
                                                        </Tooltip>
                                                    </TableCell>
                                                </>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow sx={{ backgroundColor: styling.palette.background.default }}>
                                            <TableCell>
                                                <Typography variant="h6" textOverflow={"no-wrap"}>
                                                    Kosten
                                                </Typography>
                                            </TableCell>
                                            {designs.map((design, index) => (
                                                <>
                                                    {(referenceDesign && index === 0) || (!referenceDesign && index === 1) ? (
                                                        <TableCell
                                                            key={"goal_cell"}
                                                            sx={{
                                                                borderLeft: "1px solid white",
                                                            }}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}

                                                    <TableCell key={"design_" + index}>
                                                        <Tooltip title={design.description}>
                                                            <Typography>{EuroFormat(design.investment_cost)}</Typography>
                                                        </Tooltip>
                                                    </TableCell>
                                                </>
                                            ))}
                                        </TableRow>
                                        <>
                                            {[...project.themes]
                                                .sort((a, b) => flatThemes[a.theme].supertheme - flatThemes[b.theme].supertheme)
                                                .filter((theme) => theme.subthemes.some((subtheme) => subtheme.goals.length > 0))
                                                .map((projectTheme, themeIndex) => {
                                                    const theme = flatThemes[projectTheme.theme];
                                                    const supertheme = theme.supertheme;
                                                    const color =
                                                        supertheme === 0
                                                            ? styling.palette.primary.superLight
                                                            : supertheme === 1
                                                            ? styling.palette.primary.light
                                                            : styling.palette.secondary.superLight;
                                                    return (
                                                        <>
                                                            <TableRow key={`themeHeaderRow_${themeIndex}`} sx={{ backgroundColor: color }}>
                                                                <TableCell colSpan={designs.length + 2}>
                                                                    <Typography variant="h6">{theme.name}</Typography>
                                                                </TableCell>
                                                            </TableRow>

                                                            {projectTheme.subthemes.map((projectSubtheme, subthemeIndex) => {
                                                                const subtheme = flatSubThemes[projectSubtheme.subtheme];
                                                                return projectSubtheme.goals.map((goal, goalIndex) => {
                                                                    const kpi = flatKpis[goal.kpi];
                                                                    return (
                                                                        <TableRow key={goal.id} sx={{ backgroundColor: color }}>
                                                                            <TableCell sx={{ borderRight: "1px solid white" }}>
                                                                                <Tooltip title={subtheme.description}>
                                                                                    <Typography>
                                                                                        {kpi.name === "Score" ? subtheme.name : kpi.name}
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                            {designs.map((design, index) => {
                                                                                let score =
                                                                                    design.scores_by_themes[projectTheme.id][projectSubtheme.id][
                                                                                        goalIndex
                                                                                    ];
                                                                                if (score === undefined) {
                                                                                    score = { value: null };
                                                                                }

                                                                                return (
                                                                                    <>
                                                                                        {(referenceDesign && index === 0) ||
                                                                                        (!referenceDesign && index === 1) ? (
                                                                                            <TableCell key={"goal_cell"}>
                                                                                                <Typography>
                                                                                                    {formattedTotalScore(goal, kpi)}
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                        <TableCell
                                                                                            key={score.id}
                                                                                            sx={{
                                                                                                borderLeft: `${index === 1 ? 1 : 0}px solid white`,
                                                                                            }}
                                                                                        >
                                                                                            <Typography>
                                                                                                {formattedTotalScore(score, kpi, goal.value)}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </TableRow>
                                                                    );
                                                                });
                                                            })}
                                                        </>
                                                    );
                                                })}
                                        </>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    Kansrijke alternatieven kunnen mee genomen worden naar de volgende ontwerploop, om een nieuw
                                                    ontwerp op te baseren.
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                            {designs.map((design, index) => {
                                                if (design.project !== null) {
                                                    return (
                                                        <TableCell key={"reference_cell"}>
                                                            <Typography variant="body2">
                                                                Een referentie kan niet worden meegenomen als kansrijk alternatief
                                                            </Typography>
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <TableCell key={design.id + "_toggle"}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Field
                                                                            disabled={!payload.editable}
                                                                            component={Checkbox}
                                                                            name={`preferred[${design.id}]`}
                                                                            type="checkbox"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Tooltip title="Vink dit vakje aan om dit ontwerp mee te nemen naar de volgende ontwerploop">
                                                                            <Typography noWrap>
                                                                                Kansrijk alternatief <HelpOutline fontSize="small" />
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    }
                                                                />

                                                                <Field
                                                                    disabled={!payload.editable}
                                                                    fullWidth
                                                                    component={TextField}
                                                                    name={`comments[${design.id}]`}
                                                                    maxRows={5}
                                                                    minRows={1}
                                                                    multiline
                                                                    label="Uitleg"
                                                                />
                                                            </TableCell>
                                                        </>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ButtonGroup sx={{ marginTop: "10px", display: "block" }}>
                                {!(payload.phase.id < project.phases.length) && (
                                    <Button sx={{ paddingLeft: "25px" }} onClick={advancePhase} disabled={!payload.editable}>
                                        Volgende fase {preferredDesign === undefined ? "" : "met voorkeursalternatief"}
                                    </Button>
                                )}
                                {!(payload.phase.id < project.phases.length || payload.loop.id < payload.phase.loops.length - 1) && (
                                    <Button sx={{ paddingLeft: "25px" }} onClick={() => addLoop(values)} disabled={!payload.editable}>
                                        OntwerpLoop toevoegen
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Form>
                    )}
                </Formik>
                <StepperButtons payload={payload} />
            </>
        );
    } else {
        return <Typography>Geen ontwerpen gevonden</Typography>;
    }
}
