import { toast } from "react-toastify";
import { poster } from "../../utils/axios";

export async function addFeedback(feedback) {
    const response = await poster("/feedback/", feedback)
        .then((response) => {
            if (response?.status === 201) {
                toast.success("Feedback aangemaakt");
                return { status: "success", detail: "Feedback aangemaakt" };
            }
        })
        .then((response) => response)
        .catch((error) => {
            toast.error("Er is iets misgegaan bij het aanmaken van de feedback");
            console.error(error);
            return { status: "error", detail: "Er is iets misgegaan bij het aanmaken van de feedback" };
        });

    return response;
}
