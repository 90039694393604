import {
    Button,
    ButtonGroup,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Capitalize, DateFormat, Pluralize } from "../../../functions/Formatters";
import { confirmEnrollment, deleteEnrollment, fetchEnrollments } from "../UserActions";
import { Delete, Refresh } from "@mui/icons-material";
import { global } from "../../../context/global";

export default function EnrollmentTable() {
    const dispatch = useDispatch();
    const enrollments = useSelector((state) => state.users.enrollments);
    const organisations = useSelector((state) => state.organisations.organisations);
    const [chosenOrganisations, setChosenOrganisations] = useState(
        enrollments.reduce((acc, enrollment) => {
            acc[enrollment.id] = "none";
            return acc;
        }, {})
    );

    if (enrollments.length === 0) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Inschrijvingen
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        fetchEnrollments(dispatch);
                    }}
                    startIcon={<Refresh />}
                >
                    Verversen
                </Button>
                <Typography>Er zijn momenteel geen actieve inschrijvingen.</Typography>
            </>
        );
    } else {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Inschrijvingen
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<Refresh />}
                    onClick={() => {
                        fetchEnrollments(dispatch);
                    }}
                >
                    Verversen
                </Button>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h5">{Capitalize(global.name)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5">{Capitalize(global.email)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5">{Capitalize(global.employer)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5">{Capitalize(global.role)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5">{Capitalize(Pluralize(global.project))}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5">{Capitalize(global.date)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5">{Capitalize(global.actions)}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {enrollments.map((enrollment) => {
                                const org = chosenOrganisations[enrollment.id];

                                return (
                                    <TableRow key={enrollment.id}>
                                        <TableCell>
                                            <Typography variant="h6">{enrollment.name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{enrollment.email}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{enrollment.employer || "Onbekend"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{enrollment.role || "Onbekend"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography whiteSpace={"pre-line"}>{enrollment.projects || "Onbekend"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {DateFormat(enrollment.created_at)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row">
                                                <Select
                                                    value={org}
                                                    onChange={(e) =>
                                                        setChosenOrganisations({
                                                            ...chosenOrganisations,
                                                            [enrollment.id]: e.target.value,
                                                        })
                                                    }
                                                    autoWidth
                                                    sx={{ p: 0, m: 0, minWidth: 200 }}
                                                >
                                                    <MenuItem value={"none"} key="" disabled>
                                                        Selecteer {global.organisation}
                                                    </MenuItem>
                                                    {organisations.map((organisation) => (
                                                        <MenuItem
                                                            key={organisation.id}
                                                            value={organisation.id}
                                                        >
                                                            {organisation.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <ButtonGroup>
                                                    <Button
                                                        variant="contained"
                                                        disabled={!org || org === "none"}
                                                        onClick={() => {
                                                            confirmEnrollment(
                                                                enrollment.id,
                                                                org,
                                                                dispatch
                                                            );
                                                        }}
                                                    >
                                                        Bevestigen
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        startIcon={<Delete />}
                                                        onClick={() => {
                                                            deleteEnrollment(
                                                                enrollment.id,
                                                                dispatch
                                                            );
                                                        }}
                                                    >
                                                        Verwijderen
                                                    </Button>
                                                </ButtonGroup>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}
