import React, { useEffect, useState } from "react";

import { ButtonGroup, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Cancel, Edit, Save } from "@mui/icons-material";

import { Capitalize, prettifyNumber } from "../../../functions/Formatters";
import CustomNumberField from "./CustomNumberfield";

/**
 *
 * @param {Object} props - The props object
 * @param {string} props.label - The label to display
 * @param {string} props.field - The field to edit
 * @param {string} props.value - The initial value
 * @param {function} props.onSave - The function to call when the value is saved. The function should accept an object with the field as key and the new value as value. Include {...data, id: id} to include the id of the object.
 * @param {boolean} [props.header] - Whether to display the label as a header (h5) or paragraph (default: false)
 * @param {number} [props.lines] - The number of lines to display (default: 0). If 0, the component will be displayed inline. If > 0, the component will be displayed as a header with a multiline text field.
 * @param {boolean} [props.allowEdit] - Whether to allow editing (default: true)
 * @returns {JSX.Element} - The component
 */
export default function ToggleableInputField({
    label,
    field,
    value,
    onSave,
    header = false,
    lines = 0,
    allowEdit = true,
    inline = false,
    type = "text",
    unit,
}) {
    const [open, setOpen] = useState(false);
    const [newValue, setNewValue] = useState(value);
    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const handleSave = () => {
        onSave({ [field]: newValue });
        setOpen(false);
    };

    const labelVariant = header ? "h5" : "body1";
    const multiline = lines > 1;

    const OpenEditButton = () => {
        return (
            <>
                {allowEdit ? (
                    <IconButton onClick={() => setOpen(true)}>
                        <Edit fontSize={header ? "medium" : "small"} />
                    </IconButton>
                ) : null}
            </>
        );
    };

    const componentSwitcher = (type) => {
        switch (type) {
            case "text":
                return (
                    <TextField
                        id={label}
                        label={Capitalize(label)}
                        onChange={(e) => setNewValue(e.target.value)}
                        fullWidth={multiline}
                        value={newValue}
                        multiline={multiline}
                        minRows={lines}
                        maxRows={8}
                        autoFocus
                    />
                );
            case "number":
                return (
                    <CustomNumberField
                        id={label}
                        label={Capitalize(label)}
                        value={newValue}
                        multiline={multiline}
                        fullWidth={false}
                        rows={lines}
                        autoFocus
                        unit={unit}
                        field={field}
                        onChange={(e) => {
                            setNewValue(e);
                        }}
                    />
                );
        }
    };

    return (
        <>
            {open ? (
                <form onSubmit={handleSave}>
                    {multiline && !inline && (
                        <Typography variant={labelVariant} gutterBottom>
                            {label}
                        </Typography>
                    )}
                    <Stack direction={"row"} sx={{ margin: "10px 0px"}}>
                        {componentSwitcher(type)}
                        <ButtonGroup orientation={multiline ? "vertical" : "horizontal"}>
                            <IconButton onClick={() => setOpen(false)}>
                                <Cancel />
                            </IconButton>
                            <IconButton onClick={() => handleSave()}>
                                <Save />
                            </IconButton>
                        </ButtonGroup>
                    </Stack>
                </form>
            ) : (
                <>
                    {inline ? (
                        <Stack direction="row" alignItems={header ? "start" : "end"}>
                            <Typography variant={labelVariant} gutterBottom>
                                {value || `Geen ${label}`}
                            </Typography>
                            <OpenEditButton />
                        </Stack>
                    ) : (
                        <Stack>
                            <Stack direction="row" alignItems={header ? "start" : "end"}>
                                <Typography variant={labelVariant} gutterBottom>
                                    {!inline && label + ": "} {!multiline && (type === "number" ? prettifyNumber(value, unit) : value)}
                                </Typography>
                                <OpenEditButton />
                            </Stack>
                            {multiline && <Typography sx={{ whiteSpace: "pre-wrap" }}>{value}</Typography>}
                        </Stack>
                    )}
                </>
            )}
        </>
    );
}
