import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    PointElement,
    LineElement,
    LineController,
    BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { useTheme } from "@emotion/react";

export default function ScoreGraphCost({ variants, designs }) {
    const styling = useTheme();

    const dataSets = [
        {
            data: designs.map((design) => design.investment_cost),
            backgroundColor: styling.palette.graphics.primaryDarkBlue,
            label: "Investering",
        }
    ] 

    ChartJS.register(
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Legend,
        Tooltip,
        LineController,
        BarController,
        Colors,
        Title
    );
    const options = {
        plugins: {
            title: {
                display: true,
                text: "Investeringskosten",
                font: {
                    size: 14,
                },
            },
            legend: {
                display: false,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                title: {
                    display: true,
                    text: "Kosten (€)",
                },
            },
        },
    };

    const data = {
        labels: variants,
        datasets: dataSets,
    };
    if (data === null) {
        return null;
    }

    return <Chart type="bar" options={options} data={data} redraw />;
}
