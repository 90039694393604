import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { store } from "../store/store";
import { setAuthTokens, logout } from "../store/slices/authSlice";
import { toast } from "react-toastify";

const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosService.interceptors.request.use(async (config) => {
    const { token } = store.getState().auth;

    if (token !== null) {
        config.headers.Authorization = "Bearer " + token;
        // @ts-ignore
        // console.debug('[Request]', config.baseURL + config.url, JSON.stringify(token));
    }
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        // @ts-ignore
        // console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
        return Promise.resolve(res);
    },
    (err) => {
        console.debug("[Response]", err.config.baseURL + err.config.url, err.response.status, err.response.data);
        return Promise.reject(err);
    }
);

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    const { refreshToken } = store.getState().auth;
    if (refreshToken !== null) {
        return axios
            .post(
                "/auth/refresh/",
                {
                    refresh: refreshToken,
                },
                {
                    baseURL: process.env.REACT_APP_API_BASE_URL,
                }
            )
            .then((resp) => {
                const { access, refresh } = resp.data;
                failedRequest.response.config.headers.Authorization = "Bearer " + access;
                store.dispatch(setAuthTokens({ token: access, refreshToken: refresh }));
            })
            .catch((err) => {
                toast.error("Error:", err.response.data.detail || "Onbekende fout");
                console.debug("[Response]", err.config.url, err.response.status, err.response.data);
                if (err.response && err.response.status === 401) {
                    store.dispatch(logout());
                }
            });
    }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

export const errorToToast = (error) => {
    if (error.response) {
        let details = "";
        if (error.response.data.detail) {
            details = error.response.data.detail;
        } else if (error.response.data.details) {
            details = error.response.data.details.join("\n");
        } else {
            details = error.response.data;
        }
        toast.error(details);
        return error.response;
    } else if (error.message) {
        toast.error(error.message);
    } else {
        toast.error(JSON.stringify(error));
    }
};
/**
 * Fetches data from the specified URL using axios.
 * @param {string} url - The URL to fetch data from.
 * @param {string} [responseType='json'] - The response type of the request. Defaults to 'json'. Use 'blob' to download files.
 * @returns {Promise} A promise that resolves to the response data or rejects with an error.
 */
export async function fetcher(url, responseType = "json") {
    return axiosService
        .get(url, { responseType: responseType })
        .then((response) => response)
        .catch((error) => errorToToast(error));
}
/**
 * Makes a POST request using axios.
 * @param {string} url - The URL to send the request to.
 * @param {object} payload - The data to send with the request.
 * @param {string} [contentType='application/json'] - The content type of the request. Defaults to 'application/json'.
 * @returns {Promise} - A promise that resolves to the response of the request.
 */
export async function poster(url, payload = {}, contentType = "application/json") {
    try {
        const response = await axiosService.post(url, payload, { headers: { "Content-Type": contentType } });
        return response;
    } catch (error) {
        return errorToToast(error);
    }
}
/**
 * Sends a PUT request to the specified URL with the given payload.
 * @param {string} url - The URL to send the request to.
 * @param {object} payload - The data to send in the request body.
 * @param {string} [contentType='application/json'] - The content type of the request. Defaults to 'application/json'.
 * @returns {Promise} - A promise that resolves to the response from the server.
 */
export function putter(url, payload, contentType = "application/json") {
    return axiosService
        .put(url, payload, { headers: { "Content-Type": contentType } })
        .then((response) => response)
        .catch((error) => errorToToast(error));
}

/**
 * Sends a PATCH request to the specified URL with the given payload.
 * @param {string} url - The URL to send the PATCH request to.
 * @param {object} payload - The data to be sent in the request body.
 * @param {string} [contentType='application/json'] - The content type of the request. Defaults to 'application/json'.
 * @returns {Promise} A promise that resolves to the response of the PATCH request.
 */
export async function patcher(url, payload, contentType = "application/json") {
    return axiosService
        .patch(url, payload, { headers: { "Content-Type": contentType } })
        .then((response) => response)
        .catch((error) => errorToToast(error));
}
/**
 * Deletes a resource using the specified URL.
 * @param {string} url - The URL of the resource to delete.
 * @returns {Promise} A promise that resolves to the response of the delete request.
 */
export function deleter(url) {
    return axiosService
        .delete(url)
        .then((response) => {
            toast.success("Verwijderd");
            return response;
        })
        .catch((error) => errorToToast(error));
}

export default axiosService;
