import React, { useState } from "react";
import { patchProject } from "../ProjectActions";
import { useDispatch } from "react-redux";
import {
    Button,
    ButtonGroup,
    Checkbox,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Add, Delete, Edit, Save } from "@mui/icons-material";
import { deleter, poster } from "../../../utils/axios";
import { layers as text } from "../../../context/preparationPhase";
import { toast } from "react-toastify";
import ConfirmDeleteDialog from "../../ConfirmDeleteDialog";
import { updateProject } from "../../../store/slices/projectsSlice";
import StepperButtons from "../StepperButtons";

export default function LayerSelect({ payload }) {
    const dispatch = useDispatch();
    const [layerChoices, setLayerChoices] = useState(payload.project.potential_layers);
    const [selectedLayers, setSelectedLayers] = useState(
        payload.project.layers.length > 0 ? payload.project.layers : payload.project.potential_layers.map((layer) => layer.id)
    );
    payload.editable = payload.editable && payload.project.status <= 1 ? true : false;

    const handleSaveLayers = (updatedProject) => {
        patchProject(updatedProject, dispatch);
    };

    const handleCheckbox = (event) => {
        if (event.target.checked) {
            setSelectedLayers([...selectedLayers, Number(event.target.name)]);
        } else {
            setSelectedLayers(selectedLayers.filter((layer) => layer != event.target.name));
        }
    };

    const [customLayer, setCustomLayer] = useState(false);
    const handleAddLayer = () => {
        setCustomLayer({ name: "", description: "" });
    };
    const handleEditCustomLayer = (id) => {
        setCustomLayer(layerChoices.find((layer) => layer.id === id));
        setLayerChoices(layerChoices.filter((layer) => layer.id !== id));
    };
    const saveCustomLayer = () => {
        poster(`/projects/${payload.project.id}/save_custom_layer/`, customLayer).then((response) => {
            if (response.status === 200) {
                const newLayerChoices = [...layerChoices.filter((layer) => layer.id !== response.data.id), response.data];
                const newSelectedLayers = [...selectedLayers.filter((layer) => layer !== response.data.id), response.data.id];
                dispatch(
                    updateProject({
                        ...payload.project,
                        potential_layers: newLayerChoices,
                        layers: newSelectedLayers,
                    })
                );
                setLayerChoices(newLayerChoices);
                setSelectedLayers(newSelectedLayers);
                setCustomLayer(false);
                toast.success("Onderdeel opgeslagen");
            }
        });
    };
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const handleDeleteCustomLayer = (id) => {
        deleter(`/projects/${payload.project.id}/delete_custom_layer/${id}/`).then((response) => {
            if (response.status === 200) {
                dispatch(
                    updateProject({
                        ...payload.project,
                        potential_layers: layerChoices.filter((layer) => layer.id !== id),
                        layers: selectedLayers.filter((layer) => layer !== id),
                    })
                );
                setLayerChoices(layerChoices.filter((layer) => layer.id !== id));
                setSelectedLayers(selectedLayers.filter((layer) => layer !== id));
                setDeleteDialogOpen(false);
            }
        });
    };

    return (
        <>
            <ConfirmDeleteDialog
                open={deleteDialogOpen === false ? false : true}
                onClose={() => setDeleteDialogOpen(false)}
                dialogTitle="Onderdeel verwijderen"
                dialogContentText="Weet u zeker dat u dit onderdeel wilt verwijderen?"
                handleConfirm={() => handleDeleteCustomLayer(deleteDialogOpen)}
                dialogConfirmButton="Verwijderen"
            />
            <Typography>{text.description}</Typography>
            <br />
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography fontWeight={"bold"}>{text.tableHeaderGeneral}</Typography>
                            </TableCell>
                        </TableRow>
                        {layerChoices
                            .filter((layer) => layer.project === null)
                            .map((layer) => (
                                <TableRow key={layer.id}>
                                    <TableCell>
                                        <Typography>{layer.name}</Typography>
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            name={layer.id.toString()}
                                            checked={selectedLayers.includes(layer.id)}
                                            disabled={!payload.editable}
                                            onChange={handleCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{layer.description}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography fontWeight={"bold"}>{text.tableHeaderSpecific}</Typography>
                            </TableCell>
                        </TableRow>
                        {layerChoices
                            .filter((layer) => layer.project !== null)
                            .map((layer) => (
                                <TableRow key={layer.id}>
                                    <TableCell>
                                        <Typography>{layer.name}</Typography>
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            name={layer.id.toString()}
                                            disabled={!payload.editable}
                                            checked={selectedLayers.includes(layer.id)}
                                            onChange={handleCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={2}>
                                            <Typography>{layer.description}</Typography>
                                            <ButtonGroup>
                                                <IconButton sx={{ p: 0, m: 0 }} onClick={() => handleEditCustomLayer(layer.id)}>
                                                    <Edit fontSize="small" />
                                                </IconButton>
                                                <IconButton sx={{ p: 0, m: 0 }} onClick={() => setDeleteDialogOpen(layer.id)}>
                                                    <Delete fontSize="small" />
                                                </IconButton>
                                            </ButtonGroup>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {customLayer && (
                            <TableRow key={"customlayer"}>
                                <TableCell>
                                    <Stack direction="row">
                                        <TextField
                                            autoFocus
                                            value={customLayer.name}
                                            onChange={(e) => setCustomLayer({ ...customLayer, name: e.target.value })}
                                        />
                                        <IconButton onClick={saveCustomLayer} disabled={customLayer?.name == ""}>
                                            <Save />
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                                <TableCell padding="checkbox">
                                    <Checkbox checked={true} disabled={true} onChange={() => setCustomLayer(false)} />
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row">
                                        <TextField
                                            value={customLayer.description}
                                            onChange={(e) => setCustomLayer({ ...customLayer, description: e.target.value })}
                                        />
                                        <IconButton onClick={saveCustomLayer} disabled={customLayer?.name == "" || !payload.editable}>
                                            <Save />
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )}

                        <TableRow>
                            <TableCell colSpan={3}>
                                <Button onClick={handleAddLayer} disabled={!payload.editable}>
                                    <Add />
                                    Onderdeel toevoegen
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <StepperButtons
                payload={payload}
                saveAction={handleSaveLayers}
                saveActionArguments={{ id: payload.project.id, layers: selectedLayers }}
                updateStatus={false}
                warningPopup={payload.project.status <= 1}
            />
        </>
    );
}
