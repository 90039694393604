import { Capitalize, Pluralize } from "../functions/Formatters";
import { global } from "./global";

const navItems = {
    home: {
        title: "Projecten",
        description: "overzicht met projecten waar u toegang tot heeft",
        link: "/",
    },
    orgs: {
        title: Capitalize(Pluralize(global.organisation)),
        description: `overzicht van de ${Pluralize(global.organisation)} waar u lid van bent en de bijbehorende gebruikers`,
        link: "/organisation",
    },
    themes: {
        title: "Thema’s",
        description: "overzicht met de thema’s van de duurzaamheidsroos, inclusief toelichting en bijbehorende KPI’s",
        link: "/themes",
    },
    admin: {
        title: "Beheer",
        // description: "overzicht van de verschillende stappen die genomen kunnen worden om duurzaamheid in te bedden in het project.",
        description: "op deze pagina kunt u, als applicatiebeheerder, gebruikers, organisaties en thema's beheren.",
        link: "/admin",
    },
    adminOrg: {
        title: "Organisatiebeheer",
        // description: "overzicht van de verschillende stappen die genomen kunnen worden om duurzaamheid in te bedden in het project.",
        description: "op deze pagina kunt u, als applicatiebeheerder, organisaties beheren.",
        link: "/admin",
    },
    adminThemes: {
        title: "Themabeheer",
        // description: "overzicht van de verschillende stappen die genomen kunnen worden om duurzaamheid in te bedden in het project.",
        description: "op deze pagina kunt u, als applicatiebeheerder, de duurzaamheidsthema's en KPI's beheren.",
        link: "/admin",
    },
    adminUsers: {
        title: "Gebruikersbeheer",
        // description: "overzicht van de verschillende stappen die genomen kunnen worden om duurzaamheid in te bedden in het project.",
        description: "op deze pagina kunt u, als applicatiebeheerder, gebruikers beheren.",
        link: "/admin",
    },
};

export const welcome = {
    title: "Welkom op het duurzaamheidsdashboard van het Hoogwaterbeschermingsprogramma",
    paragraphs: [
        {
            introduction:
                "Het HWBP heeft als doel gesteld om duurzaamheid en ruimtelijke kwaliteit geborgd te hebben in de processen van de dijkversterkingsprojecten. Deze tool draagt daar op twee manieren aan bij: ",
            listItems: [
                "De tool biedt handvatten aan projectteams om invulling te geven aan duurzaamheid en ruimtelijke kwaliteit gedurende het project",
                "In de tool is het mogelijk om de tussentijdse resultaten en afwegingen op deze onderwerpen te rapporteren om zo de prestaties inzichtelijk te maken.",
            ],
        },
    ],
    navigation: {
        introduction: "Kies hier om naar één van de onderdelen te gaan van de tool:",
        defaultItems: [navItems.home, navItems.orgs, navItems.themes],
        adminItems: [navItems.adminOrg, navItems.adminThemes, navItems.adminUsers],
    },
};
