import React, { useState } from "react";
import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";

export default function DesignPrincipleDialog(props) {
    const { open, onClose, focus, setFocus } = props;

    const principles = useSelector((state) => state.themes.designPrinciples); // nested array of superthemes, themes, aspects, principles
    const themes = principles.reduce((acc, supertheme) => {
        return [...acc, ...supertheme.themes];
    }, []);

    const displayedTheme = themes.find((theme) => theme.id === focus);

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"xl"} fullWidth>
            <DialogTitle variant="h4" sx={{ pt: 0 }}>
                Ontwerpprincipes
            </DialogTitle>

            <DialogContent sx={{ overflowY: "auto" }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={4} sx={{ height: "75vh", overflowY: "scroll" }} border={1}>
                        <Typography variant="h5">Index</Typography>
                        <List disablePadding>
                            {principles.map((supertheme) => {
                                const [collapseIn, setCollapseIn] = useState(true);
                                const handleClickSupertheme = () => {
                                    setCollapseIn(!collapseIn);
                                };
                                return (
                                    <>
                                        <ListItem disablePadding key={`li_supertheme_${supertheme.id}`}>
                                            <ListItemButton onClick={handleClickSupertheme} sx={{ p: 0 }}>
                                                <ListItemIcon sx={{ ml: "auto" }}>
                                                    <ExpandMore sx={{ transform: collapseIn ? "rotate(0deg)" : "rotate(270deg)" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={supertheme.name} primaryTypographyProps={{ variant: "h6" }} />
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={collapseIn}>
                                            {supertheme.themes.map((theme) => {
                                                const [open, setOpen] = useState(focus ? focus === theme.id : false);
                                                const handleClickTheme = () => {
                                                    setOpen(!open);
                                                    setFocus(theme.id);
                                                };

                                                const isFocused = focus === theme.id;
                                                const typographyProps = {
                                                    color: isFocused ? "primary.dark" : "text.primary",
                                                    fontWeight: isFocused ? "medium" : "normal",
                                                    fontSize: isFocused ? "1.1rem" : "1rem",
                                                    sx: { textDecoration: isFocused ? "underline" : "none" },
                                                };

                                                return (
                                                    <ListItem key={`li_theme_${theme.id}`} disablePadding>
                                                        <ListItemButton
                                                            selected={isFocused}
                                                            key={`li_theme_${theme.id}`}
                                                            onClick={handleClickTheme}
                                                            sx={{ p: 0 }}
                                                        >
                                                            <ListItemText inset primary={`${theme.name}`} primaryTypographyProps={typographyProps} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </Collapse>
                                    </>
                                );
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ height: "75vh", overflowY: "auto" }} border={1}>
                        {displayedTheme ? (
                            <>
                                <Typography variant="h5">{displayedTheme?.name}</Typography>
                                {displayedTheme?.aspects.length === 0 && <Typography variant="h6">Geen principes gevonden</Typography>}
                                {displayedTheme.aspects.map((aspect) => {
                                    return (
                                        <>
                                            <Typography variant="h6">{aspect.name}</Typography>
                                            <ul>
                                                {aspect.principles.map((principle, index) => (
                                                    <li key={`li_${index}`}>
                                                        <Typography sx={{ whiteSpace: "pre-wrap" }}>{principle.text}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                <Typography variant="h5">Geen thema geselecteerd</Typography>
                                <Typography variant="h6">Selecteer een thema</Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ m: 0, p: 0 }}>
                <Button onClick={onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    );
}
