import { setThemes, updateTheme, addTheme, removeTheme, setSuperthemes, setDesignPrinciples } from "../../store/slices/themesSlice";
import { fetcher, poster, deleter, patcher } from "../../utils/axios";

/**
 * Fetch themes and superthemes from the server and update the Redux store.
 *
 * @param {function} dispatch - Redux dispatch function.
 */
export const fetchThemes = (dispatch) => {
    fetcher("/themes/").then((response) => {
        dispatch(setThemes(response.data.themes));
        dispatch(setSuperthemes(response.data.superthemes));
        dispatch(setDesignPrinciples(response.data.design_principles));
    });
};

/**
 * Create a new theme by sending a POST request to the server, and update the Redux store.
 *
 * @param {Object} theme - The theme data to create.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const createTheme = (theme, dispatch, closeDialog) => {
    poster("/themes/", theme).then((response) => {
        dispatch(addTheme(response.data));
        closeDialog();
    });
};

/**
 * Update an existing theme by sending a PATCH request to the server, and update the Redux store.
 *
 * @param {Object} theme - The theme data to update.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const updateThemeAction = (theme, dispatch, closeDialog) => {
    patcher(`/themes/${theme.id}/`, theme).then((response) => {
        dispatch(updateTheme(response.data));
        closeDialog();
    });
};

/**
 * Delete a theme by sending a DELETE request to the server, and update the Redux store.
 *
 * @param {Object} theme - The theme data to delete.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const deleteTheme = (theme, dispatch, closeDialog) => {
    deleter(`/themes/${theme.id}/`).then(() => {
        dispatch(removeTheme(theme.id));
        closeDialog();
    });
};

// SUBTHEMES
/**
 * Create a new subtheme by sending a POST request to the server, and update the Redux store.
 *
 * @param {Object} subTheme - The subtheme data to create.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const createSubTheme = (subTheme, dispatch, closeDialog) => {
    poster("/subthemes/", subTheme).then((response) => {
        dispatch(updateTheme(response.data));
        closeDialog();
    });
};

/**
 * Update an existing subtheme by sending a PATCH request to the server, and update the Redux store.
 *
 * @param {Object} subTheme - The subtheme data to update.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const updateSubThemeAction = (subTheme, dispatch, closeDialog) => {
    patcher(`/subthemes/${subTheme.id}/`, subTheme).then((response) => {
        dispatch(updateTheme(response.data));
        if (closeDialog) {
            closeDialog();
        }
    });
};

/**
 * Delete a subtheme by sending a DELETE request to the server, and update the Redux store.
 *
 * @param {Object} subTheme - The subtheme data to delete.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const deleteSubTheme = (subTheme, dispatch, closeDialog) => {
    deleter(`/subthemes/${subTheme.id}/`).then((response) => {
        dispatch(updateTheme(response.data));
        closeDialog();
    });
};

// KPIs
/**
 * Create a new KPI by sending a POST request to the server, and update the Redux store.
 *
 * @param {Object} kpi - The KPI data to create.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const createKPI = (kpi, dispatch, closeDialog) => {
    poster("/kpis/", kpi).then((response) => {
        dispatch(updateTheme(response.data));
        closeDialog();
    });
};

/**
 * Update an existing KPI by sending a PATCH request to the server, and update the Redux store.
 *
 * @param {Object} kpi - The KPI data to update.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const updateKPI = (kpi, dispatch, closeDialog) => {
    patcher(`/kpis/${kpi.id}/`, kpi).then((response) => {
        dispatch(updateTheme(response.data));
        closeDialog();
    });
};

/**
 * Delete a KPI by sending a DELETE request to the server, and update the Redux store.
 *
 * @param {Object} kpi - The KPI data to delete.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} closeDialog - Function to close the dialog.
 */
export const deleteKPI = (kpi, dispatch, closeDialog) => {
    deleter(`/kpis/${kpi.id}/`).then((response) => {
        dispatch(updateTheme(response.data));
        closeDialog();
    });
};
