import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchThemes, deleteTheme } from "./ThemeActions";
import ThemeDialog from "./NewThemeDialog";
import { Add, ExpandMore, Refresh } from "@mui/icons-material";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { useNavigate } from "react-router-dom";
import { selectTheme } from "../../store/slices/themesSlice";
import { global } from "../../context/global";
import { Capitalize, Pluralize } from "../../functions/Formatters";

export default function ThemeOverview() {
    const userIsAdmin = useSelector((state) => state.auth.account.is_staff);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const themes = useSelector((state) => state.themes.themes);
    const superthemes = useSelector((state) => state.themes.superthemes);

    const [themeDialogOpen, setThemeDialogOpen] = useState(false);
    const [themeToEdit, setThemeToEdit] = useState(false);
    const handleClickEdit = (theme) => {
        dispatch(selectTheme(theme.id));
        navigate(`${theme.id}`);
    };
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [themeToDelete, setThemeToDelete] = useState(false);

    const handleDialogClose = () => {
        setThemeDialogOpen(false);
        setDeleteDialogOpen(false);
        setThemeToEdit(false);
        setThemeToDelete(false);
    };

    const tableHeaders = [global.theme, Pluralize(global.subtheme)];
    return (
        <>
            <ThemeDialog onClose={handleDialogClose} open={themeDialogOpen} edit={themeToEdit} />
            <ConfirmDeleteDialog
                open={deleteDialogOpen}
                onClose={handleDialogClose}
                dialogTitle={"Thema verwijderen"}
                dialogContentText={`Weet u zeker dat u het thema ${themeToDelete.name} wilt verwijderen?`}
                dialogConfirmButton={"Verwijder thema"}
                handleConfirm={() => {
                    deleteTheme(themeToDelete, dispatch, handleDialogClose);
                }}
            />

            <Typography variant="h4" gutterBottom>
                {Pluralize(Capitalize(global.theme))}
            </Typography>
            <Typography>
                Hier ziet u een overzicht van de thema's en de bijbehorende aspecten. Door op een thema te klikken, kunt
                u de details van het thema bekijken
            </Typography>
            <Stack spacing={1}>
                <ButtonGroup variant="contained" sx={{ width: "fit-content" }}>
                    <Button onClick={() => fetchThemes(dispatch)}>
                        <Refresh />
                        {global.refresh}
                    </Button>
                    {userIsAdmin && (
                        <Button onClick={() => setThemeDialogOpen(true)}>
                            <Add />
                            {global.new} {global.theme}
                        </Button>
                    )}
                </ButtonGroup>
                {superthemes.map((supertheme) => (
                    <Accordion key={"supertheme_accordion_" + supertheme.id} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="h6">{supertheme.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeaders.map((header) => (
                                                <TableCell key={header}>
                                                    <Typography variant="h5">{Capitalize(header)}</Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {themes
                                            .filter((theme) => theme.supertheme === supertheme.id)
                                            .map((theme) => (
                                                <TableRow
                                                    key={theme.id}
                                                    onClick={() => handleClickEdit(theme)}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <TableCell width={"30%"}>
                                                        <Typography fontSize={"1.1rem"}>{theme.name}</Typography>
                                                    </TableCell>
                                                    <TableCell width={"70%"}>
                                                        <Typography>
                                                            {theme.subthemes
                                                                .map((subtheme) => subtheme.name)
                                                                .join(", ")}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Stack>
        </>
    );
}
