import { FormControlLabel, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { TextField, Checkbox } from "formik-mui";
import { createEnrollment } from "../UserActions";
import { global, profilePage } from "../../../context/global";
import { Capitalize } from "../../../functions/Formatters";

export default function EnrollRequestComponent({ setShowForm }) {
    const dispatch = useDispatch();
    return (
        <Formik
            initialValues={{
                name: "",
                email: "",
                organisation: "",
                role: "",
                projects: "",
                mailing_duda: false,
                mailing_roadmap: false,
            }}
            validationSchema={Yup.object({
                name: Yup.string().required("Naam mag niet leeg zijn"),
                email: Yup.string().email("Ongeldig e-mailadres").required("E-mail mag niet leeg zijn"),
                organisation: Yup.string(),
                role: Yup.string(),
                projects: Yup.string(),
                mailing_duda: Yup.boolean(),
                mailing_roadmap: Yup.boolean(),
            })}
            onSubmit={(values) => {
                createEnrollment(values, dispatch);
                setShowForm(false);
            }}
        >
            {({ isValid }) => (
                <>
                    <Form>
                        <Typography>
                        Uw registratie moet eerst worden gecontroleerd voordat deze operationeel is en aan een omgeving is toegekend. U ontvangt een e-mail wanneer uw registratie is geaccepteerd.
                        </Typography>
                        <Typography variant="h6">Contactgegevens</Typography>
                        <Field component={TextField} name="name" label="Naam" autoFocus />
                        <Field component={TextField} name="email" label="Email" type="email" />
                        <Typography variant="h6">{Capitalize(global.employer)}</Typography>
                        <Field
                            component={TextField}
                            name="organisation"
                            label={Capitalize(global.employer)}
                        />
                        <Field component={TextField} name="role" label="Functie" />
                        <Field component={TextField} fullWidth multiline minRows={2} placeholder={"-project\n-project"} name="projects" label="Projecten" />

                        <Typography variant="h6">Mailinglijsten</Typography>
                        <FormControlLabel
                            control={<Field component={Checkbox} name="mailing_duda" type="checkbox" />}
                            label={profilePage.dudaMailing}
                        />
                        <FormControlLabel
                            control={<Field component={Checkbox} name="mailing_roadmap" type="checkbox" />}
                            label={profilePage.roadmapMailing}
                        />
                        <br />
                        <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                            Verstuur
                        </Button>
                    </Form>
                </>
            )}
        </Formik>
    );
}
