import { Capitalize, Pluralize } from "../functions/Formatters";

export const global = {
    theme: "thema",
    supertheme: "hoofdthema",
    subtheme: "aspect",
    project: "project",
    application: "applicatie",
    admin: "beheerder",
    user: "gebruiker",
    appAdmin: "applicatiebeheerder",
    orgAdmin: "organisatiebeheerder",
    adminRights: "beheerrechten",
    organisation: "omgeving",
    yes: "ja",
    no: "nee",
    cancel: "annuleren",
    close: "sluiten",
    save: "opslaan",
    delete: "verwijderen",
    edit: "bewerken",
    view: "bekijken",
    add: "toevoegen",
    added: "toegevoegd",
    refresh: "verversen",
    previous: "vorige",
    next: "volgende",
    step: "stap",
    new: "nieuw",
    actions: "acties",
    my: "mijn",
    name: "naam",
    level: "niveau",
    description: "beschrijving",
    number: "nummer",
    reset: "reset",
    and: "en",
    introduction: "Introductie",
    feedback: "Feedback",
    date: "Datum",
    email: "Email",
    enrollment: "inschrijving",
    employer: "organisatie",
    role: "functie",
};

export const appName = {
    full: "Dashboard Duurzaam HWBP",
    abbreviated: "DaDuHWBP",
};

export const profilePage = {
    title: "Profiel",
    personalData: "Persoongegevens",
    name: "Naam",
    email: "Email",
    password: "Wachtwoord",
    changePassword: "Wachtwoord wijzigen",
    roles: "Rollen",
    superuser: "Superuser",
    appAdmin: "Applicatiebeheerder",
    organisations: Pluralize(Capitalize(global.organisation)),
    noOrganisations: `U bent geen lid van een ${global.organisation}.`,
    logout: "Uitloggen",
    fetchData: "Gegevens ophalen",
    fetchError: "Er is een fout opgetreden bij het ophalen van uw gegevens",
    saveError: "Er is een fout opgetreden bij het opslaan van uw gegevens",
    saveSuccess: "Uw gegevens zijn succesvol opgeslagen",
    mailings: "Mailing voorkeuren",
    dudaMailing: "Mailing rondom het Dashboard Duurzaam HWBP",
    roadmapMailing: "Mailing rondom de Roadmap Duurzaam HWBP",
    employer: "Organisatie",
};

export const loginPage = {
    title: "Inloggen",
    email: "Email",
    password: "Wachtwoord",
    login: "Inloggen",
    loginError: "Er is een fout opgetreden bij het inloggen",
    loginSuccess: "U bent succesvol ingelogd",
    logout: "U bent succesvol uitgelogd",
};

export const OrgAdminPage = {
    title: `${global.organisation} beheer`,
    helperTextSelectOrganisation: `Selecteer een ${global.organisation}`,
};

export const userOverview = {
    title: "Gebruikers",
    helperTextSelectOrganisation: `Selecteer een ${global.organisation}`,
    helperTextSelectRole: "Selecteer een rol",
    filterAppAdmins: `Toon alleen ${Pluralize(global.appAdmin)}`,
    refreshUsers: `${Pluralize(global.user)} verversen`,
    refreshOrgs: `${Pluralize(global.organisation)} verversen`,
    addUser: `Nieuwe ${global.user}`,
};

export const customTextField = {
    incorrectValue: "Alleen cijfers zijn toegestaan.",
};

export const importanceLevels = [
    { value: 0, label: "Geen" },
    { value: 1, label: "Norm" },
    { value: 2, label: "Verbetering" },
    { value: 3, label: "Maximale inspanning" },
];

export const scoreLevels = [
    { value: 1, label: "Sterke verslechtering" },
    { value: 2, label: "Verslechtering" },
    { value: 3, label: "Blijft gelijk" },
    { value: 4, label: "Verbetering" },
    { value: 5, label: "Sterke verbetering" },
];

export const projectStatus = [
    { value: 0, label: "Begin" },
    { value: 1, label: "Onderdelen" },
    { value: 2, label: "Thema's" },
    { value: 3, label: "Doelen" },
    { value: 4, label: "Ontwerpen" },
];

export const phaseTypes = [
    { value: 0, label: "Verkenningsfase" },
    { value: 1, label: "Planfase" },
    { value: 2, label: "Voer een eigen naam in" },
];

export const romanNumbers = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
    "XIII",
    "XIV",
    "XV",
    "XVI",
    "XVII",
    "XVIII",
    "XIX",
    "XX",
];

export const letterNumbering = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t"];
