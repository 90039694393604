import React, { useState } from "react";
import { Button, ButtonGroup, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { Save, ArrowBack } from "@mui/icons-material";
import { global, projectStatus } from "../../context/global";
import { advanceStatus } from "./ProjectActions";
import { useDispatch } from "react-redux";

export default function StepperButtons({ payload, saveAction, saveActionArguments, updateStatus = true, warningPopup = false }) {
    const dispatch = useDispatch();
    const editmode = payload.editable && saveAction;
    const [showPopup, setShowPopup] = useState(false);
    const handleNextStep = () => {
        if (editmode) {
            if (saveActionArguments) {
                saveAction(saveActionArguments);
            } else {
                saveAction();
            }
        }
        if (updateStatus) {
            advanceStatus(payload.project.id, { status: payload.activeStep.step }, dispatch);
            payload.handleNextStep();
        } else if (warningPopup) {
            setShowPopup(true);
        } else {
            payload.handleNextStep();
        }
    };
    return (
        <>
            <Dialog open={showPopup}>
                <DialogTitle>{projectStatus[payload.activeStep.step]?.label} vergrendelen</DialogTitle>
                <DialogContent>
                    <Typography>
                        Wil je de {projectStatus[payload.activeStep.step]?.label} vergrendelen? Dit is noodzakelijk om latere stappen te kunnen
                        voltooien, maar kan alleen teruggedraaid worden door organisatie administrators.
                        <br />
                        <br />
                        {payload.activeStep.step >= 1 ? "Dit vergrendeld ook alle voorgaande stappen" : ""}
                    </Typography>
                </DialogContent>
                <ButtonGroup sx={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Button
                        onClick={() => {
                            setShowPopup(false);
                        }}
                    >
                        <ArrowBack />
                        Terug
                    </Button>
                    <Button
                        onClick={() => {
                            setShowPopup(false);
                            payload.handleNextStep();
                        }}
                    >
                        Niet vast zetten
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            advanceStatus(payload.project.id, { status: payload.activeStep.step }, dispatch);
                            setShowPopup(false);
                            payload.handleNextStep();
                        }}
                    >
                        Vast zetten
                    </Button>
                </ButtonGroup>
            </Dialog>

            <ButtonGroup sx={{ marginTop: "30px", display: "block" }}>
                {payload.activeStep.phase === 0 && payload.activeStep.loop === 0 && payload.activeStep.step === 0 ? (
                    ""
                ) : (
                    <Button sx={{ paddingLeft: "25px", paddingRight: "25px" }} onClick={payload.handlePreviousStep}>
                        Vorige stap
                    </Button>
                )}
                {(payload.activeStep.phase < payload.project.phases.length ||
                    (payload.activeStep.phase - 1 >= 0 &&
                        payload.activeStep.loop < payload.project.phases[payload.activeStep.phase - 1].loops.length - 1) ||
                    payload.activeStep.step < payload.steps[payload.activeStep.phase].steps.length - 1) && (
                    <Button sx={{ paddingLeft: "25px", paddingRight: "25px" }} onClick={handleNextStep}>
                        {editmode ? (
                            <>
                                <Save />
                                {global.save} {global.and}
                            </>
                        ) : (
                            " "
                        )}{" "}
                        {global.next} {global.step}
                    </Button>
                )}
            </ButtonGroup>
        </>
    );
}
