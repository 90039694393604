import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import React from "react";
import { Tooltip } from "@mui/material";
/**
 * StyledLink Component
 *
 * This React component creates a styled link element that can be used for navigation in the application.
 * It allows custom styling of the link's text and behavior based on the current location. This component
 * is typically used for rendering links in menus or other parts of the user interface.
 *
 * @component
 * @param {Object} props - The properties to configure the link's appearance and behavior.
 * @param {string} props.to - The destination URL to navigate to when the link is clicked.
 * @param {string} props.text - The text content of the link.
 * @param {boolean} props.inMenuItem - Indicates whether the link is used within a menu item.
 * @param {string} props.rel - Indicates whether the link is used within a menu item.
 * @param {string} props.target - Indicates whether the link is used within a menu item.
 *
 * @returns {JSX.Element} A styled link element with custom styling based on the provided props.
 */
export default function StyledLink({ to, text, inMenuItem = false, rel = undefined, target = undefined, small = false, margin, description }) {
    const theme = useTheme();
    let style = {
        textDecoration: "none",
        color: inMenuItem ? theme.palette.text.darkblue : theme.palette.text.white,
        fontSize: small ? "0.9rem" : "1.2rem",
        fontWeight: small ? "" : 550,
        margin: margin ? margin : "0 1rem 0 0",
    };
    if (location.pathname === to || location.pathname.startsWith(to + "/")) {
        style = {
            ...style,
            textDecoration: "underline",
            fontSize: small ? "1rem" : "1.2rem",
        };
    }
    if (rel && target) {
        return (
            <Tooltip title={description}>
                <Link to={to} style={style} target={target} rel={rel}>
                    {text}
                </Link>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={description}>
                <Link to={to} style={style}>
                    {text}
                </Link>
            </Tooltip>
        );
    }
}
