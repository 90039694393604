import React, { useState } from "react";
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import QuantGraphDetailed from "../../../Charts/QuantGraphDetailed";
import QuantGraphGlobal from "../../../Charts/QuantGraphGlobal";
import { useTheme } from "@emotion/react";

export default function QuantThemeTab(props) {
    const designs = props.designs;
    const projectTheme = props.projectTheme;
    const variantNames = designs.map((design) => design.name);
    const projectLayers = props.projectLayers;
    const projectSegments = props.projectSegments;

    const theme = useTheme();

    const flatKpis = useSelector((state) => state.themes.flatKpis);
    const flatSubThemes = useSelector((state) => state.themes.flatSubThemes);

    const [segmentFilter, setSegmentFilter] = useState(projectSegments.map((segment) => segment.id));
    const showGoalLine = projectSegments.length === segmentFilter.length;

    const detailedScoreDataConstructor = (designs, projectThemeID, projectSubthemeID, goalIndex) => {
        let formattedData = [];
        try {
            formattedData = projectLayers.map((layer, layerIndex) => ({
                label: layer.name,
                data: designs.map((design) =>
                    design.scores_by_themes[projectThemeID][projectSubthemeID][goalIndex]?.value
                        .filter((segment) => segmentFilter.includes(segment.segment) || segment.segment === "total")
                        .reduce(
                            (acc, segment) =>
                                acc +
                                Number(
                                    segment.values
                                        .filter((layerScore) => layerScore.layer === layer.id)
                                        .reduce((acc, layerScore) => acc + layerScore.value, 0)
                                ),
                            0
                        )
                ),
                type: "bar",
                backgroundColor: Object.values(theme.palette.graphics)[layerIndex],
            }));
        } catch (error) {
            console.log(`Showing global score for goal ${goalIndex} of subtheme ${projectSubthemeID}`);

            formattedData = [
                {
                    label: "Totaal",
                    data: designs.map((design) => {
                        let value = "noscores";
                        try {
                            value = design.scores_by_themes[projectThemeID][projectSubthemeID][goalIndex]?.value;
                        } catch (error) {
                            console.log("Ontbrekend score object voor ontwerp:", design.name);
                        }

                        if (value === null) {
                            console.log("Geen data voor ontwerp:", design.name);
                            return 0;
                        } else if (typeof value === "object") {
                            try {
                                return Object.values(value).reduce((acc, val) => acc + val.value, 0);
                            } catch (error) {
                                console.log("data structuur verkeerd voor ontwerp:", design.name);
                                return 0;
                            }
                        } else {
                            return 0;
                        }
                    }),
                    type: "bar",
                },
            ];
        }
        return formattedData;
    };

    const globalScoreDataConstructor = (designs, projectThemeID, projectSubthemeID, goalIndex) => ({
        label: "",
        data: designs.map((design) => design.scores_by_themes[projectThemeID][projectSubthemeID][goalIndex].value),
        type: "bar",
        backgroundColor: Object.values(theme.palette.graphics)[0],
    });

    const handleFilterChange = (event) => {
        setSegmentFilter(event.target.value);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack>
                        <Typography variant="h6" gutterBottom>
                            Filter
                        </Typography>
                        <FormControl>
                            <InputLabel id="segment-filter-label">Dijkvakken</InputLabel>
                            <Select
                                autoWidth
                                multiple
                                multiline
                                label={"Dijkvakken"}
                                value={segmentFilter}
                                sx={{width: 'fit-content'}}
                                onChange={handleFilterChange}
                                renderValue={(selected) => (
                                    <Box sx={{ display: "inline-flex", flexWrap: segmentFilter.length < 10 ? "no-wrap" : "wrap", gap: 0.2 }}>
                                        {selected.map((value) => (
                                            <Chip
                                                size="small"
                                                key={value}
                                                label={projectSegments.find((segment) => segment.id === value).name}
                                                color="primary"
                                            />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                }}
                            >
                                {projectSegments.map((segment) => (
                                    <MenuItem key={segment.id} value={segment.id}>
                                        {segment.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* <FormControlLabel control={<Switch checked={showGoalLine} onChange={handleCheckChange} />} label={"Toon doel"} /> */}
                    </Stack>
                </Grid>
                {designs[0]?.scores_by_themes[projectTheme.id] &&
                    Object.keys(designs[0].scores_by_themes[projectTheme.id]).map((projectSubThemeID) => {
                        const projectSubTheme = projectTheme.subthemes.find((subtheme) => subtheme.id.toString() === projectSubThemeID);
                        const subtheme = flatSubThemes[projectSubTheme.subtheme];
                        return (
                            <>
                                {" "}
                                <Grid item container xs={12}>
                                    <Typography key={`subtheme_${projectSubThemeID}`} variant="h6">
                                        {subtheme.name}
                                    </Typography>
                                </Grid>
                                {Object.values(designs[0].scores_by_themes[projectTheme.id.toString()][projectSubThemeID]).map((goal, goalIndex) => {
                                    const kpi = flatKpis[goal.kpi];
                                    const projectGoal = projectSubTheme.goals.find((goal) => goal.kpi === kpi.id);
                                    if (kpi.scale_type > 0) {
                                        return (
                                            <Grid sx={{ maxHeight: 400, minHeight: 200, minWidth: 400 }} item xs={12} xl={6} key={`subtheme_${projectSubThemeID}_goal_${goal.id}`}>
                                                {kpi.scale_type === 1 ? (
                                                    <QuantGraphDetailed
                                                        key={`goal_${goal.id}`}
                                                        variants={variantNames}
                                                        layers={projectLayers}
                                                        segments={projectSegments}
                                                        initData={detailedScoreDataConstructor(
                                                            designs,
                                                            projectTheme.id.toString(),
                                                            projectSubThemeID,
                                                            goalIndex
                                                        )}
                                                        segmentFilter={segmentFilter}
                                                        kpi={kpi}
                                                        goal={projectGoal}
                                                        showGoalLine={showGoalLine}
                                                    />
                                                ) : (
                                                    <QuantGraphGlobal
                                                        variants={variantNames}
                                                        initData={globalScoreDataConstructor(
                                                            designs,
                                                            projectTheme.id.toString(),
                                                            projectSubThemeID,
                                                            goalIndex
                                                        )}
                                                        kpi={kpi}
                                                        goal={projectGoal}
                                                    />
                                                )}
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </>
                        );
                    })}
            </Grid>
        </>
    );
}
