import { createSlice } from "@reduxjs/toolkit";

const initialState = { designs: [], referenceDesign: {} };

const designSlice = createSlice({
    name: "designs",
    initialState,
    reducers: {
        setReferenceDesign(state, action) {
            state.referenceDesign = action.payload;
        },
        setDesigns(state, action) {
            state.designs = action.payload;
        },
        updateDesigns(state, action) {
            state.designs.map((design) => {
                if (design.id === action.payload.id) {
                    design.name = action.payload.name;
                    design.description = action.payload.description;
                    design.is_prefered = action.payload.is_prefered;
                    design.scores = action.payload.scores;
                }
            });
        },
        addDesign(state, action) {
            let design = action.payload;
            if (!state.designs.find((existing_design) => existing_design.id === design.id)) {
                state.designs.push(design);
            }
        },
        removeDesign(state, action) {
            const new_designs = state.designs.filter((existing_design) => existing_design.id !== action.payload);
            state.designs = new_designs;
            if (state.referenceDesign.id === action.payload) {
                state.referenceDesign = {};
            }
        },
        updateScores(state, action) {
            if (state.referenceDesign.id === action.payload.design) {
                state.referenceDesign.scores = state.referenceDesign.scores.map((score) => (score.id === action.payload.id ? action.payload : score));
            } else {
                const old_design = state.designs.find((design) => design.id === action.payload.design);
                const new_design = {
                    ...old_design,
                    scores: old_design.scores.map((score) => (score.id === action.payload.id ? action.payload : score)),
                };

                state.designs = state.designs.map((design) => (design.id === new_design.id ? new_design : design));
            }
        },
    },
});

export const { setReferenceDesign, setDesigns, updateDesigns, addDesign, removeDesign, addScores, updateScores } = designSlice.actions;
export default designSlice.reducer;
