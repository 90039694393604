import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, ButtonGroup, Icon, Paper, Stack, Step, StepButton, StepContent, Stepper, Tooltip, Typography } from "@mui/material";
import { ArrowBack, Info, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import ProjectSummary from "./PreparationPhase/Step_1_ProjectSummary";
import ProjectThemes from "./PreparationPhase/Step_3_ProjectThemes";
import ProjectGoals from "./PreparationPhase/Step_4_ProjectGoals";
import ProjectReference from "./PreparationPhase/Step_5_ProjectReference";
import LayerSelect from "./PreparationPhase/Step_2_LayerSelect";

import ProjectAdvice from "./DesignPhase/Step_1_Advice/Advice";
import DesignScoreTabs from "./DesignPhase/Step_2_Designs/DesignScoreTabs";
import GraphTabs from "./DesignPhase/Step_3_Compare/GraphTabs";
import DesignDiscussion from "./DesignPhase/Step_4_Discuss/DesignDiscussion";

import WIP from "../../pages/WorkInProgress";
import Error from "../../pages/ErrorPage";
import ViewSegments from "./ViewSegments";

import { addLoopToPhase, advanceStatus } from "./ProjectActions";
import SegmentsSelect from "./DesignPhase/SegmentsSelect";
import { romanNumbers, letterNumbering } from "../../context/global";
import StartDesignPhase from "./PreparationPhase/Step_6_StartDesignPhase";

export default function ProjectMain({ project }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [segmentDialogOpen, setSegmentDialogOpen] = useState(false);
    const [preferredDesign, setpreferredDesign] = useState(undefined);
    const preparationSteps = ["Algemeen", "Onderdelen", "Thema's", "Doelen", "Referentie", "Ontwerpproces starten"];
    const explorationSteps = ["Adviseren", "Toetsen", "Vergelijken", "Beoordelen"];
    const [openSegments, setOpenSegments] = useState(false)

    const steps = [
        { label: "Opstart", loops: [{ label: "", steps: preparationSteps }], steps: preparationSteps },
        ...project.phases.map((phase) => ({
            label: phase.phase_name,
            loops: phase.loops.map((loop, loopIndex) => ({ ...loop, steps: explorationSteps, label: `Ontwerploop ${loopIndex + 1}` })),
            steps: explorationSteps,
        })),
    ];
    const getLastLoopID = (project) => {
        const lastPhase = project?.phases.slice(-1)[0];
        return lastPhase?.loops?.slice(-1)[0]?.id;
    }

    const [activeStep, setActiveStep] = useState({ phase: 0, loop: 0, step: 0 });

    useEffect(() => {
        const currentPhase = project.phases.length;
        const currentLoop = currentPhase === 0 ? 0 : project.phases[currentPhase - 1].loops.length - 1;
        if (activeStep.phase !== 0) {
            setActiveStep({ phase: currentPhase, loop: currentLoop, step: 0 });
        }
    }, [project.phases.length]);

    const handleNextStep = () => {
        if (activeStep.step < steps[activeStep.phase].steps.length - 1) {
            setActiveStep({ ...activeStep, step: activeStep.step + 1 });
        } else if (activeStep.loop < steps[activeStep.phase].loops.length - 1) {
            setActiveStep({ ...activeStep, step: 0, loop: Math.min(activeStep.loop + 1, steps[activeStep.loop].steps.length - 1) });
        } else {
            setActiveStep({ step: 0, loop: 0, phase: Math.min(activeStep.phase + 1, project.phases.length) });
        }
    };
    const handlePreviousStep = () => {
        if (activeStep.step > 0) {
            setActiveStep({ ...activeStep, step: activeStep.step - 1 });
        } else if (activeStep.loop > 0) {
            setActiveStep({
                ...activeStep,
                loop: Math.max(activeStep.loop - 1, 0),
                step: 0,
            });
        } else {
            setActiveStep({
                phase: Math.max(activeStep.phase - 1, 0),
                loop: Math.max(activeStep.loop - 1, 0),
                step: Math.max(steps[activeStep.loop].steps.length - 1, 0),
            });
        }
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const handleAdvancePhase = (design) => {
        if (design) {
            setpreferredDesign(design);
        }
        setSegmentDialogOpen(true);
    };
    const handleAddDesignLoop = () => {
        addLoopToPhase(project.id, dispatch);
    };

    const defaultPhaseName = (index) => {
        switch (index) {
            case 0:
                return "Opstart";
            case 1:
                return "Verkenningsfase";
            case 2:
                return "Planfase";
            default:
                return "Fase" + index;
        }
    };
    const ProjectStepper = ({ step, setStep }) => {
        return (
            // Map the phases
            <Stepper nonLinear activeStep={step.phase} orientation="vertical">
                {steps.map((phase, phaseIndex) => (
                    <Step key={phaseIndex}>
                        <StepButton onClick={setStep({ phase: phaseIndex, loop: 0, step: 0 })}>
                            {phase.label || defaultPhaseName(phaseIndex)}
                        </StepButton>
                        <StepContent>
                            {/* Map the Loops */}
                            <Stepper nonLinear activeStep={step.loop} orientation="vertical">
                                {phase.loops &&
                                    phase.loops.map((loop, loopIndex) => (
                                        <Step key={loopIndex}>
                                            {phaseIndex > 0 && (
                                                <StepButton
                                                    icon={romanNumbers[loopIndex]}
                                                    onClick={setStep({
                                                        phase: phaseIndex,
                                                        loop: loopIndex,
                                                        step: 0,
                                                    })}
                                                >
                                                    {loop.label}
                                                </StepButton>
                                            )}
                                            <StepContent>
                                                <Stepper nonLinear activeStep={step.step} orientation="vertical">
                                                    {loop.steps.map((step, stepIndex) => (
                                                        <Step
                                                            key={stepIndex}
                                                            completed={
                                                                phaseIndex === 0
                                                                    ? stepIndex === 4
                                                                        ? !!project.reference_design
                                                                        : project.status > stepIndex
                                                                    : false
                                                            }
                                                        >
                                                            <StepButton
                                                                icon={letterNumbering[stepIndex]}
                                                                onClick={setStep({
                                                                    phase: phaseIndex,
                                                                    loop: loopIndex,
                                                                    step: stepIndex,
                                                                })}
                                                            >
                                                                {step}
                                                            </StepButton>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            </StepContent>
                                        </Step>
                                    ))}
                                    
                            </Stepper>
                        </StepContent>
                    </Step >
                ))
                }
            </Stepper >
        );
    };

    const lockAndUnlockPage = (activeStep) => {
        if (activeStep.step > 0 && activeStep.step < 4) {
            if ((activeStep.phase === 0 && project.status > activeStep.step) || project.phases.length > activeStep.phase) {
                return (project.user_is_orgadmin || project.user_is_owner) ? (
                    <Tooltip
                        title={
                            "Ontgrendel informatie in dit project vanaf dit punt. Let op: dit kan complicaties veroorzaken in latere stappen van dit project!"
                        }
                    >
                        <Button onClick={() => advanceStatus(project.id, { status: activeStep.step - 1 }, dispatch)}>
                            <Lock />
                            Ontgrendelen
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title={"Neem contact op met een project eigenaar om informatie te ontgrendelen "}>
                        <Lock />
                    </Tooltip>
                );
            }
        }
    };

    const renderSwitch = (step) => {
        const payload = {
            project: project,
            activeStep: activeStep,
            handlePreviousStep: handlePreviousStep,
            steps: steps,
            handleNextStep: handleNextStep,
            editable: project.user_is_owner || project.user_is_orgadmin,
            phaseIndex: step.phase - 1,
            phase: project.phases[step.phase - 1],
            lastLoopID: getLastLoopID(project),
        };
        if (step.phase === 0) {
            // Preparation phase
            switch (step.step) {
                case 0:
                    return <ProjectSummary payload={payload} />;
                case 1:
                    return <LayerSelect payload={payload} />;
                case 2:
                    return <ProjectThemes payload={payload} />;
                case 3:
                    return <ProjectGoals payload={payload} />;
                case 4:
                    return <ProjectReference payload={payload} />;
                case 5:
                    return <StartDesignPhase payload={payload} handleAdvancePhase={handleAdvancePhase} />;
                default:
                    return <Error />;
            }
        }
        else {
            //Exploration/design phase
            payload.loop = project.phases[step.phase - 1].loops[step.loop];
            payload.loopIndex = step.loop;
            switch (step.step) {
                case 0:
                    return <ProjectAdvice payload={payload} />;
                case 1:
                    return <DesignScoreTabs payload={payload} />;
                case 2:
                    return <GraphTabs payload={payload} />;
                case 3:
                    return <DesignDiscussion payload={payload} handleAdvancePhase={handleAdvancePhase} handleAddDesignLoop={handleAddDesignLoop} />
                default:
                    return <Error />;
            }
        }
    };

    return (
        <>
            <SegmentsSelect
                project={project}
                isFirstPhase={project.phases.length === 0}
                design={preferredDesign}
                open={segmentDialogOpen}
                onClose={() => setSegmentDialogOpen(false)}
            />
            {steps[activeStep.phase]?.steps[activeStep?.step] ? (
                <Stack direction="row" spacing={1} component={Paper} width={"100%"} minWidth={"min-content"}>
                    <Stack>
                        <ButtonGroup>
                            <Button variant="outlined" onClick={() => navigate("/")}>
                                <ArrowBack />
                                Terug
                            </Button>
                        </ButtonGroup>
                        <ProjectStepper step={activeStep} setStep={handleStep} />
                    </Stack>
                    <Box>
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                {project.name} - {steps[activeStep.phase].steps[activeStep.step]}
                            {lockAndUnlockPage(activeStep)}
                            </Typography>
                            {activeStep.phase > 0 && <>
                                        <ViewSegments open={openSegments} setOpen={setOpenSegments} project={project} phaseIndex={activeStep.phase} />
                                        <Button
                                        startIcon={<Info />}
                                            onClick={() => setOpenSegments(true)}
                                        >
                                            Segmenten bekijken
                                        </Button>
                                    </>}
                        </Stack>
                        {renderSwitch(activeStep)}
                    </Box>
                </Stack>
            ) : null}
        </>
    );
}
