import { Paper, Container, Typography, Dialog, DialogActions, Button, Table, TableHead, TableCell, TableBody, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { global } from "../../context/global";

export default function ViewSegments({ open, setOpen, project, phaseIndex }) {
    return (
        <Dialog open={open} maxWidth="xl">
            <Typography variant="h4" component="h1" gutterBottom>
                Segmenten
            </Typography>
            <Table>
                <TableHead>
                    <TableCell width={"150px"}>Naam</TableCell>
                    <TableCell width={"150px"}>Lengte</TableCell>
                </TableHead>
                <TableBody>
                    {project.phases[phaseIndex-1]?.segments.map(segment => (
                        <TableRow key={segment.id}>
                            <TableCell>
                                {segment.name}
                            </TableCell>
                            <TableCell>
                                {segment.length} meter
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <DialogActions>
                <Button onClick={() => { setOpen(false) }}>{global.close}</Button>
            </DialogActions>
        </Dialog>
    );
}
