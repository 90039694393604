import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthenticatedRoute = ({ children }) => {
    const auth = useSelector((state) => state.auth);

    if (auth.account) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return <Navigate to="/login" />;
    }
};

export default AuthenticatedRoute;
