import React from "react";
import { useDispatch, useSelector } from "react-redux"; // Import Redux hooks
import * as Yup from "yup";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { createSubTheme, updateSubThemeAction } from "./ThemeActions";

/**
 * SubThemeDialog component for creating or editing a sub-theme.
 *
 * @param {Object} props - Props object.
 * @param {boolean} props.open - Determines if the dialog is open.
 * @param {function} props.onClose - Callback to close the dialog.
 * @param {Object} props.edit - The sub-theme to edit, if provided.
 */
export default function SubThemeDialog({ open, onClose, edit = false, theme }) {
    const dispatch = useDispatch(); // Get the dispatch function from Redux

    const defaultValues = {
        name: !edit ? "" : edit.name,
        description: !edit ? "" : edit.description,
    };

    /**
     * Handles the submission of the sub-theme form.
     *
     * @param {string} name - The name of the sub-theme.
     * @param {string} description - The description of the sub-theme.
     */
    const handleSubmitSubTheme = (name, description) => {
        if (edit) {
            // If editing an existing sub-theme, call the updateSubThemeAction
            updateSubThemeAction(
                {
                    id: edit.id,
                    name: name,
                    description: description,
                },
                dispatch,
                onClose
            );
        } else {
            // If creating a new sub-theme, call the createSubTheme action
            createSubTheme(
                {
                    name: name,
                    description: description,
                    theme: theme.id,
                },
                dispatch,
                onClose
            );
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={"new_or_edit_subtheme_dialog"} maxWidth={"md"}>
            <Formik
                initialValues={{
                    name: defaultValues.name,
                    description: defaultValues.description,
                }}
                validationSchema={Yup.object({
                    name: Yup.string().trim().required("Naam is vereist"),
                    description: Yup.string().trim(),
                })}
                onSubmit={(values) => {
                    handleSubmitSubTheme(values.name, values.description);
                }}
            >
                {({ isValid }) => (
                    <Form>
                        <DialogTitle>{!edit ? "Nieuw subthema" : "Subthema bewerken"}</DialogTitle>
                        <DialogContent>
                            <Stack spacing={2} sx={{ pt: 2 }}>
                                <Field
                                    component={TextField}
                                    name="name"
                                    label="Naam"
                                    type="text"
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                    autoFocus
                                    sx={{ width: 400 }}
                                />
                                <Field
                                    component={TextField}
                                    name="description"
                                    label="Beschrijving"
                                    type="text"
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                                Subthema opslaan
                            </Button>
                            <Button onClick={() => onClose()} color="primary">
                                Annuleren
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
