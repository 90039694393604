import { createSlice } from "@reduxjs/toolkit";

const initialState = { token: null, refreshToken: null, account: null, files: [] };

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthTokens(state, action) {
            state.refreshToken = action.payload.refreshToken;
            state.token = action.payload.token;
        },
        setAccount(state, action) {
            state.account = action.payload;
        },
        setWelcomeDialog(state, action) {
            state.account = {
                ...state.account,
                is_first_login: action.payload,
            };
        },
        setFiles(state, action) {
            state.files = action.payload;
        },
        logout(state) {
            state.account = null;
            state.refreshToken = null;
            state.token = null;
            state.files = [];
        },
    },
});

export const { setAuthTokens, setAccount, logout, setWelcomeDialog, setFiles } = authSlice.actions;
export default authSlice.reducer;
