import React from "react";
import { Autocomplete, Checkbox, FormControl, Stack, TextField } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

export default function MultiSelectFilter({ values, options, setValue, label }) {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    return (
        <Stack direction={"row"}>
            <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                    ChipProps={{
                        color: "primary",
                    }}
                    multiple
                    value={values}
                    onChange={handleChange}
                    sx={{ minWidth: 200 }}
                    fullWidth
                    options={options}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    disableCloseOnSelect
                    limitTags={3}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                />
            </FormControl>
        </Stack>
    );
}
