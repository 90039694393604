import { toast } from "react-toastify";
import { fetchOrgs } from "../components/OrganisationManagement/OrganisationActions";
import { fetchProjects } from "../components/Projects/ProjectActions";
import { fetchUsers } from "../components/UserManagement/UserActions";
import { logout } from "./slices/authSlice";
import { clearOrganisations } from "./slices/organisationsSlice";
import { clearProjects } from "./slices/projectsSlice";
import { clearUsers } from "./slices/usersSlice";
import { fetchThemes } from "../components/ThemeManagement/ThemeActions";
import { clearThemes } from "./slices/themesSlice";
import { fetchFiles } from "../components/Attachments/AttachmentActions";

export const fetchAllObjects = (dispatch) => {
    fetchOrgs(dispatch);
    fetchProjects(dispatch);
    fetchUsers(dispatch);
    fetchThemes(dispatch);
    fetchFiles(dispatch);
    toast.success("Ingelogd");
};

export const handleLogout = (dispatch) => {
    console.log("Logging out...");
    dispatch(clearOrganisations());
    dispatch(clearProjects());
    dispatch(clearUsers());
    dispatch(clearThemes());
    dispatch(logout());
    toast.success("Uitgelogd");
};
