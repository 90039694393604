import React from "react";
import { Box, Tab, Tabs, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import SuperThemeTab from "./SuperThemeTabContent";
import { CustomTabPanel, a11yProps } from "../../../CustomComponents/CustomTabPanel";
import ScoreGraphCost from "../../../Charts/ScoreGraphCost";
import StepperButtons from "../../StepperButtons";
import InformationDialogue from "../../../CustomComponents/InformationDialogue";
import { compare } from "../../../../context/designPhase";

export default function GraphTabs({ payload }) {
    const superThemes = useSelector((state) => state.themes.superthemes).concat([{ id: 4, name: "Kosten" }])
    const projectLayers = payload.project.potential_layers.filter((pl) => payload.project.layers.includes(pl.id));
    const [superThemeTabValue, setSuperThemeTabValue] = useState(`${superThemes[0].id}`);
    const handleChangeSuperTab = (event) => {
        setSuperThemeTabValue(event.target.id);
    };
    let designs = payload.loop.designs;
    if (payload.project.reference_design) {
        designs = [payload.project.reference_design, ...designs];
    }

    if (payload.loop.designs.length === 0) {
        return (
            <Box>
                <Typography>Er zijn nog geen ontwerpen voor deze ontwerploop. Voeg een ontwerp toe om de scores te kunnen bekijken.</Typography>
                <StepperButtons payload={payload} />
            </Box>
        );
    }
    return (
        <Box>
            <InformationDialogue text={compare}/>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 0 }}>
                <Tabs
                    value={superThemeTabValue}
                    onChange={handleChangeSuperTab}
                    aria-label="tabs_container"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {superThemes.map((supertheme) => {
                        return (
                            <Tab
                                key={`supertheme_tab_${supertheme.name}`}
                                label={supertheme.name}
                                {...a11yProps(supertheme.id)}
                                value={`${supertheme.id}`}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            {superThemes.map((supertheme) => {
                if (supertheme.name === "Kosten") {
                    return (
                        <CustomTabPanel key={`tab_supertheme_${supertheme.id}`} value={superThemeTabValue} index={`${supertheme.id}`}>
                            <Grid item xs={12} sx={{ maxHeight: 350, minHeight: 250 }}>
                                <ScoreGraphCost designs={designs} variants={designs.map((design) => design.name)} />
                            </Grid>
                        </CustomTabPanel>
                    );
                } else {
                    return (
                        <CustomTabPanel key={`tab_supertheme_${supertheme.id}`} value={superThemeTabValue} index={`${supertheme.id}`}>
                            <SuperThemeTab
                                supertheme={supertheme}
                                designs={designs}
                                projectLayers={projectLayers}
                                projectSegments={payload.phase.segments}
                            />
                        </CustomTabPanel>
                    );
                }
            })}
            <StepperButtons payload={payload} updateStatus={false} />
        </Box>
    );
}
