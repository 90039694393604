import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";

export default function ResetPasswordDialog({ open, handleClose, handleReset, email, setEmail }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Reset wachtwoord</DialogTitle>
            <DialogContent>
                <Typography>
                    Vul het email in van de account waarvan je het wachtwoord wilt resetten. Er wordt een email gestuurd naar het opgegeven email
                    adres met een link om het wachtwoord te resetten.
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuleren</Button>
                <Button onClick={() => handleReset(email)}>Reset</Button>
            </DialogActions>
        </Dialog>
    );
}
