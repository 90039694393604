// Import React and necessary components from the Material-UI library
import React, { useEffect } from "react";
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
    TextField,
    IconButton,
} from "@mui/material";

// Import the 'global' context from a custom context named 'global'
import { global } from "../context/global";
import { Cancel } from "@mui/icons-material";

/**
 * ConfirmDeleteDialog Component
 *
 * This React component represents a confirmation dialog for deleting items or performing
 * critical actions in the application. It provides a dialog box with a customizable title,
 * content, and buttons for confirming or canceling the action. The appearance and behavior
 * of this dialog can be customized by passing various props.
 *
 * @component
 * @param {Object} props - The properties to configure the dialog's appearance and behavior.
 * @param {boolean} props.open - Controls whether the dialog is open or closed.
 * @param {function} props.onClose - Event handler for closing the dialog.
 * @param {string} props.dialogTitle - The title displayed in the dialog.
 * @param {string} props.dialogContentText - The content displayed in the dialog.
 * @param {function} props.handleConfirm - Event handler for confirming the action.
 * @param {string} props.dialogConfirmButton - The text for the confirmation button.
 * @param {boolean} props.critical - Set to true to display a warning and require typing a confirmation text.
 * @param {string} props.confirmationText - The text that must be typed to confirm the action.
 */
export default function ConfirmDeleteDialog(props) {
    const [textValue, setTextValue] = React.useState("");
    const handleClose = () => {
        props.onClose();
    };
    useEffect(() => {
        setTextValue("");
    }, [props.open]);

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
                {props.dialogTitle}
                <IconButton onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
                    <Cancel />
                </IconButton>
                </DialogTitle>
            <DialogContent>
                <DialogContentText>{props.dialogContentText}</DialogContentText>
                {props.critical && (
                    <Alert severity="error" sx={{mt: 2}}>
                        Let op! Dit kan niet ongedaan gemaakt worden! Ter bevestiging, voer hieronder de naam in van het
                        object dat verwijderd moet worden:
                        <TextField
                            size="small"
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                            label="Project"
                            fullWidth
                            sx={{ backgroundColor: "white", mt: 1, color: "red" }}
                        />
                    </Alert>
                )}
            </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={props.handleConfirm}
                        disabled={props.critical && props.confirmationText.toString().toLowerCase() !== textValue.toString().toLowerCase() }
                    >
                        {props.dialogConfirmButton || global.delete}
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleClose}>
                        {global.cancel}
                    </Button>
                </DialogActions>
        </Dialog>
    );
}
