import { React, useState } from "react";

import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Alert,
    ButtonGroup,
    Stack,
} from "@mui/material";
import { Delete, Save, UploadFile } from "@mui/icons-material";

import { formatFileSize } from "../../functions/Formatters";

export default function AttachmentDialog(props) {
    // The attachment object, passed as prop from the parent component, is used to store the flat information:
    // Name, description, filename, id
    // The file itself is stored in the fileContent state variable

    const { attachment, setAttachment, handleDownload } = props;
    const [fileContent, setFileContent] = useState(null);
    const editMode = attachment.id !== ""; // Edit mode means that the attachment already exists and is being edited

    // Settings for file requirements
    const allowedExtensions = [
        // Microsoft Office files
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",

        // Images
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",

        // Compressed files
        "zip",
        "rar",
        "7z",

        // OpenOffice files
        "odt",
        "ods",
        "odp",

        // other
        "pdf",
        "txt",
        "csv",
        "xml",
        "json",
        "shp",
        "shx",
        "dbf",
    ];

    const maxSizeMB = 50;
    const maxSize = maxSizeMB * 1024 * 1024;

    // Error state, used to diplay error messages when file is not valid
    const [error, setError] = useState(false);
    const errorMessages = {
        fileExtension: (extension) => `Dit type bestand (.${extension}) is niet toegestaan`,
        fileSize: (size) =>
            `Dit bestand is te groot (${formatFileSize(size)}). Maximale grootte is ${formatFileSize(maxSize)}.`,
    };

    const handleFileSelect = (event) => {
        if (!event.target.files) {
            console.log("Geen bestand geselecteerd");
            return;
        }
        const uploadedFile = event.target.files[0];
        const extension = uploadedFile.name.split(".").pop();

        // Check whether the file conforms to the requirements
        switch (true) {
            case !allowedExtensions.includes(extension):
                setFileContent(null);
                setError(errorMessages.fileExtension(extension));
                break;
            case uploadedFile.size > maxSize:
                setFileContent(null);
                setError(errorMessages.fileSize(uploadedFile.size));
                break;
            default:
                setError(false);
                setAttachment({ ...attachment, filename: uploadedFile.name });
                setFileContent(uploadedFile);
                break;
        }
    };

    const handleClose = () => {
        setFileContent(null);
        setError(false);
        props.onClose();
    };

    const handleSave = () => {
        let formData = new FormData();
        if (fileContent) {
            // This is only true when a new file is uploaded. When editing an existing attachment, the file is not updated
            const file = new File([fileContent], attachment.filename, {
                type: fileContent.type,
            });
            formData.append("file", file);
            formData.append("filename", attachment.filename);
        }
        // This is done both when updating and creating a new attachment
        formData.append("name", attachment.name);
        formData.append("description", attachment.description);
        formData.append("id", attachment.id);

        props.handleSave(formData);
        handleClose();
    };

    const handleChange = (event) => {
        setAttachment({ ...attachment, [event.target.id]: event.target.value });
    };
    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth="sm">
            <DialogTitle>{editMode ? "Bestand bewerken" : "Nieuw bestand"}</DialogTitle>
            <DialogContent>
                <Stack spacing={1} marginTop={1}>
                    <TextField
                        id="name"
                        label="Naam"
                        variant="outlined"
                        onChange={handleChange}
                        value={attachment.name}
                        autofill="off"
                        autoFocus={!editMode}
                        required
                    />
                    <TextField
                        id="description"
                        label="Beschrijving"
                        variant="outlined"
                        onChange={handleChange}
                        value={attachment.description}
                        multiline
                        minRows={3}
                        maxRows={8}
                        fullWidth
                    />
                    {editMode ? (
                        <>
                            <TextField
                                disabled
                                id="filename"
                                label="Bestand"
                                variant="outlined"
                                value={`${attachment.filename} (${formatFileSize(attachment.filesize)})`}
                            />
                            <Typography>
                                Klik{" "}
                                <span
                                    style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
                                    onClick={() => handleDownload(attachment)}
                                >
                                    hier
                                </span>{" "}
                                om het bestand te downloaden.
                            </Typography>
                        </>
                    ) : (
                        <Button
                            component="label"
                            variant="outlined"
                            startIcon={<UploadFile />}
                            disabled={attachment.id !== ""}
                        >
                            {fileContent ? attachment.filename : "Kies een bestand"}
                            <input type="file" hidden onChange={handleFileSelect} />
                        </Button>
                    )}
                    {!fileContent && !editMode && (
                        <>
                            <Typography variant="body2">Maximale grootte: {formatFileSize(maxSize)}</Typography>
                            <Typography variant="body2">
                                Toegestane bestanden: {allowedExtensions.join(", ")}
                            </Typography>
                        </>
                    )}
                    {fileContent && (
                        <Typography variant="body2">Bestandsgrootte: {formatFileSize(fileContent.size)}</Typography>
                    )}
                    {error && <Alert severity="warning">{error}</Alert>}
                </Stack>

                <DialogActions>
                    <ButtonGroup variant="contained">
                        <Button autoFocus={editMode} onClick={handleClose}>
                            Annuleren
                        </Button>
                        {editMode && (
                            <Button onClick={() => props.handleDelete(attachment.id)} color="error">
                                <Delete />
                                Verwijderen
                            </Button>
                        )}
                        <Button
                            onClick={handleSave}
                            disabled={attachment.name === "" || (!fileContent && !editMode) || error !== false}
                        >
                            <Save />
                            {editMode ? "Wijzigingen opslaan" : "Bestand uploaden"}
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
