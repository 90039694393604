import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value != index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value == index && <Box sx={{ pb: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function a11yProps(id) {
    return {
        id: id,
        "aria-controls": `simple-tabpanel-${id}`,
    };
}
