import { createTheme } from "@mui/material/styles";

// The theme colors and font where derived from the HWBP styling. Some components have their own color overrides, but most of the colors are defined here.
// Other style overrides in components are mostly for spacing and padding.
let Theme = createTheme({
    typography: {
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    },
    palette: {
        primary: {
            main: "#29abe2", // HWBP Blue
            // light: "#51a7c3",
            superLight: "#94d5f0",
            // dark: "#26378a",
            input: "#bfe1e9",
        },
        secondary: {
            main: "#c0c670", // HWBP Green
            // light: "#dfe2b6",
            superLight: "#dfe2b7",
            dark: "#9a9b64",
            white: "#ffffff",
        },
        tertiary: {
            main: "#ff0000", // HWBP Red
        },
        graphics: {
            primaryDarkBlue: "#00577e",
            primaryGreen: "#a5c100",
            primaryLightBlue: "#0086a8",
            secondaryOrange: "#ff7f00",
            secondaryRed: "#ff0000",
            secondaryBrightPurple: "#a300ff",
            secondaryYellow: "#ffea00",
            secondaryGreen: "#00ff00",
            secondaryLightPurple: "#d5aaff",
            secondaryGrey: "#808080",
        },
        background: {
            default: "#e5e5e5", // Whitesmoke
            lightsmoke: "#f0f0f0", // Off-white
            white: "#ffffff", // White
        },
        text: {
            white: "#ffffff",
            black: "#000000",
            darkblue: "#26378a",
            headingMain: "#9a9b64",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1800,
        },
    },
});

// Assign colours to certain specific components. If you're uncertain about components MUI name just 'console.log' the component and you'll find the name in type->render->muiName.
Theme = createTheme(Theme, {
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: "lg",
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    display: "block",
                    padding: "20px",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: Theme.palette.secondary.main,
                        color: Theme.palette.secondary.contrastText,
                    },
                },
                outlinedPrimary: {
                    "&:hover": {
                        backgroundColor: Theme.palette.secondary.main,
                        color: Theme.palette.secondary.contrastText,
                    },
                },
                normalPrimary: {
                    "&:hover": {
                        backgroundColor: Theme.palette.secondary.main,
                        color: Theme.palette.secondary.contrastText,
                    },
                },
                textPrimary: {
                    "&:hover": {
                        borderRadius: 20,
                        color: Theme.palette.primary.dark,
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    borderColor: Theme.palette.secondary.main,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    padding: 0,
                    border: 0,
                    borderRadius: 5,
                    "&.Mui-expanded": {
                        border: 5,
                        borderColor: "#000000",
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    backgroundColor: Theme.palette.primary.light,
                    color: Theme.palette.text.dark,
                    "&:hover, &.Mui-expanded": {
                        backgroundColor: Theme.palette.secondary.main,
                        color: Theme.palette.text.white,
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: Theme.palette.text.white,
                },
                colorSecondary: {
                    color: Theme.palette.primary.main,
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Theme.palette.primary.input,
                    border: "1px solid",
                    borderColor: Theme.palette.secondary.main,
                },
            },
            variants: [
                // variants will help you define the props given by Mui and the styles you want
                // to apply for it
                {
                    props: { disabled: true },
                    style: {
                        backgroundColor: Theme.palette.background.default,
                        fontSize: "2.2rem",
                    },
                },
            ],
        },
        MuiOutlinedInput: {
            variants: [
                // variants will help you define the props given by Mui and the styles you want
                // to apply for it
                {
                    props: { disabled: true },
                    style: {
                        backgroundColor: Theme.palette.background.default,
                    },
                },
            ],
        },

        MuiTextField: {
            defaultProps: {
                size: "small",
            },
            variants: [
                {
                    props: { "read-only": "true" },
                    style: {
                        backgroundColor: Theme.palette.secondary.main,
                    },
                },
            ],
        },
        MuiSelect: {
            variants: [
                {
                    props: { disabled: true },
                    style: {
                        color: "red",
                        backgroundColor: Theme.palette.background.default,
                        fontSize: "5rem",
                    },
                },
            ],
        },
        MuiTableRow: {
            variants: [
                {
                    props: { headerRow: true },
                    style: {
                        color: Theme.palette.text.white,
                        backgroundColor: Theme.palette.secondary.main,
                    },
                },
                {
                    props: { odd: "false" },
                    style: {
                        backgroundColor: Theme.palette.secondary.light,
                    },
                },
                {
                    props: { odd: "true" },
                    style: {
                        backgroundColor: Theme.palette.secondary.dark,
                    },
                },
            ],
        },
    },

    typography: {
        // h3 is the main heading on the page. The size is responsive to the screen size.
        h3: {
            [Theme.breakpoints.down("xl")]: {
                fontSize: "2.4rem",
            },
            [Theme.breakpoints.up("xl")]: {
                fontSize: "3.0rem",
            },
            color: Theme.palette.primary.dark,
        },
        h4: {
            color: Theme.palette.primary.dark,
        },
        h5: {
            color: Theme.palette.primary.dark,
        },
        subtitle1: {
            color: Theme.palette.primary.dark,
        },
    },
});

export default Theme;
