import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authSlice from "./slices/authSlice.js";
import usersSlice from "./slices/usersSlice.js";
import projectsSlice from "./slices/projectsSlice.js";
import organisationsSlice from "./slices/organisationsSlice.js";
import themesSlice from "./slices/themesSlice.js";
import designSlice from "./slices/designSlice.js";

const rootReducer = combineReducers({
    auth: authSlice,
    users: usersSlice,
    projects: projectsSlice,
    organisations: organisationsSlice,
    themes: themesSlice,
    designs: designSlice,
});

const store = configureStore({
    reducer: rootReducer,
});

export { store };
