import { createSlice } from "@reduxjs/toolkit";

const initialState = { users: [], enrollments: [] };

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUsers(state, action) {
            /**Sets the full array of users */
            state.users = action.payload;
        },
        addUser(state, action) {
            state.users = [...state.users, action.payload];
        },
        removeUser(state, action) {
            state.users = state.users.filter((user) => user.id !== action.payload);
        },
        clearUsers(state) {
            state.users = [];
        },
        updateUser(state, action) {
            const index = state.users.findIndex((user) => user.id === action.payload.id);
            if (index === -1) {
                state.users = [...state.users, action.payload];
            } else {
                const newState = state.users.map((user) => {
                    if (user.id === action.payload.id) {
                        return action.payload;
                    }
                    return user;
                });
                state.users = newState;
            }
        },
        toggleAdminStatus(state, action) {
            let user = state.users.find((user) => user.id === action.payload);
            user.is_staff = !user.is_staff;
            state.users = state.users.map((u) => {
                if (u.id === user.id) {
                    return user;
                }
                return u;
            });
        },
        setEnrollments(state, action) {
            state.enrollments = action.payload;
        },
        addEnrollment(state, action) {
            state.enrollments = [...state.enrollments, action.payload];
        },
        removeEnrollment(state, action) {
            state.enrollments = state.enrollments.filter((enrollment) => enrollment.id !== action.payload);
        },
    },
});

export const { setUsers, addUser, removeUser, clearUsers, updateUser, toggleAdminStatus, setEnrollments, addEnrollment, removeEnrollment } = userSlice.actions;
export default userSlice.reducer;
