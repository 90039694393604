/**
 * Formats a number (or string) to a Dutch Euro currency format (€ 1.000,00). Null, undefined and empty string are returned as empty string.
 * @param {string|number} number Value to format. Can be a number or a string
 * @returns Formatted value as a string
 */
export function EuroFormat(number) {
    if (number === undefined || number === null || number === "" || isNaN(Number(number))) {
        return "";
    }

    const numberFormat = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 0,
    });
    return numberFormat.format(Number(number));
}

/**
 * Formats a number to a string with Dutch number formatting.
 * @param {number} number - The number to format.
 * @returns {string} The formatted number as a string.
 */
export function NumberFormat(number) {
    if (number === undefined || number === null || number === "" || isNaN(Number(number))) {
        return "";
    }

    const numberFormat = new Intl.NumberFormat("nl-NL");
    return numberFormat.format(number);
}

/**
 * Formats a date to a Dutch date format, in short (01-01-2021) or long (vrijdag 1 januari 2021) form
 * @param {Date} date Date object.
 * @param {String} [format] Format to use. Can be "long" or "short". Defaults to "short".
 * @param {Boolean} [time] Whether to include the time in the output. Defaults to false.
 * @returns Formatted date as a string.
 */
export function DateFormat(date, format = "short", time = false) {
    if (!date) {
        return "";
    }

    let dateObject = new Date(date);

    let dateOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    };
    if (time) {
        dateOptions = { ...dateOptions, hour: "numeric", minute: "numeric" };
    }
    if (format === "long") {
        dateOptions = { ...dateOptions, weekday: "long", month: "long" };
    }

    let dateFormat = new Intl.DateTimeFormat("nl-NL", dateOptions);

    return dateFormat.format(dateObject);
}

/**
 * Formats the time delta between two dates in days or years.
 * @param {Date} date1 - The first date to compare.
 * @param {string} [blankText="N.v.t."] - The text to return when date1 is null or undefined. Defaults to "N.v.t."
 * @param {Date} [date2=new Date()] - The second date to compare. Defaults to the current date.
 * @returns {string} - The formatted time delta in days or years.
 */
export function FormattedTimeDelta(date1, blankText = "N.v.t.", date2 = new Date()) {
    if (!date1) {
        return blankText;
    }
    const date1Object = new Date(date1);
    const date2Object = new Date(date2);
    const timeDeltaBase = date1Object - date2Object; // in milliseconds
    const timeFormat = new Intl.RelativeTimeFormat("nl-NL", { numeric: "auto" });

    const timeDeltaInDays = Math.round(timeDeltaBase / (1000 * 60 * 60 * 24));

    if (timeDeltaInDays < -365) {
        return timeFormat.format(Math.round(timeDeltaInDays / 365), "years");
    }
    if (timeDeltaInDays < -31) {
        return timeFormat.format(Math.round(timeDeltaInDays / 30.5), "months");
    }
    return timeFormat.format(timeDeltaInDays, "days");
}

/**
 * Capitalizes the first letter of a string. If the string is all caps, it is returned as is.
 * @param {string} string - The input string to be capitalized.
 * @returns {string} The capitalized string.
 */
export function Capitalize(string) {
    if (!string) {
        return "";
    }
    const regexCapitalLetters = /^[A-Z]/;
    if (regexCapitalLetters.test(string)) {
        return string;
    } else {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

export function Pluralize(singular, count = 2, plural) {
    if (count === 1) {
        return singular;
    } else {
        if (!plural) {
            const lowerCaseSingular = singular.toLowerCase();
            const regexGetsOnlyS = /([^aieou])(e|el|en|er|em|ie|eau)$|([aeiou]y)$/;
            const regexGetsApostropheS = /[aeiouy]$/;

            if (regexGetsOnlyS.test(lowerCaseSingular)) {
                plural = singular + "s";
            } else if (regexGetsApostropheS.test(lowerCaseSingular)) {
                plural = singular + "'s";
            } else {
                plural = singular + "en";
            }
        }
        return plural;
    }
}

/**
 * Formats the file size into a human-readable format.
 * @param {number} size - The size of the file in bytes.
 * @returns {string} - The formatted file size with appropriate unit.
 */
export const formatFileSize = (size) => {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0,
        n = parseInt(size, 10) || 0;
    while (n >= 1024 && ++l) {
        n = n / 1024;
    }
    return n.toFixed(n >= 10 || l < 1 ? 0 : 1) + " " + units[l];
};

/**
 * Sanitizes a number value by removing non-numeric characters and determining the correct decimal separator.
 *
 * @param {string | number} value - The value to sanitize.
 * @returns {string} The sanitized number value.
 */
export const sanitizeNumber = (value) => {
    if (value === undefined || value === null) {
        return "";
    }

    value = value.toString();
    value = value?.replaceAll(/[^0-9,.-]/gi, ""); // regex removes everything that is not a number

    if (value.includes(",") && value.includes(".")) {
        // if both a comma and a dot are present, the user has thousands and decimal separators. Have to check which one is used as a decimal separator
        if (value.lastIndexOf(",") > value.lastIndexOf(".")) {
            // if the comma is after the dot, the comma is the decimal separator
            value = value.replaceAll(".", "").replaceAll(",", ".");
        } else {
            // if the dot is after the comma, the dot is the decimal separator
            value = value.replaceAll(",", "");
        }
    } else if (value.includes(",")) {
        // if only a comma is present, the comma is probably the decimal separator, but if multiple are present, the user has thousands separator
        if (value.split(",").length === 2) {
            // if there is only one comma, we assume it is the decimal separator
            value = value.replaceAll(",", ".");
        } else {
            // if there are multiple commas, they are the thousands separator and we remove them
            value = value.replaceAll(",", "");
        }
    } else if (value.includes(".")) {
        // if only dots are present, we have to check if they are the decimal separator or thousands separator
        if (value.split(".").length === 2) {
            // if there is only one dot, we assume it is the decimal separator
            null;
        } else {
            // if there are multiple dots, the user has thousands separator
            value = value.replaceAll(".", "");
        }
    }

    if (value.charAt(0) === 0) {
        value = value.substring(1);
    }

    return value;
};

/**
 * Creates a pretty version of the number supplied, including the supplied unit
 *
 * @param {string | number} value - The value to prettify.
 * @param {string | number} unit - The unit of the value.
 * @returns {string} The pretty stringified value.
 */
export const prettifyNumber = (value, unit) => {
    //First check to see if there is a value to start with
    if (value === undefined || value === null) {
        return "";
    }
    //cast the value to string, in order to make includes and split work with the value
    value = value.toString();

    //Check if the value needs to be sanitized first, based of multiple dots, or if it includes a comma.
    if ((value.includes(".") && value.split(".").length >= 3) || value.includes(",")) {
        value = sanitizeNumber(value);
    }
    // value = sanitizeNumber(value)

    //check if there is a separator
    if (value.includes(".")) {
        //check if it is monetary, to change output
        if (unit === "€") {
            //return the value in euro format, with 2 numbers after the comma
            return EuroFormat(value.substring(0, value.indexOf("."))) + "," + value.substring(value.indexOf(".") + 1, value.indexOf(".") + 3);
        } else {
            //return the value in number format, with a dot as thousand separator, and comma as decimal separator
            return NumberFormat(value.substring(0, value.indexOf("."))) + "," + value.substring(value.indexOf(".") + 1);
        }
    } else {
        if (unit === "€") {
            return EuroFormat(value);
        } else {
            //return the value in number format, with a dot as thousand separator, without decimals
            return NumberFormat(value);
        }
    }
};

/**
 * Shortens a string to a specified maximum length and appends an ellipsis if necessary.
 * @param {string} string - The string to be shortened.
 * @param {number} [maxLength=50] - The maximum length of the shortened string.
 * @param {string} [ellipsis='...'] - The ellipsis to be appended if the string is shortened.
 * @returns {string} The shortened string.
 */
export const shortenString = (string, maxLength=50, ellipsis='...') => {
    if (!string) {
        return '';
    }
    if (string.length <= maxLength) {
        return string;
    }
    return string.substring(0, maxLength) + ellipsis;
}