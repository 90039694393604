import React, { useState } from "react";
import { global } from "../../context/global";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Box } from "@mui/material";
import { Warning } from "@mui/icons-material";

/**
 * Renders an information dialogue component.
 * @param {Object} props - The component props.
 * @param {Object} props.text - The text content for the dialogue.
 * @param {string} props.text.title - The title of the dialogue.
 * @param {string} props.text.shortText - The short text for the dialogue.
 * @param {string[]} props.text.fullText - The full text for the dialogue.
 * @returns {JSX.Element} The rendered InformationDialogue component.
 */
export default function InformationDialogue({ text, maxLength = 300 }) {
    const [readMore, setReadMore] = useState(false);

    const joinedFullText = text?.fullText?.join(' ') || ""
    const shortText = text?.shortText || joinedFullText.slice(0, maxLength) + "..."

    const handleClickReadMore = () => {
        setReadMore(!readMore);
    };

    const getIcon = () => {
        switch (text.icon) {
            case "warning":
                return <Warning color="warning" />;
            case "info":
                return null;
            case "information":
                return null;
            default:
                return null;
        }
    };


    return (
        <>
            <Box >
                <Typography>{joinedFullText.length > maxLength ? shortText : joinedFullText}</Typography>
                {text.fullText?.length > 0 && (text.shortText || text.fullText[0].length > maxLength || text.fullText.length > 1) && (
                    <Button onClick={handleClickReadMore} className="toggle-button">
                        Klik hier voor meer informatie
                    </Button>
                )}
            </Box>
            <Dialog open={readMore} onClose={() => setReadMore(false)} maxWidth={"md"}>
                {getIcon()}
                <DialogTitle>{text.title}</DialogTitle>
                <DialogContent>
                    <>
                    {text.fullText
                        ? text.fullText.map((str, index) => {
                              if (str.includes("<list>")) {
                                  let list = str.slice(str.indexOf("<list>") + 6, str.indexOf("</list>")).split("</>");
                                  return (
                                      <ol key={`list_${index}`}>
                                          {list.map((item, list_index) => (
                                              <li key={list_index}>{item}</li>
                                          ))}
                                      </ol>
                                  );
                              }
                              return (
                                  <Typography
                                      variant={str.includes("<h>") ? "h6" : "body1"}
                                      key={`paragraph_${index}`}
                                      sx={{ display: "block", marginBottom: "1rem" }}
                                  >
                                      {str.includes("<h>") ? str.slice(3) : str}
                                  </Typography>
                              );
                          })
                        : text}
                    </>
                </DialogContent>
                <Button autoFocus variant="contained" onClick={handleClickReadMore}>
                    {global.close}
                </Button>
            </Dialog>
        </>
    );
}
