import React from "react";
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export default function PolarAreaChart({ valueData }) {
    const availableThemes = useSelector((state) => state.themes.themes);
    const reorderedThemes = [...availableThemes.slice(3, availableThemes.length), ...availableThemes.slice(0, 3)];

    const style = useTheme();
    const superThemeColors = {
        0: style.palette.primary.light,
        1: style.palette.primary.dark,
        2: style.palette.secondary.main,
    };
    const themeColors = reorderedThemes.map((theme) => superThemeColors[theme.supertheme]);
    const themeLabels = reorderedThemes.map((theme) => theme.name);
    const themeDescriptions = reorderedThemes.map((theme) => theme.description);
    const themeLevels = reorderedThemes.map(
        (theme) => valueData.projectThemes.find((projectTheme) => projectTheme.theme == theme.id)?.importance || 0
    );

    const options = {
        title: {
            display: true,
            text: "Thema's",
        },
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        aspectRatio: 2,
        animation: false,
        scales: {
            r: {
                min: 0,
                max: 3,
                pointLabels: {
                    display: true,
                    centerPointLabels: true,
                    font: {
                        size: 14,
                    },
                },
                ticks: {
                    min: 0,
                    max: 3,
                    stepSize: 1,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: "top",
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return "Niveau" + ": " + themeLevels[context.dataIndex];
                    },
                    afterLabel: function (context) {
                        return themeDescriptions[context.dataIndex];
                    },
                },
            },
        },
    };

    let data = {
        labels: themeLabels,
        datasets: [
            {
                label: "Niveau",
                data: themeLevels,
                backgroundColor: themeColors,
                borderWidth: 1,
            },
        ],
    };

    return (
        <Box sx={{ height: 300, m: 2 }}>
            <PolarArea options={options} redraw={true} data={data} />
        </Box>
    );
}
