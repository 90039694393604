import React, { Fragment } from "react";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setWelcomeDialog } from "../store/slices/authSlice";
import { welcome } from "../context/welcome";
import { global } from "../context/global";
import { CustomTabPanel, a11yProps } from "./CustomComponents/CustomTabPanel";
import { Feed } from "@mui/icons-material";
import FeedbackForm from "./Feedback/FeedbackForm";

export default function WelcomeDialog() {
    const dispatch = useDispatch();
    const { title, paragraphs, navigation } = welcome;
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    if (!auth.account) {
        return null;
    }

    const handleClose = () => {
        setTabValue(0);
        dispatch(setWelcomeDialog(false));
    };
    const handleLink = (link) => {
        handleClose();
        navigate(link);
    };

    const NavItemInline = ({ navItem }) => (
        <li>
            <Typography
                display={"inline"}
                sx={{ cursor: "pointer", fontWeight: "bold", textDecoration: "underline" }}
                onClick={() => handleLink(navItem.link)}
            >
                {navItem.title}
            </Typography>
            <Typography display={"inline"}> - {navItem.description}</Typography>
        </li>
    );

    return (
        <>
            <Dialog open={auth.account.is_first_login} onClose={handleClose} maxWidth={"md"}>
                <DialogTitle sx={{ color: "primary" }}>{title}</DialogTitle>
                <DialogContent>
                    {auth.token && 
                    <Tabs sx={{mb: 1}} value={tabValue} onChange={handleChangeTab} aria-label="tabs">
                        <Tab key={"tab_introduction"} label={global.introduction} {...a11yProps(0)} />
                        <Tab key={"tab_feedback"} label={global.feedback} {...a11yProps(1)} />
                    </Tabs>
                    }
                    <CustomTabPanel key={0} value={tabValue} index={0}>
                        {paragraphs.map((paragraph, index) => (
                            <Fragment key={`text_${index}`}>
                                <Typography key={`p_${index}`}>{paragraph.introduction}</Typography>
                                <ul key={`li_p_${index}`}>
                                    {paragraph.listItems.map((listItem, listItemIndex) => (
                                        <li key={`li_${index}_${listItemIndex}`}>
                                            <Typography>{listItem}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Fragment>
                        ))}

                        <Typography>{navigation.introduction}</Typography>
                        <ul>
                            {auth.account.is_org_member &&
                                navigation.defaultItems.map((navItem, itemIndex) => (
                                    <NavItemInline key={`default_nav_li_${itemIndex}`} navItem={navItem} />
                                ))}
                            <br />
                            {auth.account.is_staff &&
                                navigation.adminItems.map((navItem, itemIndex) => (
                                    <NavItemInline key={`admin_nav_li_${itemIndex}`} navItem={navItem} />
                                ))}
                        </ul>
                    </CustomTabPanel>
                    <CustomTabPanel key={1} value={tabValue} index={1}>
                        <FeedbackForm />
                    </CustomTabPanel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{global.close}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
